// App.js
import React, { useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './index.css';
import Navbar from './components/Navbar/Navbar';
import Home from './components/Home/Home';
import About from './components/About';
import Skills from './components/Skills';
import Contact from './components/Contact';
import Services from './components/Services.js';
import Footer from './components/Footer';
import { Matrix } from './components/Utils/Matrix';
import Register from './components/Session/Register';
import UserProfile from './components/UserProfile.js';
import Blog from './components/Blog/Blog';
import Foro from './components/Forum/Foro';
import Post from './components/Forum/Post';
import DetallePublicacion from './components/Forum/DetallePublicacion';
import AI from './components/AI.js';
import Admin from './components/Admin/Admin';
import Dashboard from './components/Admin/Dashboard';
import PublishArticle from './components/Blog/PublishArticle';
import ArticleDetail from './components/Blog/ArticleDetail';
import PrivacyPolicy from './components/MobileWheel/PrivacyPolicy';
import Tests from './components/Tests/Tests';
import SearchPage from './components/Search/SearchPage';
import Projects from './components/Projects';
import Restaurant1 from './components/Restaurant1';
import Restaurant2 from './components/restaurant/src/Restaurant2.jsx';
import ServerInterface from './components/ServerInterface.js';
import NotFound from './components/NotFound.js'
import HomePage from './components/HomePage.js'


import './App.css';

function App() {
  const [darkMode, setDarkMode] = useState(() => {
    const currentTheme = localStorage.getItem('theme') === 'dark';
    return currentTheme;
  });

  const handleThemeChange = (isDark) => {
    setDarkMode(isDark);
  };

  // Utiliza useLocation para detectar la ruta actual
  const location = useLocation();

  // Define una condición para ocultar Navbar y Footer en las páginas de restaurante
  const hideHeaderFooter = location.pathname.startsWith('/restaurante');

  return (
    <>
      {/* Renderiza Navbar solo si hideHeaderFooter es falso */}
      {!hideHeaderFooter && <Navbar onThemeChange={handleThemeChange} darkMode={darkMode} />}

      <Routes>
        <Route path="/Tests" element={<Tests />} />
        <Route path="/AI" element={<AI />} />
        <Route path="/new-post" element={<Post />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/forum" element={<Foro />} />
        <Route path="/register" element={<Register />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/publicaciones/:id" element={<DetallePublicacion />} />
        <Route path="/Admin" element={<Admin />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/publish" element={<PublishArticle />} />
        <Route path="/articles/:id" element={<ArticleDetail />} />
        <Route path="/MobileWheelPrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/tests" element={<Tests />} />
        <Route path="/restaurante1/*" element={<Restaurant1 />} />
        <Route path="/restaurante2/*" element={<Restaurant2 />} />
        <Route path="/ControlPanel" element={<ServerInterface />} />
        <Route path="/homepage" element={<HomePage />} />
        <Route path="*" element={<NotFound />} />

        <Route
          path="/"
          element={
            <>
              <Matrix />
              <Home darkMode={darkMode} />
              <About />
              <Skills />
              <Services />
              <Projects />
              <Contact />
            </>
          }
        />
      </Routes>

      {/* Renderiza Footer solo si hideHeaderFooter es falso */}
      {!hideHeaderFooter && <Footer />}
    </>
  );
}

export default App;
