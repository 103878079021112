// src/components/Testimonials.jsx
import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

const Testimonials = () => {
  const { t } = useTranslation();
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  const testimonials = [
    {
      quote: t('testimonial1_quote'),
      name: t('testimonial1_name'),
      image: '/images/testimonials/testimonial1.jpg',
    },
    {
      quote: t('testimonial2_quote'),
      name: t('testimonial2_name'),
      image: '/images/testimonials/testimonial2.jpg',
    },
    {
      quote: t('testimonial3_quote'),
      name: t('testimonial3_name'),
      image: '/images/testimonials/testimonial3.jpg',
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 7000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <section className="py-20 bg-white dark:bg-gray-900">
      <div className="container mx-auto px-6 md:px-12">
        <motion.div
          ref={ref}
          initial={{ opacity: 0 }}
          animate={inView ? { opacity: 1 } : {}}
          transition={{ duration: 1 }}
        >
          <h2 className="text-4xl font-bold text-center text-gold mb-12 font-sans">
            {t('testimonialsTitle')}
          </h2>
          <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
              <div key={index} className="px-4">
                <div className="md:flex md:items-center md:space-x-6">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="w-24 h-24 rounded-full mx-auto md:mx-0"
                  />
                  <div className="mt-4 md:mt-0 text-center md:text-left">
                    <p className="text-gray-700 dark:text-gray-200 italic font-medium mb-2">
                      "{testimonial.quote}"
                    </p>
                    <p className="text-gold font-semibold">
                      {testimonial.name}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </motion.div>
      </div>
    </section>
  );
};

export default Testimonials;
