// Home.js
import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Importar los estilos de AOS
import './Home.css'; // Importar los estilos de Home

function Home({ darkMode }) {
  const [isImageVisible, setIsImageVisible] = useState(darkMode);
  const [animationClass, setAnimationClass] = useState(darkMode ? 'fade-in' : 'fade-out');

  useEffect(() => {
    if (darkMode) {
      setIsImageVisible(true);
      setTimeout(() => setAnimationClass('fade-in'), 0);
    } else {
      setAnimationClass('fade-out');
      setTimeout(() => setIsImageVisible(false), 500);
    }

    AOS.init({
      duration: 1000,
      once: true,
      offset: 50,
    });

    const setHomeHeight = () => {
      const vh = window.visualViewport
        ? window.visualViewport.height * 0.01
        : window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    setHomeHeight();
    window.addEventListener('resize', setHomeHeight);

    return () => {
      window.removeEventListener('resize', setHomeHeight);
    };
  }, [darkMode]);

  return (
    <section
      className="relative w-full grid grid-cols-1 md:grid-cols-[auto_auto_1fr] items-center justify-items-center md:justify-items-start p-4 md:p-8 gap-10 md:gap-20 overflow-hidden"
      id="home"
      style={{ height: 'calc(var(--vh, 1vh) * 100)' }}
    >
      {/* Enlaces Sociales */}
      <div
        className="social-icons flex flex-row md:flex-col justify-center md:justify-start md:ml-6 mb-4 md:mb-0 space-x-4 md:space-x-0 md:space-y-6"
        data-aos="fade-right"
      >
        <a
          href="https://github.com/tempestgf"
          className="mx-2 md:mx-0 mb-4 text-3xl text-white hover:text-[var(--hover-color)] transition-transform transform hover:scale-110"
        >
          <i className="bx bxl-github"></i>
        </a>
        <a
          href="https://twitter.com/tempestgf"
          className="mx-2 md:mx-0 mb-4 text-3xl text-white hover:text-[var(--hover-color)] transition-transform transform hover:scale-110"
        >
          <i className="bx bxl-twitter"></i>
        </a>
        <a
          href="https://www.pinterest.es/tempestgf"
          className="mx-2 md:mx-0 mb-4 text-3xl text-white hover:text-[var(--hover-color)] transition-transform transform hover:scale-110"
        >
          <i className="bx bxl-pinterest"></i>
        </a>
        <a
          href="https://app.hackthebox.com/profile/346176"
          className="mx-2 md:mx-0 mb-4 text-3xl text-white hover:text-[var(--hover-color)] transition-transform transform hover:scale-110"
        >
          <i className="bx bx-cube"></i>
        </a>
        <a
          href="https://codepen.io/Tempestgf"
          className="mx-2 md:mx-0 mb-4 text-3xl text-white hover:text-[var(--hover-color)] transition-transform transform hover:scale-110"
        >
          <i className="bx bxl-codepen"></i>
        </a>
      </div>

      {/* Texto Principal */}
      <div
        className="z-10 max-w-full md:max-w-[calc(100%-10rem)] self-left text-center md:text-left justify-self-center md:justify-self-start"
        data-aos="fade-left"
      >
        <h1
          className="font-bexago text-white text-4xl md:text-6xl"
          style={{ fontFamily: 'Bexago' }}
        >
          TEMPEST
        </h1>
        <h2 className="text-white text-lg font-bold hover:text-[var(--hover-color)]"><a href="https://es.wikipedia.org/wiki/Netrunner">Netrunner</a></h2>
        <p className="text-white font-normal my-3">
          Problem solving and thinking out of the matrix.
        </p>
        <a href="#contact" className="btn-cyberpunk font-bold">
          Contact Me
        </a>
      </div>

      {/* Contenedor de Imagen y Canvas solo si es visible */}
      {isImageVisible && (
        <div
          className={`image-container hidden lg:flex justify-center items-center ${animationClass}`}
          data-aos="zoom-in"
        >
          <img
            className="night-image"
            src={require('./soldieraura.png')}
            alt="Cyberpunk Soldier"
          />
        </div>
      )}

      {/* Canvas de Fondo */}
      <canvas
        id="backgroundCanvas"
        className="absolute top-0 left-0 w-full h-full bg-transparent bg-no-repeat bg-cover bg-center z-[-3]"
      ></canvas>

      {/* Enlace Scroll Down */}
      <div
        className="absolute bottom-4 left-1/2 transform -translate-x-1/2 z-50" // Cambié a absolute para que esté contenido dentro de la sección
      >
        <a
          href="#about"
          className="flex items-center text-lg text-white no-underline hover:no-underline"
        >
          <i className="bx bx-mouse text-[var(--main-color)] text-2xl"></i>
          <span className="ml-2 hover:text-[var(--hover-color)]">Scroll Down</span>
          <i className="bx bx-caret-down text-[var(--main-color)] text-2xl ml-2"></i>
        </a>
      </div>
    </section>
  );
}

export default Home;
