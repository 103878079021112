// Homepage.js
import React, { useState, useCallback, useEffect } from 'react';
import {
  FaYoutube,
  FaGithub,
  FaRedditAlien,
  FaTwitter,
  FaChessKnight,
  FaSun,
  FaMoon,
  FaLinkedin,
  FaStackOverflow,
  FaDiscord,
  FaTwitch,
  FaInstagram,
  FaPinterest,
} from 'react-icons/fa';
import { SiOpenai, SiProtonmail, SiGmail } from 'react-icons/si';
import { FiSearch } from 'react-icons/fi';
import PropTypes from 'prop-types';

// Definición de enlaces rápidos fuera del componente para optimizar
const quickLinks = [
  { name: 'YouTube', url: 'https://www.youtube.com', icon: FaYoutube },
  { name: 'GitHub', url: 'https://github.com', icon: FaGithub },
  { name: 'Reddit', url: 'https://www.reddit.com', icon: FaRedditAlien },
  { name: 'Twitter (X)', url: 'https://twitter.com', icon: FaTwitter },
  { name: 'ChatGPT', url: 'https://chat.openai.com', icon: SiOpenai },
  { name: 'ProtonMail', url: 'https://mail.proton.me', icon: SiProtonmail },
  { name: 'Gmail', url: 'https://mail.google.com', icon: SiGmail },
  { name: 'Lichess', url: 'https://lichess.org', icon: FaChessKnight },
  { name: 'LinkedIn', url: 'https://www.linkedin.com', icon: FaLinkedin },
  { name: 'Stack Overflow', url: 'https://stackoverflow.com', icon: FaStackOverflow },
  { name: 'Discord', url: 'https://discord.com', icon: FaDiscord },
  { name: 'Twitch', url: 'https://www.twitch.tv', icon: FaTwitch },
  { name: 'Instagram', url: 'https://www.instagram.com', icon: FaInstagram },
  { name: 'Pinterest', url: 'https://www.pinterest.com', icon: FaPinterest },
];

function Homepage({ isNightMode, toggleNightMode }) {
  const [searchQuery, setSearchQuery] = useState('');

  // Añadir o quitar la clase 'active' al body según el modo nocturno
  useEffect(() => {
    if (isNightMode) {
      document.body.classList.add('active');
    } else {
      document.body.classList.remove('active');
    }
  }, [isNightMode]);

  const handleSearch = useCallback(
    (e) => {
      e.preventDefault();
      const trimmedQuery = searchQuery.trim();
      if (trimmedQuery !== '') {
        window.location.href = `https://search.brave.com/search?q=${encodeURIComponent(trimmedQuery)}`;
      }
    },
    [searchQuery]
  );

  return (
    <div className="min-h-screen flex flex-col items-center justify-center transition-colors duration-500">
      {/* Botón de Modo Nocturno */}
      <button
        onClick={toggleNightMode}
        className="absolute top-6 right-6 p-3 rounded-full bg-opacity-20 backdrop-filter backdrop-blur-md text-gray-800 dark:text-gray-200 hover:bg-opacity-30 transition-all duration-300 focus:outline-none shadow-lg"
        aria-label={isNightMode ? 'Activar modo diurno' : 'Activar modo nocturno'}
        style={{
          backgroundColor: 'var(--secondary-color)',
          color: 'var(--text-color)',
        }}
      >
        {isNightMode ? <FaSun className="text-yellow-400" /> : <FaMoon className="text-indigo-400" />}
      </button>

      {/* Cuadro de Búsqueda */}
      <form onSubmit={handleSearch} className="w-full max-w-md mb-12">
        <div className="relative">
          <input
            type="text"
            placeholder="Buscar en Brave..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full py-4 px-5 pl-14 rounded-full bg-opacity-20 backdrop-filter backdrop-blur-md border border-transparent focus:outline-none focus:ring-2 focus:ring-indigo-500 text-gray-800 dark:text-gray-200 placeholder-gray-500 transition-colors duration-300"
            aria-label="Campo de búsqueda"
            style={{
              backgroundColor: 'var(--secondary-color)',
              color: 'var(--text-color)',
            }}
          />
          <FiSearch
            className="absolute left-5 top-1/2 transform -translate-y-1/2 text-xl"
            style={{
              color: 'var(--hover-color)',
            }}
          />
        </div>
      </form>

      {/* Accesos Rápidos */}
      <div className="w-full max-w-5xl px-4 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-6">
        {quickLinks.map((link) => {
          const IconComponent = link.icon;
          return (
            <a
              key={link.name}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-col items-center justify-center p-6 rounded-2xl bg-opacity-30 backdrop-filter backdrop-blur-md hover:bg-opacity-50 transition-all duration-300 transform hover:scale-105 shadow-lg hover:shadow-2xl"
              aria-label={`Ir a ${link.name}`}
              style={{
                backgroundColor: 'var(--secondary-color)',
                color: 'var(--text-color)',
              }}
            >
              <IconComponent
                className="text-5xl mb-4 transition-transform duration-300"
                style={{
                  color: 'var(--main-color)',
                }}
              />
              <span className="text-sm font-medium">{link.name}</span>
            </a>
          );
        })}
      </div>
    </div>
  );
}

// Definición de PropTypes para mejorar la robustez del componente
Homepage.propTypes = {
  isNightMode: PropTypes.bool.isRequired,
  toggleNightMode: PropTypes.func.isRequired,
};

export default Homepage;
