// src/components/Hero.jsx
import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const Hero = () => {
  const { t } = useTranslation();

  return (
    <section id="home" className="h-screen relative overflow-hidden">
      <video
        className="absolute top-0 left-0 w-full h-full object-cover"
        src="/videos/interior.mp4"
        autoPlay
        loop
        muted
        playsInline
      />
      <div className="absolute inset-0 bg-black bg-opacity-70"></div>
      <div className="container mx-auto h-full flex items-center justify-center relative">
        <motion.div
          className="text-center px-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <h1 className="text-5xl md:text-7xl font-extrabold mb-6 text-white font-playfair">
            {t('heroTitle')}
          </h1>
          <p className="text-xl md:text-2xl mb-8 text-white font-medium font-playfair">
            {t('heroSubtitle')}
          </p>
          <a
            href="#menu"
            className="inline-block px-8 py-4 bg-gold hover:bg-gold-dark rounded-full text-white font-semibold transition-colors"
          >
            {t('heroButton')}
          </a>
        </motion.div>
      </div>
    </section>
  );
};

export default Hero;
