// src/components/Gallery.jsx
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Gallery = () => {
  const { t } = useTranslation();
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  const images = [
    '/images/gallery/dish1.jpg',
    '/images/gallery/dish2.jpg',
    '/images/gallery/dish3.jpg',
    '/images/gallery/dish4.jpg',
    '/images/gallery/dish5.jpg',
    '/images/gallery/dish6.jpg',
    '/images/gallery/dish7.jpg',
    '/images/gallery/dish8.jpg',
    '/images/gallery/dish9.jpg',
    '/images/gallery/dish10.jpg',
    '/images/gallery/dish11.jpg',
    '/images/gallery/dish12.jpg',
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024, // pantallas medianas
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640, // pantallas pequeñas
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section id="gallery" className="py-20 bg-white dark:bg-gray-900">
      <div className="container mx-auto px-6 md:px-12">
        <motion.div
          ref={ref}
          initial={{ opacity: 0 }}
          animate={inView ? { opacity: 1 } : {}}
          transition={{ duration: 1 }}
        >
          <h2 className="text-4xl font-bold text-center text-gold mb-12 font-sans">
            {t('galleryTitle')}
          </h2>
          <Slider {...settings}>
            {images.map((src, index) => (
              <div key={index} className="px-2">
                <div className="relative group">
                  <img
                    src={src}
                    alt={`${t('galleryTitle')} ${index + 1}`}
                    className="w-full h-64 object-cover rounded-lg shadow-md"
                  />
                  <div className="absolute inset-0 bg-gold opacity-0 group-hover:opacity-20 rounded-lg transition-opacity"></div>
                </div>
              </div>
            ))}
          </Slider>
        </motion.div>
      </div>
    </section>
  );
};

export default Gallery;
