// components/Navbar.js
import React, { useState, useEffect, useRef } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const [navOpen, setNavOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const desktopDropdownRef = useRef(null);
  const mobileDropdownRef = useRef(null);

  // Toggle mobile menu
  const toggleNav = () => {
    setNavOpen(!navOpen);
    if (!navOpen) {
      setScrolled(true);
    } else {
      setScrolled(window.scrollY > 50);
    }
  };

  // Handle scroll behavior for navbar background change
  useEffect(() => {
    const handleScroll = () => {
      if (!navOpen) {
        setScrolled(window.scrollY > 50);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [navOpen]);

  // Change language handler
  const handleLanguageChange = async (lang, e) => {
    e.stopPropagation(); // Prevents conflicts with other click handlers
    try {
      await i18n.changeLanguage(lang);
      setSelectedLanguage(lang);
      localStorage.setItem('language', lang);
      setDropdownOpen(false);
    } catch (error) {
      console.error('Error changing language:', error);
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        desktopDropdownRef.current &&
        !desktopDropdownRef.current.contains(event.target) &&
        mobileDropdownRef.current &&
        !mobileDropdownRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    };

    if (dropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  const flagImages = {
    es: 'https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Spain.svg',
    ca: 'https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Catalonia.svg',
    en: 'https://upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg',
  };

  return (
    <header
      className={`fixed w-full top-0 z-50 transition-all duration-300 ${
        scrolled || navOpen ? 'bg-gray-900 shadow-lg' : 'bg-transparent'
      }`}
    >
      <div className="container mx-auto px-6 lg:px-16 flex justify-between items-center py-4">
        {/* Logo */}
        <div className="flex-none text-white text-3xl font-bold cursor-pointer">
          <a href="/">Gourmet Haven</a>
        </div>

        {/* Navigation Links - Desktop */}
        <nav className="hidden md:flex flex-1 justify-center space-x-8 items-center">
          <a href="/restaurante1" className="text-white hover:text-blue-500 transition cursor-pointer">
            {t('Home')}
          </a>
          <a href="#about" className="text-white hover:text-blue-500 transition cursor-pointer">
            {t('about')}
          </a>
          <a href="/restaurante1/menu" className="text-white hover:text-blue-500 transition cursor-pointer">
            {t('menu')}
          </a>
          <a href="#gallery" className="text-white hover:text-blue-500 transition cursor-pointer">
            {t('gallery')}
          </a>
          <a href="#contact" className="text-white hover:text-blue-500 transition cursor-pointer">
            {t('contact')}
          </a>

          {/* Language Selector - Desktop */}
          <div className="relative hidden md:block" ref={desktopDropdownRef}>
            <button
              className="flex items-center space-x-2 focus:outline-none"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              <img
                src={flagImages[selectedLanguage]}
                alt="Selected Language"
                className="w-6 h-6 rounded-full"
              />
            </button>
            {dropdownOpen && (
              <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-32 bg-white rounded-md shadow-lg py-2 z-20">
                <button
                  className="flex items-center space-x-2 px-4 py-2 w-full hover:bg-gray-100"
                  onClick={(e) => handleLanguageChange('es', e)}
                >
                  <img
                    src={flagImages.es}
                    alt="Español"
                    className="w-5 h-5 rounded-full"
                  />
                  <span>Español</span>
                </button>
                <button
                  className="flex items-center space-x-2 px-4 py-2 w-full hover:bg-gray-100"
                  onClick={(e) => handleLanguageChange('ca', e)}
                >
                  <img
                    src={flagImages.ca}
                    alt="Català"
                    className="w-5 h-5 rounded-full"
                  />
                  <span>Català</span>
                </button>
                <button
                  className="flex items-center space-x-2 px-4 py-2 w-full hover:bg-gray-100"
                  onClick={(e) => handleLanguageChange('en', e)}
                >
                  <img
                    src={flagImages.en}
                    alt="English"
                    className="w-5 h-5 rounded-full"
                  />
                  <span>English</span>
                </button>
              </div>
            )}
          </div>
        </nav>

        {/* CTA Button with Framer Motion Animation - Desktop */}
        <div className="hidden md:flex flex-none ml-auto">
          <motion.a
            href="/restaurante1/reservations"
            className="relative inline-block px-6 py-2 text-base font-semibold text-white bg-blue-600 rounded-full overflow-hidden hover:bg-blue-700 focus:outline-none"
            initial={{ boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)' }}
            whileHover={{
              scale: 1.05,
              boxShadow: '0px 0px 15px rgba(0, 120, 255, 0.6)',
            }}
            transition={{ type: 'spring', stiffness: 200, damping: 10 }}
          >
            {t('reserve')}
          </motion.a>
        </div>

        {/* Language Selector - Mobile */}
        <div className="md:hidden relative" ref={mobileDropdownRef}>
          <button
            className="flex items-center space-x-2 focus:outline-none"
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            <img
              src={flagImages[selectedLanguage]}
              alt="Selected Language"
              className="w-6 h-6 rounded-full"
            />
          </button>
          {dropdownOpen && (
            <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-32 bg-white rounded-md shadow-lg py-2 z-20">
              <button
                className="flex items-center space-x-2 px-4 py-2 w-full hover:bg-gray-100"
                onClick={(e) => handleLanguageChange('es', e)}
              >
                <img
                  src={flagImages.es}
                  alt="Español"
                  className="w-5 h-5 rounded-full"
                />
                <span>Español</span>
              </button>
              <button
                className="flex items-center space-x-2 px-4 py-2 w-full hover:bg-gray-100"
                onClick={(e) => handleLanguageChange('ca', e)}
              >
                <img
                  src={flagImages.ca}
                  alt="Català"
                  className="w-5 h-5 rounded-full"
                />
                <span>Català</span>
              </button>
              <button
                className="flex items-center space-x-2 px-4 py-2 w-full hover:bg-gray-100"
                onClick={(e) => handleLanguageChange('en', e)}
              >
                <img
                  src={flagImages.en}
                  alt="English"
                  className="w-5 h-5 rounded-full"
                />
                <span>English</span>
              </button>
            </div>
          )}
        </div>

        {/* Hamburger Menu - Mobile */}
        <div className="md:hidden flex items-center">
          <div className="text-white text-2xl cursor-pointer" onClick={toggleNav}>
            {navOpen ? <FaTimes /> : <FaBars />}
          </div>
        </div>
      </div>

      {/* Mobile Dropdown Menu */}
      {navOpen && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
          className="md:hidden bg-gray-900 text-white py-4"
        >
          <nav className="flex flex-col items-center space-y-4">
            <a href="#about" onClick={toggleNav} className="hover:text-blue-500 cursor-pointer">
              {t('about')}
            </a>
            <a href="/restaurante1/menu" onClick={toggleNav} className="hover:text-blue-500 cursor-pointer">
              {t('menu')}
            </a>
            <a href="#gallery" onClick={toggleNav} className="hover:text-blue-500 cursor-pointer">
              {t('gallery')}
            </a>
            <a href="#contact" onClick={toggleNav} className="hover:text-blue-500 cursor-pointer">
              {t('contact')}
            </a>
            <a
              href="/reservations"
              onClick={toggleNav}
              className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition"
            >
              {t('reserve')}
            </a>
          </nav>
        </motion.div>
      )}
    </header>
  );
};

export default Navbar;
