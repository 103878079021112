// Navbar.js
import React, { useState, useEffect } from 'react';
import Register from '../Session/Register';
import Login from '../Session/Login';
import { DarkMode } from '../Utils/DarkMode';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid';

function Navbar({ onThemeChange }) {
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  // Check login status on component mount
  useEffect(() => {
    checkLoginStatus();
  }, []);

  const checkLoginStatus = async () => {
    try {
      const response = await fetch('https://tempestgf.zapto.org/api/checkLogin', {
        method: 'GET',
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        console.log('Datos de login:', data); // Para depuración
        setIsLoggedIn(data.loggedIn);
        setUsername(data.username);
        setIsAdmin(data.isAdmin); // Update isAdmin state
      } else {
        setIsLoggedIn(false);
        setUsername('');
        setIsAdmin(false);
      }
    } catch (error) {
      console.error('Error checking login status:', error);
      setIsLoggedIn(false);
      setUsername('');
      setIsAdmin(false);
    }
  };

  const handleLoginClick = () => {
    setShowLoginModal(true);
  };

  const handleRegisterClick = () => {
    setShowRegisterModal(true);
  };

  const handleCloseModalRegister = () => {
    setShowRegisterModal(false);
  };

  const handleCloseModalLogin = () => {
    setShowLoginModal(false);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLogout = async () => {
    try {
      const response = await fetch('https://tempestgf.zapto.org/api/logout', {
        method: 'POST',
        credentials: 'include',
      });
      if (response.ok) {
        setIsLoggedIn(false);
        setUsername('');
        setIsDropdownOpen(false);
        setIsAdmin(false); // Reset isAdmin state
        window.location.href = '/';
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleLogin = async () => {
    setIsLoggedIn(true);
    setShowLoginModal(false);
    await checkLoginStatus(); // Update isAdmin after login
  };

  const menuItems = [
    { name: 'Home', href: '/#home' },
    { name: 'Blog', href: '/Blog' },
    { name: 'Forum', href: '/Forum' },
    { name: 'AI', href: '/AI' },
    { name: 'Tor', href: 'http://tempestgf.zapto.org' },
  ];

  const menuItemClassNames =
    'text-base py-3 px-5 text-[var(--text-color)] font-semibold no-underline rounded-md transition-colors duration-300 hover:text-[var(--hover-color)] hover:bg-[var(--secondary-color)]';

  const mobileMenuItemClassNames =
    'text-2xl py-4 px-6 text-[var(--text-color)] font-semibold no-underline rounded-md transition-colors duration-300 hover:text-[var(--hover-color)] hover:bg-[var(--secondary-color)]';

  return (
    <header className="fixed top-0 left-0 w-full z-[1000] bg-[var(--bg-color)] py-[18px] px-4 md:px-[10%] shadow-md transition-colors duration-300 flex items-center justify-between">
      <a
        href="/"
        className="text-2xl font-bold text-[var(--text-color)] no-underline hover:text-[var(--hover-color)]"
      >
        tempest
      </a>
      {/* Desktop Navigation Menu */}
      <ul className="hidden md:flex md:flex-row justify-center flex-1 list-none space-y-0 md:space-x-5">
        {menuItems.map((item) => (
          <li key={item.name}>
            <a href={item.href} className={menuItemClassNames}>
              {item.name}
            </a>
          </li>
        ))}
        {isLoggedIn ? (
          <li className="relative">
            <a
              className={`${menuItemClassNames} cursor-pointer`}
              onClick={handleDropdownToggle}
            >
              {username}
            </a>
            {isDropdownOpen && (
              <div className="absolute top-full mt-2 right-0 bg-white min-w-[160px] shadow-lg z-10 rounded-lg overflow-hidden">
                <a
                  href="/profile"
                  className="block text-[#333333] py-3 px-4 no-underline text-sm transition-colors duration-300 hover:bg-[#f1f1f1] hover:text-black"
                  onClick={() => setIsDropdownOpen(false)}
                >
                  Profile
                </a>
                {isAdmin && (
                  <>
                    <a
                      href="/ControlPanel"
                      className="block text-[#333333] py-3 px-4 no-underline text-sm transition-colors duration-300 hover:bg-[#f1f1f1] hover:text-black"
                      onClick={() => setIsDropdownOpen(false)}
                    >
                      Control Panel
                    </a>
                    <a
                      href="/publish"
                      className="block text-[#333333] py-3 px-4 no-underline text-sm transition-colors duration-300 hover:bg-[#f1f1f1] hover:text-black"
                      onClick={() => setIsDropdownOpen(false)}
                    >
                      Publish Article
                    </a>
                  </>
                )}
                <a
                  href="#"
                  onClick={handleLogout}
                  className="block text-[#333333] py-3 px-4 no-underline text-sm transition-colors duration-300 hover:bg-[#f1f1f1] hover:text-black"
                >
                  Logout
                </a>
              </div>
            )}
          </li>
        ) : (
          <>
            <li>
              <a
                onClick={handleLoginClick}
                className={`${menuItemClassNames} cursor-pointer`}
              >
                Login
              </a>
            </li>
            <li>
              <a
                onClick={handleRegisterClick}
                className={`${menuItemClassNames} cursor-pointer`}
              >
                Register
              </a>
            </li>
          </>
        )}
      </ul>
      {/* Hamburger Icon and DarkMode */}
      <div className="flex items-center">
        {/* DarkMode component in desktop view */}
        <div className="hidden md:block">
          <DarkMode onThemeChange={onThemeChange} />
        </div>
        {/* Hamburger Icon */}
        <div className="md:hidden">
          <Bars3Icon
            className="h-8 w-8 text-[var(--text-color)] cursor-pointer"
            onClick={handleMobileMenuToggle}
          />
        </div>
      </div>
      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="fixed inset-0 bg-[var(--bg-color)] z-50 flex flex-col items-center justify-center space-y-6">
          {/* Close Icon */}
          <div className="absolute top-0 right-0 p-4">
            <XMarkIcon
              className="h-8 w-8 text-[var(--text-color)] cursor-pointer"
              onClick={handleMobileMenuToggle}
            />
          </div>
          {/* Mobile Menu Options */}
          <ul className="flex flex-col items-center space-y-6">
            {menuItems.map((item) => (
              <li key={item.name}>
                <a
                  href={item.href}
                  className={mobileMenuItemClassNames}
                  onClick={handleMobileMenuToggle}
                >
                  {item.name}
                </a>
              </li>
            ))}
            {isLoggedIn ? (
              <>
                <li>
                  <a
                    href="/profile"
                    className={mobileMenuItemClassNames}
                    onClick={handleMobileMenuToggle}
                  >
                    Profile
                  </a>
                </li>
                {isAdmin && (
                  <>
                    <li>
                      <a
                        href="/ControlPanel"
                        className={mobileMenuItemClassNames}
                        onClick={handleMobileMenuToggle}
                      >
                        Control Panel
                      </a>
                    </li>
                    <li>
                      <a
                        href="/publish"
                        className={mobileMenuItemClassNames}
                        onClick={handleMobileMenuToggle}
                      >
                        Publish Article
                      </a>
                    </li>
                  </>
                )}
                <li>
                  <a
                    onClick={() => {
                      handleLogout();
                      handleMobileMenuToggle();
                    }}
                    className={`${mobileMenuItemClassNames} cursor-pointer`}
                  >
                    Logout
                  </a>
                </li>
              </>
            ) : (
              <>
                <li>
                  <a
                    onClick={() => {
                      handleLoginClick();
                      handleMobileMenuToggle();
                    }}
                    className={`${mobileMenuItemClassNames} cursor-pointer`}
                  >
                    Login
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      handleRegisterClick();
                      handleMobileMenuToggle();
                    }}
                    className={`${mobileMenuItemClassNames} cursor-pointer`}
                  >
                    Register
                  </a>
                </li>
              </>
            )}
            {/* DarkMode component in mobile view */}
            <li>
              <DarkMode onThemeChange={onThemeChange} />
            </li>
          </ul>
        </div>
      )}
      {showRegisterModal && <Register onClose={handleCloseModalRegister} />}
      {showLoginModal && (
        <Login onClose={handleCloseModalLogin} onLogin={handleLogin} />
      )}
    </header>
  );
}

export default Navbar;
