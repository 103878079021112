// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      // Navigation and Sections
      about: 'About',
      menu: 'Menu',
      gallery: 'Gallery',
      contact: 'Contact',
      reserve: 'Reserve Now',
      testimonialsTitle: 'Testimonials',
      reservationTitle: 'Make a Reservation',
      reservationDescription: 'Book your table online easily and quickly.',
      reserveTable: 'Reserve Now',
      galleryTitle: 'Gallery',
      showMore: 'Show More',
      close: 'Close',
      menuTitle: 'Our Menu',
      sendMessage: 'Send Message',
      newsletter: 'Newsletter',
      followUs: 'Follow Us',
      title: 'Gourmet Haven',
      backToCover: 'Back to cover',

      // Hero Section
      heroTitle: 'Welcome to Gourmet Haven',
      heroSubtitle: 'Discover the Art of Fine Dining',
      heroButton: 'Explore Our Menu',

      // About Section
      aboutTitle: 'Our Story',
      aboutParagraph1:
        'At {{brand}}, our journey began with a simple idea: to craft exceptional dishes that leave a lasting impression. Led by renowned chef Marco Santorini, who brings over a decade of experience, we combine Mediterranean flavors with cutting-edge techniques to offer a culinary experience like no other.',
      aboutParagraph2:
        "Whether you're here for an intimate dinner or a special occasion, our team is dedicated to creating an ambiance of elegance and warmth. We believe that fine dining is not just about food, but about creating unforgettable moments.",
      exquisiteDishes: 'Exquisite Dishes',
      fineWineSelection: 'Fine Wine Selection',
      awardWinningService: 'Award-Winning Service',

      // Menu Section
      menuDescription1:
        'At {{brand}}, our menu is curated to offer a dining experience like no other. With a perfect blend of traditional and contemporary culinary artistry, each dish celebrates the freshest seasonal ingredients, enhancing bold flavors with each bite.',
      menuDescription2:
        'From elegant entrées to refined tasting menus, paired with a selection of world-class wines, our menu promises an unforgettable sensory journey.',
      exploreMenuButton: 'Explore the Menu',

      // Footer
      footerAboutTitle: 'About Gourmet Haven',
      footerAboutDescription:
        'Gourmet Haven is dedicated to providing a unique dining experience with a fusion of Mediterranean and modern cuisine. Join us and experience the art of fine dining!',
      footerAddress: '123 Culinary Street, Foodie Town',
      footerPhone: 'Phone: (123) 456-7890',
      newsletterTitle: 'Newsletter',
      newsletterDescription:
        'Subscribe to our newsletter to stay updated with our latest offers and seasonal menus!',
      subscribe: 'Subscribe',
      followUsDescription: 'Stay connected on our social channels:',

      // Contact Section
      contactTitle: 'Get in Touch',
      contactDescription:
        "We'd love to hear from you. Please fill out the form below to get in touch.",
      contactFormName: 'Your Name',
      contactFormEmail: 'Your Email',
      contactFormMessage: 'Your Message',
      contactThanks: "Thank you for your message! We'll get back to you shortly.",
      sendMessage: 'Send Message',
      contactInfoTitle: 'Contact Information',
      contactInfoDescription:
        'Feel free to reach out to us through any of the following methods:',
      ourAddress: 'Our Address',
      callUs: 'Call Us',
      emailUs: 'Email Us',
      mapTitle: 'Our Location',
      mapDescription: 'Find us on the map below.',

      // Menu Categories
      starters: 'Starters',
      mainCourses: 'Main Courses',
      desserts: 'Desserts',
      drinks: 'Drinks',

      // Menu Items
      bruschetta: 'Bruschetta',
      bruschettaDesc: 'Toasted bread with tomatoes, basil, and olive oil.',
      capreseSalad: 'Caprese Salad',
      capreseSaladDesc: 'Fresh mozzarella, tomatoes, and basil.',
      garlicPrawns: 'Garlic Prawns',
      garlicPrawnsDesc: 'Prawns sautéed in garlic butter.',
      soup: 'Soup of the Day',
      soupDesc: 'Ask your server for today’s special.',
      stuffedMushrooms: 'Stuffed Mushrooms',
      stuffedMushroomsDesc: 'Mushrooms stuffed with cheese and herbs.',

      beefTenderloin: 'Beef Tenderloin',
      beefTenderloinDesc: 'Juicy and tender beef cooked to perfection.',
      chickenParmesan: 'Chicken Parmesan',
      chickenParmesanDesc: 'Breaded chicken breast topped with marinara and cheese.',
      grilledSalmon: 'Grilled Salmon',
      grilledSalmonDesc: 'Fresh salmon grilled with herbs and lemon.',
      lambChops: 'Lamb Chops',
      lambChopsDesc: 'Succulent lamb chops seasoned with garlic and rosemary.',
      pastaPrimavera: 'Pasta Primavera',
      pastaPrimaveraDesc: 'Pasta with fresh seasonal vegetables.',
      risotto: 'Risotto',
      risottoDesc: 'Creamy risotto with mushrooms and parmesan.',

      cheesecake: 'Cheesecake',
      cheesecakeDesc: 'Classic creamy cheesecake with a graham cracker crust.',
      chocolateLava: 'Chocolate Lava Cake',
      chocolateLavaDesc: 'Warm chocolate cake with a molten center.',
      pannaCotta: 'Panna Cotta',
      pannaCottaDesc: 'Smooth and creamy Italian dessert.',
      tiramisu: 'Tiramisu',
      tiramisuDesc: 'Traditional Italian coffee-flavored dessert.',

      cappuccino: 'Cappuccino',
      cappuccinoDesc: 'Espresso with steamed milk and foam.',
      espresso: 'Espresso',
      espressoDesc: 'Strong and bold espresso shot.',
      redWine: 'Red Wine',
      redWineDesc: 'A selection of fine red wines.',
      sparklingWater: 'Sparkling Water',
      sparklingWaterDesc: 'Refreshing sparkling mineral water.',
      whiteWine: 'White Wine',
      whiteWineDesc: 'A selection of crisp white wines.',

      // Testimonials
      testimonial1_quote:
        'An unforgettable dining experience! Every dish was exquisite and the service was top-notch.',
      testimonial1_name: 'Emily Johnson',
      testimonial2_quote:
        'The ambiance and food were absolutely incredible. I highly recommend the Gourmet Haven experience.',
      testimonial2_name: 'Michael Smith',
      testimonial3_quote:
        'A true culinary masterpiece. Every bite was full of flavor and the atmosphere was amazing!',
      testimonial3_name: 'Sarah Williams',

      // Reservation Form
      reservationDescription: 'Book your table online easily and quickly.',
      name: 'Name',
      email: 'Email',
      phone: 'Phone',
      date: 'Date',
      time: 'Time',
      guests: 'Number of Guests',
      submit: 'Submit',
      reservationSuccess: 'Your reservation has been received!',
      reservationError: 'There was an error submitting your reservation.',
      all_rights_reserved: 'All rights reserved.',

      // Additional Keys
      availableDates: 'Available Dates',
      datesInfo: 'Check our available dates on the calendar.',
      availableTimes: 'Available Times',
      timesInfo: 'Choose your preferred dining time.',
      guestPolicy: 'Guest Policy',
      guestInfo: 'Maximum of 8 guests per table.',
      isRequired: 'is required',
    },
  },
  es: {
    translation: {
      // Navegación y secciones
      about: 'Sobre Nosotros',
      menu: 'Menú',
      gallery: 'Galería',
      contact: 'Contacto',
      reserve: 'Reserva Ahora',
      testimonialsTitle: 'Testimonios',
      reservationTitle: 'Reserva una Mesa',
      reservationDescription: 'Reserva tu mesa en línea de forma fácil y rápida.',
      reserveTable: 'Reservar Ahora',
      galleryTitle: 'Galería',
      showMore: 'Ver más',
      close: 'Cerrar',
      menuTitle: 'Nuestro Menú',
      sendMessage: 'Enviar Mensaje',
      newsletter: 'Boletín',
      followUs: 'Síguenos',
      title: 'Gourmet Haven',
      backToCover: 'Vuelve a la portada',

      // Hero Section
      heroTitle: 'Bienvenido a Gourmet Haven',
      heroSubtitle: 'Descubre el Arte de la Alta Cocina',
      heroButton: 'Explora Nuestro Menú',

      // About Section
      aboutTitle: 'Nuestra Historia',
      aboutParagraph1:
        'En {{brand}}, nuestro viaje comenzó con una idea simple: crear platos excepcionales que dejen una impresión duradera. Dirigidos por el renombrado chef Marco Santorini, quien aporta más de una década de experiencia, combinamos sabores mediterráneos con técnicas de vanguardia para ofrecer una experiencia culinaria única.',
      aboutParagraph2:
        'Ya sea que estés aquí para una cena íntima o una ocasión especial, nuestro equipo está dedicado a crear un ambiente de elegancia y calidez. Creemos que la alta cocina no solo se trata de comida, sino de crear momentos inolvidables.',
      exquisiteDishes: 'Platos Exquisitos',
      fineWineSelection: 'Selección de Vinos Finos',
      awardWinningService: 'Servicio Galardonado',

      // Menu Section
      menuDescription1:
        'En {{brand}}, nuestro menú está diseñado para ofrecer una experiencia gastronómica como ninguna otra. Con una mezcla perfecta de arte culinario tradicional y contemporáneo, cada plato celebra los ingredientes de temporada más frescos, realzando sabores audaces con cada bocado.',
      menuDescription2:
        'Desde entradas elegantes hasta menús de degustación refinados, acompañados de una selección de vinos de clase mundial, nuestro menú promete un viaje sensorial inolvidable.',
      exploreMenuButton: 'Explora el Menú',

      // Footer
      footerAboutTitle: 'Sobre Gourmet Haven',
      footerAboutDescription:
        'Gourmet Haven está dedicado a proporcionar una experiencia gastronómica única con una fusión de cocina mediterránea y moderna. ¡Únete a nosotros y experimenta el arte de la alta cocina!',
      footerAddress: '123 Calle Culinaria, Ciudad Gastronómica',
      footerPhone: 'Teléfono: (123) 456-7890',
      newsletterTitle: 'Boletín',
      newsletterDescription:
        '¡Suscríbete a nuestro boletín para mantenerte actualizado con nuestras últimas ofertas y menús de temporada!',
      subscribe: 'Suscribirse',
      followUsDescription: 'Mantente conectado en nuestras redes sociales:',

      // Contact Section
      contactTitle: 'Ponte en Contacto',
      contactDescription:
        'Nos encantaría saber de ti. Por favor, completa el siguiente formulario para ponerte en contacto.',
      contactFormName: 'Tu Nombre',
      contactFormEmail: 'Tu Correo Electrónico',
      contactFormMessage: 'Tu Mensaje',
      contactThanks: '¡Gracias por tu mensaje! Nos pondremos en contacto contigo pronto.',
      sendMessage: 'Enviar Mensaje',
      contactInfoTitle: 'Información de Contacto',
      contactInfoDescription:
        'No dudes en comunicarte con nosotros a través de cualquiera de los siguientes métodos:',
      ourAddress: 'Nuestra Dirección',
      callUs: 'Llámanos',
      emailUs: 'Envíanos un Correo',
      mapTitle: 'Nuestra Ubicación',
      mapDescription: 'Encuéntranos en el mapa a continuación.',

      // Menu Categories
      starters: 'Entradas',
      mainCourses: 'Platos Principales',
      desserts: 'Postres',
      drinks: 'Bebidas',

      // Menu Items
      bruschetta: 'Bruschetta',
      bruschettaDesc: 'Pan tostado con tomates, albahaca y aceite de oliva.',
      capreseSalad: 'Ensalada Caprese',
      capreseSaladDesc: 'Mozzarella fresca, tomates y albahaca.',
      garlicPrawns: 'Gambas al Ajillo',
      garlicPrawnsDesc: 'Gambas salteadas en mantequilla de ajo.',
      soup: 'Sopa del Día',
      soupDesc: 'Consulta con el camarero la especialidad de hoy.',
      stuffedMushrooms: 'Champiñones Rellenos',
      stuffedMushroomsDesc: 'Champiñones rellenos de queso y hierbas.',

      beefTenderloin: 'Solomillo de Ternera',
      beefTenderloinDesc: 'Carne de ternera jugosa y tierna cocinada a la perfección.',
      chickenParmesan: 'Pollo Parmesano',
      chickenParmesanDesc: 'Pechuga de pollo empanada con marinara y queso.',
      grilledSalmon: 'Salmón a la Parrilla',
      grilledSalmonDesc: 'Salmón fresco a la parrilla con hierbas y limón.',
      lambChops: 'Chuletas de Cordero',
      lambChopsDesc: 'Chuletas de cordero suculentas sazonadas con ajo y romero.',
      pastaPrimavera: 'Pasta Primavera',
      pastaPrimaveraDesc: 'Pasta con verduras frescas de temporada.',
      risotto: 'Risotto',
      risottoDesc: 'Risotto cremoso con champiñones y parmesano.',

      cheesecake: 'Cheesecake',
      cheesecakeDesc: 'Clásico cheesecake cremoso con base de galleta.',
      chocolateLava: 'Pastel de Lava de Chocolate',
      chocolateLavaDesc: 'Pastel de chocolate caliente con centro fundido.',
      pannaCotta: 'Panna Cotta',
      pannaCottaDesc: 'Postre suave y cremoso italiano.',
      tiramisu: 'Tiramisú',
      tiramisuDesc: 'Postre italiano tradicional con sabor a café.',

      cappuccino: 'Capuchino',
      cappuccinoDesc: 'Espresso con leche al vapor y espuma.',
      espresso: 'Espresso',
      espressoDesc: 'Fuerte y audaz shot de espresso.',
      redWine: 'Vino Tinto',
      redWineDesc: 'Selección de finos vinos tintos.',
      sparklingWater: 'Agua con Gas',
      sparklingWaterDesc: 'Refrescante agua mineral con gas.',
      whiteWine: 'Vino Blanco',
      whiteWineDesc: 'Selección de vinos blancos crujientes.',

      // Testimonials
      testimonial1_quote:
        '¡Una experiencia gastronómica inolvidable! Cada plato fue exquisito y el servicio de primera categoría.',
      testimonial1_name: 'María García',
      testimonial2_quote:
        'El ambiente y la comida fueron absolutamente increíbles. Recomiendo encarecidamente la experiencia de Gourmet Haven.',
      testimonial2_name: 'Carlos López',
      testimonial3_quote:
        'Una verdadera obra maestra culinaria. ¡Cada bocado estaba lleno de sabor y el ambiente era asombroso!',
      testimonial3_name: 'Ana Fernández',

      // Reservation Form
      reservationDescription: 'Reserva tu mesa en línea de forma fácil y rápida.',
      name: 'Nombre',
      email: 'Correo Electrónico',
      phone: 'Teléfono',
      date: 'Fecha',
      time: 'Hora',
      guests: 'Número de Personas',
      submit: 'Enviar',
      reservationSuccess: '¡Tu reserva ha sido recibida!',
      reservationError: 'Hubo un error al enviar tu reserva.',
      all_rights_reserved: 'Todos los derechos reservados.',

      // Additional Keys
      availableDates: 'Fechas Disponibles',
      datesInfo: 'Consulta nuestras fechas disponibles en el calendario.',
      availableTimes: 'Horarios Disponibles',
      timesInfo: 'Elige tu horario de comida preferido.',
      guestPolicy: 'Política de Invitados',
      guestInfo: 'Máximo de 8 invitados por mesa.',
      isRequired: 'es obligatorio',
    },
  },
  ca: {
    translation: {
      // Navegación y secciones
      about: 'Sobre Nosaltres',
      menu: 'Menú',
      gallery: 'Galeria',
      contact: 'Contacte',
      reserve: 'Reserva Ara',
      testimonialsTitle: 'Testimonis',
      reservationTitle: 'Reserva una Taula',
      reservationDescription: 'Reserva la teva taula en línia de forma fàcil i ràpida.',
      reserveTable: 'Reservar Ara',
      galleryTitle: 'Galeria',
      showMore: 'Veure més',
      close: 'Tancar',
      menuTitle: 'El Nostre Menú',
      sendMessage: 'Enviar Missatge',
      newsletter: 'Butlletí',
      followUs: 'Segueix-nos',
      title: 'Gourmet Haven',
      backToCover: 'Torna a la Portada',

      // Hero Section
      heroTitle: 'Benvingut a Gourmet Haven',
      heroSubtitle: "Descobreix l'Art de la Cuina d'Autor",
      heroButton: 'Explora el Nostre Menú',

      // About Section
      aboutTitle: 'La Nostra Història',
      aboutParagraph1:
        "A {{brand}}, el nostre viatge va començar amb una idea simple: elaborar plats excepcionals que deixin una impressió duradora. Dirigits pel reconegut xef Marco Santorini, que aporta més d'una dècada d'experiència, combinem sabors mediterranis amb tècniques innovadores per oferir una experiència culinària única.",
      aboutParagraph2:
        "Ja sigui que estiguis aquí per a un sopar íntim o una ocasió especial, el nostre equip està dedicat a crear un ambient d'elegància i calidesa. Creiem que la cuina d'autor no és només menjar, sinó crear moments inoblidables.",
      exquisiteDishes: 'Plats Exquisits',
      fineWineSelection: 'Selecció de Vins Fins',
      awardWinningService: 'Servei Guardonat',

      // Menu Section
      menuDescription1:
        "A {{brand}}, el nostre menú està dissenyat per oferir una experiència gastronòmica com cap altra. Amb una barreja perfecta d'art culinari tradicional i contemporani, cada plat celebra els ingredients de temporada més frescos, realçant sabors intensos a cada mos.",
      menuDescription2:
        "Des d'entrants elegants fins a menús de degustació refinats, acompanyats d'una selecció de vins de classe mundial, el nostre menú promet un viatge sensorial inoblidable.",
      exploreMenuButton: 'Explora el Menú',

      // Footer
      footerAboutTitle: 'Sobre Gourmet Haven',
      footerAboutDescription:
        "Gourmet Haven està dedicat a proporcionar una experiència gastronòmica única amb una fusió de cuina mediterrània i moderna. Uneix-te a nosaltres i experimenta l'art de la cuina d'autor!",
      footerAddress: '123 Carrer Culinària, Ciutat Gastronòmica',
      footerPhone: 'Telèfon: (123) 456-7890',
      newsletterTitle: 'Butlletí',
      newsletterDescription:
        "Subscriu-te al nostre butlletí per estar al dia amb les nostres últimes ofertes i menús de temporada!",
      subscribe: 'Subscriu-te',
      followUsDescription: 'Mantén-te connectat a les nostres xarxes socials:',

      // Contact Section
      contactTitle: "Contacta'ns",
      contactDescription:
        "Ens encantaria saber de tu. Si us plau, omple el següent formulari per posar-te en contacte.",
      contactFormName: 'El teu Nom',
      contactFormEmail: 'El teu Correu Electrònic',
      contactFormMessage: 'El teu Missatge',
      contactThanks: "Gràcies pel teu missatge! Ens posarem en contacte amb tu aviat.",
      sendMessage: 'Enviar Missatge',
      contactInfoTitle: 'Informació de Contacte',
      contactInfoDescription:
        "No dubtis en comunicar-te amb nosaltres a través de qualsevol dels següents mètodes:",
      ourAddress: 'La nostra Adreça',
      callUs: "Truca'ns",
      emailUs: "Envia'ns un Correu",
      mapTitle: 'La nostra Ubicació',
      mapDescription: 'Troba\'ns al mapa a continuació.',

      // Menu Categories
      starters: 'Entrants',
      mainCourses: 'Plats Principals',
      desserts: 'Postres',
      drinks: 'Begudes',

      // Menu Items
      bruschetta: 'Bruschetta',
      bruschettaDesc: "Pa torrat amb tomàquets, alfàbrega i oli d’oliva.",
      capreseSalad: 'Amanida Caprese',
      capreseSaladDesc: 'Mozzarella fresca, tomàquets i alfàbrega.',
      garlicPrawns: "Gambes al All",
      garlicPrawnsDesc: "Gambes saltades amb mantega d'all.",
      soup: 'Sopa del Dia',
      soupDesc: "Consulta el cambrer per a l’especialitat del dia.",
      stuffedMushrooms: 'Xampinyons Farcits',
      stuffedMushroomsDesc: 'Xampinyons farcits de formatge i herbes.',

      beefTenderloin: 'Filet de Vedella',
      beefTenderloinDesc: 'Vedella sucosa i tendra cuinada a la perfecció.',
      chickenParmesan: 'Pollastre Parmesà',
      chickenParmesanDesc: 'Pit de pollastre arrebossat amb marinara i formatge.',
      grilledSalmon: 'Salmó a la Graella',
      grilledSalmonDesc: 'Salmó fresc a la graella amb herbes i llimona.',
      lambChops: 'Costelles de Xai',
      lambChopsDesc: "Costelles de xai suculentes amanides amb all i romaní.",
      pastaPrimavera: 'Pasta Primavera',
      pastaPrimaveraDesc: 'Pasta amb verdures fresques de temporada.',
      risotto: 'Risotto',
      risottoDesc: "Risotto cremós amb bolets i parmesà.",

      cheesecake: 'Pastís de Formatge',
      cheesecakeDesc: 'Clàssic pastís de formatge cremós amb base de galeta.',
      chocolateLava: 'Pastís de Xocolata Lava',
      chocolateLavaDesc: 'Pastís de xocolata calent amb centre fos.',
      pannaCotta: 'Panna Cotta',
      pannaCottaDesc: 'Postre suau i cremós italià.',
      tiramisu: 'Tiramisú',
      tiramisuDesc: "Postre italià tradicional amb sabor a cafè.",

      cappuccino: 'Cappuccino',
      cappuccinoDesc: "Espresso amb llet al vapor i escuma.",
      espresso: 'Espresso',
      espressoDesc: 'Cafè intens i ric.',
      redWine: 'Vi Negre',
      redWineDesc: 'Selecció de vins negres fins.',
      sparklingWater: 'Aigua amb Gas',
      sparklingWaterDesc: 'Refrescant aigua mineral amb gas.',
      whiteWine: 'Vi Blanc',
      whiteWineDesc: 'Selecció de vins blancs cruixents.',

      // Testimonials
      testimonial1_quote:
        'Una experiència gastronòmica inoblidable! Cada plat va ser exquisit i el servei de primera categoria.',
      testimonial1_name: 'Laura Martínez',
      testimonial2_quote:
        "L'ambient i el menjar van ser absolutament increïbles. Recomano encaridament l'experiència de Gourmet Haven.",
      testimonial2_name: 'Josep Soler',
      testimonial3_quote:
        "Una veritable obra mestra culinària. Cada mos estava ple de sabor i l'ambient era sorprenent!",
      testimonial3_name: 'Marta Rovira',

      // Reservation Form
      reservationDescription: 'Reserva la teva taula en línia de forma fàcil i ràpida.',
      name: 'Nom',
      email: 'Correu Electrònic',
      phone: 'Telèfon',
      date: 'Data',
      time: 'Hora',
      guests: 'Nombre de Persones',
      submit: 'Enviar',
      reservationSuccess: 'La teva reserva ha estat rebuda!',
      reservationError: 'Hi ha hagut un error en enviar la teva reserva.',
      all_rights_reserved: 'Tots els drets reservats.',

      // Additional Keys
      availableDates: 'Dates Disponibles',
      datesInfo: 'Consulta les nostres dates disponibles al calendari.',
      availableTimes: 'Horaris Disponibles',
      timesInfo: 'Tria el teu horari de menjar preferit.',
      guestPolicy: 'Política de Convidats',
      guestInfo: 'Màxim de 8 convidats per taula.',
      isRequired: 'és obligatori',
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng:
    localStorage.getItem('language') ||
    navigator.language.slice(0, 2) ||
    'en', // Default language
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
