// src/components/Header.jsx
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';
import { motion } from 'framer-motion';
import ReactCountryFlag from 'react-country-flag';

const Header = () => {
  const { t, i18n } = useTranslation();
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  const languageMenuRef = useRef(null);
  const mobileMenuRef = useRef(null);
  const hamburgerMenuRef = useRef(null); // Nueva referencia para el botón de la hamburguesa

  // Función para cambiar el idioma
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  // Función para cambiar el tema
  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  // Efecto para aplicar la clase 'dark' al elemento html
  useEffect(() => {
    const root = window.document.documentElement;
    if (theme === 'dark') {
      root.classList.add('dark');
    } else {
      root.classList.remove('dark');
    }
  }, [theme]);

  // Cerrar el menú de idiomas al hacer clic fuera
  useEffect(() => {
    const handleClickOutsideLanguage = (event) => {
      if (languageMenuRef.current && !languageMenuRef.current.contains(event.target)) {
        setIsLanguageMenuOpen(false);
      }
    };

    if (isLanguageMenuOpen) {
      document.addEventListener('mousedown', handleClickOutsideLanguage);
    }

    // Limpiar el evento al desmontar el componente
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideLanguage);
    };
  }, [isLanguageMenuOpen]);

  // Cerrar el menú móvil al hacer clic fuera
  useEffect(() => {
    const handleClickOutsideMobileMenu = (event) => {
      if (
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target) &&
        hamburgerMenuRef.current &&
        !hamburgerMenuRef.current.contains(event.target)
      ) {
        setIsMobileMenuOpen(false);
      }
    };

    if (isMobileMenuOpen) {
      document.addEventListener('mousedown', handleClickOutsideMobileMenu);
    }

    // Limpiar el evento al desmontar el componente
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideMobileMenu);
    };
  }, [isMobileMenuOpen]);

  // Mapeo de idiomas y códigos de país
  const languages = [
    { code: 'ca', name: 'Català', countryCode: 'ES-CT' },
    { code: 'es', name: 'Español', countryCode: 'ES' },
    { code: 'en', name: 'English', countryCode: 'GB' },
  ];

  // Obtener el idioma actual
  const currentLanguage = languages.find((lang) => lang.code === i18n.language);

  return (
    <>
      <header className="fixed top-0 w-full z-50 bg-white dark:bg-gray-900 shadow-md transition-colors font-serif">
        <div className="container mx-auto py-2 px-4 flex items-center justify-between md:grid md:grid-cols-3 md:py-4 md:px-6">
          {/* Logo */}
          <div className="flex items-center md:justify-start">
            <a
              href="/"
              className="text-2xl md:text-3xl font-bold text-gold hover:text-gold-dark font-serif"
            >
              {t('title')}
            </a>
          </div>

          {/* Navbar */}
          <nav className="hidden md:flex justify-center">
            <div className="flex space-x-6">
              {['about', 'menu', 'gallery', 'contact'].map((section) => (
                <Link
                  key={section}
                  to={section}
                  smooth={true}
                  duration={500}
                  className="text-sm md:text-base text-gray-600 dark:text-white hover:text-gold cursor-pointer transition-colors font-medium"
                >
                  {t(section)}
                </Link>
              ))}
            </div>
          </nav>

          {/* Controles */}
          <div className="flex items-center space-x-2 md:justify-end md:space-x-4">
            {/* Selector de idioma con banderas */}
            <div className="relative" ref={languageMenuRef}>
              <button
                onClick={() => setIsLanguageMenuOpen(!isLanguageMenuOpen)}
                className="flex items-center focus:outline-none"
                aria-label="Change Language"
              >
                <ReactCountryFlag
                  countryCode={currentLanguage.countryCode}
                  svg
                  style={{
                    width: '1.5em',
                    height: '1.5em',
                  }}
                  title={currentLanguage.name}
                  className="text-gray-600 dark:text-white"
                />
              </button>
              {isLanguageMenuOpen && (
                <div
                  className="absolute right-0 mt-2 w-32 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md shadow-lg z-50"
                >
                  {languages.map(({ code, countryCode, name }) => (
                    <button
                      key={code}
                      onClick={() => {
                        changeLanguage(code);
                        setIsLanguageMenuOpen(false);
                      }}
                      className="flex items-center w-full px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors focus:outline-none"
                    >
                      <ReactCountryFlag
                        countryCode={countryCode}
                        svg
                        style={{
                          width: '1.5em',
                          height: '1.5em',
                        }}
                        title={name}
                        className="mr-2"
                      />
                      <span className="text-gray-700 dark:text-gray-200">
                        {name}
                      </span>
                    </button>
                  ))}
                </div>
              )}
            </div>

            {/* Botón para cambiar el tema en desktop */}
            <div className="hidden md:block">
              <button
                onClick={toggleTheme}
                className="text-gray-600 dark:text-white focus:outline-none"
                aria-label="Toggle Theme"
              >
                {theme === 'light' ? (
                  // Icono de luna para modo nocturno
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 3v1m0 16v1m8-8h1M4 12H3m15.364-7.364l-.707.707M6.343 17.657l-.707.707M17.657 17.657l-.707-.707M6.343 6.343l-.707-.707M12 5a7 7 0 00-7 7h0a7 7 0 0011.313 5.312A7 7 0 0112 5z"
                    />
                  </svg>
                ) : (
                  // Icono de sol para modo claro
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    stroke="none"
                  >
                    <path d="M12 2a1 1 0 011 1v2a1 1 0 11-2 0V3a1 1 0 011-1zM12 19a1 1 0 011 1v2a1 1 0 11-2 0v-2a1 1 0 011-1zM4.22 4.22a1 1 0 011.42 0l1.42 1.42a1 1 0 11-1.42 1.42L4.22 5.64a1 1 0 010-1.42zM16.95 16.95a1 1 0 011.42 0l1.42 1.42a1 1 0 11-1.42 1.42l-1.42-1.42a1 1 0 010-1.42zM2 12a1 1 0 011-1h2a1 1 0 110 2H3a1 1 0 01-1-1zM19 12a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1zM4.22 19.78a1 1 0 010-1.42l1.42-1.42a1 1 0 111.42 1.42L5.64 19.78a1 1 0 01-1.42 0zM16.95 7.05a1 1 0 010-1.42l1.42-1.42a1 1 0 111.42 1.42L18.36 7.05a1 1 0 01-1.42 0z" />
                    <circle cx="12" cy="12" r="5" />
                  </svg>
                )}
              </button>
            </div>

            {/* Botón del menú móvil */}
            <button
              ref={hamburgerMenuRef} // Añadimos la referencia aquí
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="md:hidden text-gray-600 dark:text-white focus:outline-none"
              aria-label="Toggle Menu"
            >
              {/* Icono de menú hamburguesa */}
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={
                    isMobileMenuOpen
                      ? 'M6 18L18 6M6 6l12 12'
                      : 'M4 6h16M4 12h16M4 18h16'
                  }
                />
              </svg>
            </button>
          </div>
        </div>

        {/* Menú móvil */}
        {isMobileMenuOpen && (
          <motion.nav
            ref={mobileMenuRef}
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            className="md:hidden bg-white dark:bg-gray-900 shadow-md overflow-hidden"
          >
            <ul className="flex flex-col items-center space-y-2 py-4 px-6">
              {['about', 'menu', 'gallery', 'contact'].map((section) => (
                <Link
                  key={section}
                  to={section}
                  smooth={true}
                  duration={500}
                  className="text-sm text-gray-600 dark:text-white hover:text-gold cursor-pointer transition-colors font-medium"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  {t(section)}
                </Link>
              ))}
            </ul>
          </motion.nav>
        )}
      </header>

      {/* Botón para cambiar el tema en mobile */}
      <div className="md:hidden">
        <button
          onClick={toggleTheme}
          className="fixed bottom-4 right-4 z-50 text-gray-600 dark:text-white focus:outline-none
                     border border-gray-800 dark:border-white bg-white dark:bg-gray-800 rounded-full p-2 shadow-lg"
          aria-label="Toggle Theme"
        >
          {theme === 'light' ? (
            // Icono de luna para modo nocturno
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 3v1m0 16v1m8-8h1M4 12H3m15.364-7.364l-.707.707M6.343 17.657l-.707.707M17.657 17.657l-.707-.707M6.343 6.343l-.707-.707M12 5a7 7 0 00-7 7h0a7 7 0 0011.313 5.312A7 7 0 0112 5z"
              />
            </svg>
          ) : (
            // Icono de sol para modo claro
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="currentColor"
              viewBox="0 0 24 24"
              stroke="none"
            >
              <path d="M12 2a1 1 0 011 1v2a1 1 0 11-2 0V3a1 1 0 011-1zM12 19a1 1 0 011 1v2a1 1 0 11-2 0v-2a1 1 0 011-1zM4.22 4.22a1 1 0 011.42 0l1.42 1.42a1 1 0 11-1.42 1.42L4.22 5.64a1 1 0 010-1.42zM16.95 16.95a1 1 0 011.42 0l1.42 1.42a1 1 0 11-1.42 1.42l-1.42-1.42a1 1 0 010-1.42zM2 12a1 1 0 011-1h2a1 1 0 110 2H3a1 1 0 01-1-1zM19 12a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1zM4.22 19.78a1 1 0 010-1.42l1.42-1.42a1 1 0 111.42 1.42L5.64 19.78a1 1 0 01-1.42 0zM16.95 7.05a1 1 0 010-1.42l1.42-1.42a1 1 0 111.42 1.42L18.36 7.05a1 1 0 01-1.42 0z" />
              <circle cx="12" cy="12" r="5" />
            </svg>
          )}
        </button>
      </div>
    </>
  );
};

export default Header;
