// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Restaurant.css */
.restaurant-app * {
    font-family: inherit !important; /* Restablece la fuente a la fuente predeterminada del navegador */
  }
  
  .restaurant-app {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }


`, "",{"version":3,"sources":["webpack://./src/components/Restaurant.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;IACI,+BAA+B,EAAE,kEAAkE;EACrG;;EAEA;IACE;;gBAEY;IACZ,mCAAmC;IACnC,kCAAkC;EACpC","sourcesContent":["/* Restaurant.css */\n.restaurant-app * {\n    font-family: inherit !important; /* Restablece la fuente a la fuente predeterminada del navegador */\n  }\n  \n  .restaurant-app {\n    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n      sans-serif;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n  }\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
