// components/MenuIntro.js
import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

const MenuIntro = () => {
  const { t } = useTranslation(); // Initialize translation hook
  const { ref: textRef, inView: textInView } = useInView({
    triggerOnce: true,
    threshold: 0.01,
    rootMargin: '0px 0px -50px 0px',
  });

  const { ref: visualRef, inView: visualInView } = useInView({
    triggerOnce: true,
    threshold: 0.01,
    rootMargin: '0px 0px -50px 0px',
  });

  return (
    <section className="relative py-16 sm:py-20 bg-gradient-to-r from-gray-900 to-gray-800">
      <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 md:gap-12 px-4 sm:px-6">
        {/* Text Section */}
        <motion.div
          ref={textRef}
          initial={{ opacity: 0, x: -30 }}
          animate={textInView ? { opacity: 1, x: 0 } : { opacity: 0, x: -30 }}
          transition={{ duration: 0.8, ease: 'easeOut' }}
          className="flex flex-col justify-center"
        >
          <h2 className="text-3xl sm:text-4xl lg:text-5xl font-extrabold text-white mb-4 sm:mb-6">
            {t('menuTitle')}
          </h2>
          <p className="text-base sm:text-lg lg:text-xl text-gray-300 leading-relaxed mb-4 sm:mb-6">
            {t('menuDescription1', { brand: 'Gourmet Haven' })}
          </p>
          <p className="text-base sm:text-lg lg:text-xl text-gray-300 leading-relaxed mb-4 sm:mb-6">
            {t('menuDescription2')}
          </p>

          {/* Updated Navy Blue Button */}
          <div className="mt-6 sm:mt-8">
            <a
              href="/restaurante1/menu"
              className="relative inline-block px-6 py-3 m-1 overflow-hidden text-base sm:text-lg font-medium text-blue-900 border-2 border-blue-900 rounded-md group"
            >
              <span className="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform -translate-x-full bg-blue-800 group-hover:translate-x-0"></span>
              <span className="relative z-10 text-blue-400 transition-colors duration-300 ease-in-out group-hover:text-white">
                {t('exploreMenuButton')}
              </span>
            </a>
          </div>
        </motion.div>

        {/* Visual Section (Signature Dish Video or Image) */}
        <motion.div
          ref={visualRef}
          initial={{ opacity: 0, y: 30 }}
          animate={visualInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
          transition={{ duration: 0.8, ease: 'easeOut' }}
          className="relative"
        >
          <video
            autoPlay
            muted
            loop
            playsInline
            className="rounded-lg shadow-lg object-cover w-full h-56 sm:h-72 md:h-96 lg:h-full"
          >
            <source src="/videos/dish.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </motion.div>
      </div>
    </section>
  );
};

export default MenuIntro;
