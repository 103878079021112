import React, { useState } from 'react';

const AI = () => {
  const [message, setMessage] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => setMessage(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!message.trim()) {
      setError('Please enter a message');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const res = await fetch('https://tempestgf.zapto.org/api/chat', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message }),
      });

      if (!res.body) {
        throw new Error('Streaming not supported');
      }

      let result = '';
      const reader = res.body.getReader();

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        result += new TextDecoder().decode(value);
        setResponse(result);
      }

      setMessage('');
    } catch (error) {
      console.error('Error in request:', error);
      setError(
        error.message === 'Failed to fetch'
          ? 'Network error, please try again later.'
          : 'Error communicating with server'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen p-5 bg-gradient-to-r from-blue-500 via-purple-600 to-pink-500">
      <div className="bg-white/20 backdrop-blur-md p-8 rounded-3xl shadow-xl max-w-lg w-full text-center border border-white/30">
        <h1 className="text-4xl font-extrabold mb-5 text-white drop-shadow-lg">Ask the AI</h1>
        <p className="text-lg mb-6 text-white/80">Type your message and get a response!</p>
        <form onSubmit={handleSubmit} className="flex flex-col items-center mb-6" aria-live="polite">
          <input
            type="text"
            value={message}
            onChange={handleChange}
            placeholder="Type your message..."
            disabled={loading}
            aria-label="Message input"
            className="w-full p-3 text-lg rounded-lg mb-4 bg-white/10 text-white placeholder-white/70 border border-white/40 focus:border-pink-400 focus:outline-none focus:ring-4 focus:ring-pink-200 transition-all disabled:opacity-50 disabled:cursor-not-allowed"
          />
          <button
            type="submit"
            disabled={loading || !message.trim()}
            className={`w-full px-6 py-3 text-lg font-semibold text-white bg-pink-500 rounded-full shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105 hover:bg-pink-600 focus:outline-none focus:ring-4 focus:ring-pink-300 ${
              loading || !message.trim() ? 'opacity-50 cursor-not-allowed hover:scale-100' : ''
            }`}
            aria-busy={loading}
          >
            {loading ? 'Sending...' : 'Send'}
          </button>
        </form>
        {loading && (
          <div className="flex justify-center items-center mt-4">
            <div className="w-10 h-10 border-4 border-t-4 border-white/40 border-t-pink-400 rounded-full animate-spin"></div>
          </div>
        )}
        {error && <p className="text-red-400 mt-4" role="alert">{error}</p>}
        {response && (
          <div className="mt-6 p-4 rounded-lg border border-white/40 bg-white/10 text-white">
            <p className="text-lg">{response}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AI;
