// components/Hero.js
import React, { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaChevronDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const Hero = () => {
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);
  const [currentVideo, setCurrentVideo] = useState(1);

  const handleVideoEnd = () => {
    if (currentVideo === 1) {
      setCurrentVideo(2);
      videoRef2.current.play();
    }
  };

  const handleScrollDown = () => {
    const aboutSection = document.querySelector('#about');
    if (aboutSection) {
      const offsetTop = aboutSection.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: offsetTop - 60,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const setHeroHeight = () => {
      const vh = window.visualViewport
        ? window.visualViewport.height * 0.01
        : window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    setHeroHeight();
    window.addEventListener('resize', setHeroHeight);

    return () => {
      window.removeEventListener('resize', setHeroHeight);
    };
  }, []);

  return (
    <section
      id="home"
      className="relative flex flex-col items-center justify-center overflow-hidden bg-fixed bg-center bg-no-repeat"
      style={{ height: 'calc(var(--vh, 1vh) * 100)' }}
    >
      <video
        ref={videoRef1}
        autoPlay
        muted
        playsInline
        className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-500 ${
          currentVideo === 1 ? 'opacity-100' : 'opacity-0'
        }`}
        onEnded={handleVideoEnd}
      >
        <source src="videos/exterior.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <video
        ref={videoRef2}
        muted
        loop
        playsInline
        className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-500 ${
          currentVideo === 2 ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <source src="videos/interior.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="absolute inset-0 bg-gradient-to-b from-black/80 via-black/60 to-black/80"></div>

      <motion.div
        ref={ref}
        className="relative z-10 text-center text-white px-6"
        initial={{ opacity: 0, y: 100 }}
        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
        transition={{ duration: 1.5 }}
      >
        <h1
          className="font-extrabold mb-4 drop-shadow-2xl"
          style={{
            fontSize: 'clamp(2rem, 6vw, 4rem)', // Ajusta el tamaño entre 2rem y 4rem según el ancho de la pantalla
            lineHeight: '1.2', // Ajusta la altura de línea para mejorar la legibilidad
          }}
        >
          {t('heroTitle')}
        </h1>
        <p
          className="mt-4 text-gray-200 drop-shadow-2xl"
          style={{
            fontSize: 'clamp(1rem, 4vw, 1.5rem)', // Ajusta el tamaño entre 1rem y 1.5rem según el ancho de la pantalla
            lineHeight: '1.4',
          }}
        >
          {t('heroSubtitle')}
        </p>

        <motion.a
          href="/restaurante1/menu"
          className="mt-8 inline-block px-8 py-3 text-lg font-semibold text-white bg-blue-600 rounded-full shadow-lg hover:bg-blue-700 transition-all duration-300 focus:outline-none"
          whileHover={{
            scale: 1.05,
            boxShadow: '0px 8px 15px rgba(0, 123, 255, 0.4)',
          }}
          transition={{ type: 'spring', stiffness: 300, damping: 10 }}
        >
          {t('heroButton')}
        </motion.a>
      </motion.div>

      <div
        onClick={handleScrollDown}
        className="absolute bottom-4 z-10 text-white cursor-pointer animate-bounce transition-transform hover:scale-110"
      >
        <FaChevronDown
          size={40}
          className="hover:text-blue-500 transition-colors duration-300"
        />
      </div>
    </section>
  );
};

export default Hero;
