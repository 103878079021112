// components/Reservation.js
import React, { useState } from 'react';
import Navbar from './Navbar'; // Importa el Navbar
import Footer from './Footer'; // Importa el Footer
import { FaCalendarAlt, FaClock, FaUser } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';

const Reservation = () => {
  const { t } = useTranslation();
  const [reservationData, setReservationData] = useState({
    date: '',
    time: '',
    guests: 1,
    specialRequest: '',
  });
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { ref: formRef, inView: formInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      setIsSubmitted(true);
      // Aquí puedes agregar la lógica para enviar los datos al servidor
    } else {
      setErrors(validationErrors);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!reservationData.date)
      newErrors.date = `${t('reservationFormDate')} ${t('isRequired')}`;
    if (!reservationData.time)
      newErrors.time = `${t('reservationFormTime')} ${t('isRequired')}`;
    if (!reservationData.guests || reservationData.guests < 1)
      newErrors.guests = `${t('reservationFormGuests')} ${t('isRequired')}`;
    return newErrors;
  };

  return (
    <>
      <Navbar />
      <section
        id="reservation"
        className="bg-gradient-to-br from-gray-800 to-gray-900 py-16 text-white overflow-hidden min-h-screen"
      >
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-16">
          <motion.div
            ref={formRef}
            initial={{ opacity: 0, x: -100 }}
            animate={formInView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1.2 }}
            className="bg-gray-800 p-8 rounded-lg shadow-xl"
          >
            <h2 className="text-4xl font-bold mb-8 text-center md:text-left">
              {t('reservationTitle')}
            </h2>
            {isSubmitted ? (
              <p className="text-green-400 text-xl">{t('reservationThanks')}</p>
            ) : (
              <form onSubmit={handleSubmit} className="space-y-6">
                {/* Fecha */}
                <div className="flex flex-col">
                  <label htmlFor="date" className="text-lg">
                    {t('reservationFormDate')}
                  </label>
                  <input
                    type="date"
                    id="date"
                    className="p-3 border border-gray-600 rounded-lg bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={reservationData.date}
                    onChange={(e) =>
                      setReservationData({ ...reservationData, date: e.target.value })
                    }
                  />
                  {errors.date && (
                    <span className="text-red-500 text-sm">{errors.date}</span>
                  )}
                </div>

                {/* Hora */}
                <div className="flex flex-col">
                  <label htmlFor="time" className="text-lg">
                    {t('reservationFormTime')}
                  </label>
                  <input
                    type="time"
                    id="time"
                    className="p-3 border border-gray-600 rounded-lg bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={reservationData.time}
                    onChange={(e) =>
                      setReservationData({ ...reservationData, time: e.target.value })
                    }
                  />
                  {errors.time && (
                    <span className="text-red-500 text-sm">{errors.time}</span>
                  )}
                </div>

                {/* Número de invitados */}
                <div className="flex flex-col">
                  <label htmlFor="guests" className="text-lg">
                    {t('reservationFormGuests')}
                  </label>
                  <input
                    type="number"
                    id="guests"
                    min="1"
                    className="p-3 border border-gray-600 rounded-lg bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={reservationData.guests}
                    onChange={(e) =>
                      setReservationData({ ...reservationData, guests: e.target.value })
                    }
                  />
                  {errors.guests && (
                    <span className="text-red-500 text-sm">{errors.guests}</span>
                  )}
                </div>

                {/* Peticiones especiales */}
                <div className="flex flex-col">
                  <label htmlFor="specialRequest" className="text-lg">
                    {t('reservationFormSpecialRequest')}
                  </label>
                  <textarea
                    id="specialRequest"
                    rows="4"
                    className="p-3 border border-gray-600 rounded-lg bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={reservationData.specialRequest}
                    onChange={(e) =>
                      setReservationData({
                        ...reservationData,
                        specialRequest: e.target.value,
                      })
                    }
                  />
                </div>

                {/* Botón de envío */}
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="w-full bg-blue-600 py-3 rounded-lg hover:bg-blue-700 transition"
                  type="submit"
                >
                  {t('reserveTable')}
                </motion.button>
              </form>
            )}
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 100 }}
            animate={formInView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1.2 }}
            className="flex flex-col justify-center items-start space-y-8 bg-gray-800 p-8 rounded-lg shadow-xl"
          >
            <h2 className="text-4xl font-bold mb-8">
              {t('reservationInfoTitle')}
            </h2>
            <p className="text-gray-300">{t('reservationDescription')}</p>
            <div className="flex items-center space-x-4">
              <FaCalendarAlt className="text-blue-400 text-3xl" />
              <div>
                <h4 className="text-xl font-bold">{t('availableDates')}</h4>
                <p className="text-gray-300">{t('datesInfo')}</p>
              </div>
            </div>

            <div className="flex items-center space-x-4">
              <FaClock className="text-blue-400 text-3xl" />
              <div>
                <h4 className="text-xl font-bold">{t('availableTimes')}</h4>
                <p className="text-gray-300">{t('timesInfo')}</p>
              </div>
            </div>

            <div className="flex items-center space-x-4">
              <FaUser className="text-blue-400 text-3xl" />
              <div>
                <h4 className="text-xl font-bold">{t('guestPolicy')}</h4>
                <p className="text-gray-300">{t('guestInfo')}</p>
              </div>
            </div>
          </motion.div>
        </div>
      </section>
    </>
  );
};

export default Reservation;
