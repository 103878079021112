import React, { useMemo, useCallback, useState } from 'react';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';

// URL de imagen de respaldo en caso de error
const DEFAULT_IMAGE = 'https://via.placeholder.com/600x400?text=Imagen+no+disponible';

/**
 * Convierte contenido HTML a texto plano de manera segura.
 * @param {string} html - Contenido HTML a sanitizar y convertir.
 * @returns {string} Texto plano.
 */
const getPlainText = (html) => {
  const cleanHtml = DOMPurify.sanitize(html);
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = cleanHtml;
  return tempDiv.textContent || tempDiv.innerText || '';
};

/**
 * Componente Article optimizado para rendimiento y accesibilidad.
 */
const Article = React.memo(({ article, onReadMore }) => {
  const [imgSrc, setImgSrc] = useState(article.image_path || DEFAULT_IMAGE);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  /**
   * Maneja el error de carga de la imagen, estableciendo una imagen por defecto.
   */
  const handleImageError = () => {
    setImgSrc(DEFAULT_IMAGE);
  };

  /**
   * Función de callback para manejar el evento "Leer más".
   */
  const handleReadMoreClick = useCallback(() => {
    onReadMore(article.id);
  }, [onReadMore, article.id]);

  /**
   * Memoriza el contenido de texto plano para evitar recalculaciones.
   */
  const plainTextContent = useMemo(() => getPlainText(article.content), [article.content]);

  /**
   * Memoriza el contenido de vista previa.
   */
  const previewContent = useMemo(() => {
    return plainTextContent.length > 200
      ? `${plainTextContent.substring(0, 200)}...`
      : plainTextContent;
  }, [plainTextContent]);

  return (
    <article
      className="bg-[var(--bg-color)] dark:bg-gray-800 rounded-lg p-6 sm:p-8 shadow-lg hover:shadow-2xl transition-shadow duration-300 transform hover:-translate-y-1 hover:bg-opacity-95"
      data-aos="fade-up"
      aria-labelledby={`article-title-${article.id}`}
    >
      {/* Imagen con placeholder y carga diferida */}
      <div className="relative w-full h-48 mb-4 overflow-hidden rounded-lg">
        <img
          src={imgSrc}
          alt={article.title}
          loading="lazy"
          onError={handleImageError}
          onLoad={() => setIsImageLoaded(true)}
          className={`w-full h-full object-cover transition-opacity duration-500 ease-in-out ${
            isImageLoaded ? 'opacity-100' : 'opacity-0'
          }`}
        />
        {!isImageLoaded && (
          <div className="absolute inset-0 bg-gray-300 animate-pulse"></div>
        )}
      </div>

      {/* Título del artículo */}
      <h2
        id={`article-title-${article.id}`}
        className="text-2xl sm:text-3xl font-bold mb-2 text-[var(--main-color)] dark:text-indigo-400 hover:text-[var(--hover-color)] transition-colors duration-300 drop-shadow-md"
      >
        {article.title}
      </h2>

      {/* Información del artículo */}
      <div className="flex items-center text-sm text-gray-500 mb-4" aria-label="Información del artículo">
        {article.date && (
          <time dateTime={new Date(article.date).toISOString()} className="mr-4">
            {new Date(article.date).toLocaleDateString()}
          </time>
        )}
        {article.author_name && (
          <p>
            Por <span className="font-medium">{article.author_name}</span>
          </p>
        )}
      </div>

      {/* Vista previa del contenido */}
      <p className="text-base sm:text-lg text-[var(--text-color)] dark:text-gray-300 mb-6 leading-relaxed">
        {previewContent}
      </p>

      {/* Botón "Leer más..." */}
      <button
        onClick={handleReadMoreClick}
        className="bg-gradient-to-r from-[var(--main-color)] to-[var(--hover-color)] dark:from-indigo-500 dark:to-purple-600 text-white py-2 sm:py-3 px-6 rounded-full font-semibold hover:scale-105 transform transition-all duration-200 hover:from-[var(--secondary-color)] hover:to-[var(--hover-color)] shadow-lg focus:outline-none focus:ring-2 focus:ring-[var(--main-color)] dark:focus:ring-purple-600"
        aria-label={`Leer más sobre ${article.title}`}
      >
        Leer más...
      </button>

      {/* Comentarios o interacción futura */}
      <div className="mt-6">
        <p className="text-sm text-gray-400 italic">
          * Próximamente: Sección de comentarios y discusiones.
        </p>
      </div>
    </article>
  );
});

// Validación de las props con PropTypes
Article.propTypes = {
  article: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    image_path: PropTypes.string,
    date: PropTypes.string,
    author_name: PropTypes.string,
  }).isRequired,
  onReadMore: PropTypes.func.isRequired,
};

export default Article;
