// src/components/Contact.jsx
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Contact = () => {
  const { t } = useTranslation();
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [messageSent, setMessageSent] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí puedes implementar el envío del formulario a tu backend o servicio de correo electrónico.
    // Por ahora, simplemente mostramos un mensaje de éxito.
    setMessageSent(true);
    // Reiniciar el formulario después de un breve retraso para que el usuario vea que se ha enviado
    setTimeout(() => {
      setFormData({
        name: '',
        email: '',
        message: '',
      });
      setMessageSent(false);
    }, 3000);
  };

  return (
    <section id="contact" className="py-20 bg-gray-100 dark:bg-gray-900">
      <div className="container mx-auto px-6 md:px-12">
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1 }}
        >
          <h2 className="text-4xl font-bold text-center text-gold mb-6 font-sans">
            {t('contactTitle')}
          </h2>
          <p className="text-gray-700 dark:text-gray-200 text-center mb-12 font-medium">
            {t('contactDescription')}
          </p>
          <div className="flex flex-col md:flex-row md:space-x-12">
            <div className="md:w-1/2">
              <form onSubmit={handleSubmit} className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-md">
                <div className="mb-4">
                  <label className="block text-gray-700 dark:text-gray-200 font-medium mb-2">
                    {t('contactFormName')}
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-gold bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 dark:text-gray-200 font-medium mb-2">
                    {t('contactFormEmail')}
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-gold bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200"
                  />
                </div>
                <div className="mb-6">
                  <label className="block text-gray-700 dark:text-gray-200 font-medium mb-2">
                    {t('contactFormMessage')}
                  </label>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    rows="5"
                    className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-gold bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200"
                  ></textarea>
                </div>
                {messageSent && (
                  <p className="text-green-600 dark:text-green-400 font-medium mb-4">
                    {t('contactThanks')}
                  </p>
                )}
                <button
                  type="submit"
                  className="w-full px-6 py-3 bg-gold hover:bg-gold-dark text-white font-semibold rounded-full transition-colors"
                >
                  {t('sendMessage')}
                </button>
              </form>
            </div>
            <div className="md:w-1/2 mt-8 md:mt-0">
              <h3 className="text-2xl font-bold text-gold mb-4 font-sans">
                {t('contactInfoTitle')}
              </h3>
              <p className="text-gray-700 dark:text-gray-200 font-medium mb-4">
                {t('contactInfoDescription')}
              </p>
              <div className="mb-4">
                <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                  {t('ourAddress')}
                </h4>
                <p className="text-gray-700 dark:text-gray-200">123 Culinary Street, Foodie Town</p>
              </div>
              <div className="mb-4">
                <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                  {t('callUs')}
                </h4>
                <p className="text-gray-700 dark:text-gray-200">(123) 456-7890</p>
              </div>
              <div>
                <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                  {t('emailUs')}
                </h4>
                <p className="text-gray-700 dark:text-gray-200">info@gourmethaven.com</p>
              </div>
            </div>
          </div>
          {/* Vista del Mapa */}
          <div className="mt-12">
            <h3 className="text-2xl font-bold text-center text-gold mb-4 font-sans">
              {t('mapTitle')}
            </h3>
            <p className="text-gray-700 dark:text-gray-200 text-center mb-6 font-medium">
              {t('mapDescription')}
            </p>
            <div className="flex justify-center">
              <div className="w-full h-64 md:h-80 rounded-lg overflow-hidden shadow-lg">
                <iframe
                  title="Gourmet Haven Location"
                  src="https://www.google.com/maps/embed?pb=..." // Reemplaza "..." con tu URL de mapa de Google
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                ></iframe>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Contact;
