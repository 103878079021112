// src/components/About.jsx
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const About = () => {
  const { t } = useTranslation();
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  return (
    <section id="about" className="py-20 bg-white dark:bg-gray-900">
      <div className="container mx-auto px-6 md:px-12">
        <motion.div
          ref={ref}
          className="md:flex md:items-center"
          initial={{ opacity: 0, x: -50 }}
          animate={inView ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 1 }}
        >
          <div className="md:w-1/2">
            <h2 className="text-4xl font-bold text-gold mb-6 font-sans">{t('aboutTitle')}</h2>
            <p className="text-gray-700 dark:text-gray-200 leading-relaxed mb-4 font-medium">
              {t('aboutParagraph1', { brand: t('title') })}
            </p>
            <p className="text-gray-700 dark:text-gray-200 leading-relaxed mb-6 font-medium">
              {t('aboutParagraph2')}
            </p>
            <a
              href="#contact"
              className="inline-block px-6 py-3 bg-gold hover:bg-gold-dark text-white font-semibold rounded-full transition-colors"
            >
              {t('reserve')}
            </a>
          </div>
          <div className="md:w-1/2 mt-10 md:mt-0 md:pl-10">
            <div className="relative">
              <img src="/images/chef.jpg" alt={t('aboutTitle')} className="rounded-lg shadow-lg" />
              <motion.div
                className="absolute inset-0 bg-gold opacity-0 rounded-lg"
                whileHover={{ opacity: 0.2 }}
                transition={{ duration: 0.3 }}
              ></motion.div>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default About;
