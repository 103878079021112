import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

function About() {
  useEffect(() => {
    AOS.init({
      duration: 600,
      easing: 'ease-out-cubic',
      once: true,
      offset: 50,
      throttleDelay: 99,
    });
  }, []);

  return (
    <section
      id="about"
      className="relative py-0 md:py-16 bg-[var(--bg-color)] text-[var(--text-color)] overflow-hidden"
      style={{
        marginTop: '0', // Evitar margen entre secciones
      }}
    >
      <div className="container mx-auto px-6 md:px-12">
        <div className="text-center mb-12">
          <h2
            className="text-3xl font-bold text-[var(--text-color)]"
            data-aos="fade-down"
          >
            About Me
          </h2>
          <p
            className="mt-4 text-[var(--text-color)]"
            data-aos="fade-up"
          >
            Introduction
          </p>
        </div>
        <div className="flex flex-col md:flex-row items-center overflow-hidden">
          <div className="w-full md:w-1/2 mb-8 md:mb-0" data-aos="zoom-in">
            <img
              src="/static/coding.jpg"
              alt="Programming Illustration"
              className="w-full h-auto max-w-full rounded-lg shadow-lg"
            />
          </div>
          <div className="w-full md:w-1/2 md:pl-12">
            <p
              className="text-base leading-relaxed mb-6 text-[var(--text-color)]"
              data-aos="fade-right"
            >
              I am a passionate web developer and cybersecurity analyst...
            </p>
            <p
              className="text-base leading-relaxed mb-6 text-[var(--text-color)]"
              data-aos="fade-right"
            >
              I offer custom web development services, security audits...
            </p>
            <div className="flex items-center mb-4" data-aos="fade-left">
              <i className="bx bxs-user text-2xl text-[var(--text-color)]"></i>
              <a
                href="https://twitter.com/Tempestgf"
                className="ml-4 text-[var(--text-color)] hover:text-[var(--hover-color)]"
              >
                @Tempestgf
              </a>
            </div>
            <div className="flex items-center mb-4" data-aos="fade-left">
              <i className="bx bxs-envelope text-2xl text-[var(--text-color)]"></i>
              <a
                href="mailto:tempestgf@protonmail.com"
                className="ml-4 text-[var(--text-color)] hover:text-[var(--hover-color)]"
              >
                tempestgf@protonmail.com
              </a>
            </div>
            <div className="flex items-center mb-8" data-aos="fade-left">
              <i className="bx bxs-briefcase text-2xl text-[var(--text-color)]"></i>
              <span className="ml-4 text-[var(--text-color)]">
                Available for freelance projects
              </span>
            </div>
            <div className="flex flex-col md:flex-row" data-aos="fade-up">
              <a
                href="/static/CV.pdf"
                className="bg-[var(--main-color)] text-white py-3 px-6 rounded-lg shadow-lg mb-4 md:mb-0 md:mr-4 hover:bg-[var(--hover-color)]"
              >
                Download CV
              </a>
              <a
                href="#contact"
                className="bg-[var(--secondary-color)] text-white py-3 px-6 rounded-lg shadow-lg hover:bg-[var(--hover-color)]"
              >
                Contact Me
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
