import React from 'react';
import '../../../i18n';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Menu from './components/Menu';
import Gallery from './components/Gallery';
import Testimonials from './components/Testimonials';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Reservation from './components/Reservation';
import './Restaurant.css'
function Restaurant2() {
  return (
    <div className="restaurant-app2 overflow-x-hidden">
      <Header />
      <main>
        <Hero />
        <About />
        <Menu />
        <Reservation />
        <Gallery />
        <Testimonials />
        <Contact />
      </main>
      <Footer />
    </div>
  );
}

export default Restaurant2;
