import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';

// URL de imagen de respaldo en caso de error
const DEFAULT_IMAGE = 'https://via.placeholder.com/600x400?text=Imagen+no+disponible';
// Número máximo de reintentos para fetch
const MAX_RETRIES = 3;
// Intervalo entre reintentos en milisegundos
const RETRY_INTERVAL = 1000;

/**
 * Hook personalizado para la carga diferida de imágenes usando Intersection Observer.
 * @param {string} src - Fuente de la imagen.
 * @returns {object} Objeto con referencias y estado de carga.
 */
const useLazyLoadImage = (src) => {
  const [isInView, setIsInView] = useState(false);
  const imgRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsInView(true);
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, []);

  return { imgRef, isInView };
};

/**
 * Convierte contenido HTML a texto plano de manera segura.
 * @param {string} html - Contenido HTML a sanitizar y convertir.
 * @returns {string} Texto plano.
 */
const getPlainText = (html) => {
  const cleanHtml = DOMPurify.sanitize(html);
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = cleanHtml;
  return tempDiv.textContent || tempDiv.innerText || '';
};

/**
 * Componente ArticleDetail optimizado para rendimiento, accesibilidad y estética.
 */
const ArticleDetail = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [relatedArticles, setRelatedArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const abortControllerRef = useRef(null);

  /**
   * Función para realizar fetch con reintentos.
   * @param {string} url - URL de la API.
   * @param {number} retries - Número de reintentos restantes.
   * @param {number} delay - Retardo entre reintentos.
   * @returns {Promise<object>} Datos de la API.
   */
  const fetchWithRetry = useCallback(async (url, retries = MAX_RETRIES, delay = RETRY_INTERVAL) => {
    try {
      const response = await fetch(url, { signal: abortControllerRef.current.signal });
      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (err) {
      if (err.name === 'AbortError') throw err;
      if (retries > 0) {
        console.warn(`Reintentando fetch (${MAX_RETRIES - retries + 1}/${MAX_RETRIES})...`);
        await new Promise(res => setTimeout(res, delay));
        return fetchWithRetry(url, retries - 1, delay);
      } else {
        throw err;
      }
    }
  }, []);

  /**
   * Fetch del artículo y artículos relacionados.
   */
  const fetchArticleData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    abortControllerRef.current = new AbortController();

    try {
      // Fetch del artículo principal
      const articleData = await fetchWithRetry(`https://tempestgf.zapto.org/api/articles/${id}`);
      setArticle(articleData);

      // Determinar la categoría principal
      const category = articleData.categories ? articleData.categories.split(',')[0].trim() : 'General';

      // Fetch de artículos relacionados
      const relatedData = await fetchWithRetry(`https://tempestgf.zapto.org/api/articles?category=${encodeURIComponent(category)}`);
      const filteredRelated = relatedData.filter(item => item.id !== articleData.id).slice(0, 3);
      setRelatedArticles(filteredRelated);
    } catch (err) {
      console.error('Error fetching article:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [id, fetchWithRetry]);

  /**
   * Inicialización de AOS y fetch de datos al montar el componente.
   */
  useEffect(() => {
    AOS.init({ duration: 1000, easing: 'ease-in-out', once: true });
    fetchArticleData();

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [fetchArticleData]);

  /**
   * Memoriza los datos estructurados para SEO.
   */
  const structuredData = useMemo(() => {
    if (!article) return null;
    return {
      "@context": "https://schema.org",
      "@type": "Article",
      "headline": article.title,
      "image": article.image_path || DEFAULT_IMAGE,
      "author": {
        "@type": "Person",
        "name": article.author_name || "Autor Desconocido"
      },
      "datePublished": article.publish_date,
      "description": article.summary || 'Descripción no disponible',
      "publisher": {
        "@type": "Organization",
        "name": "Nombre de tu Blog",
        "logo": {
          "@type": "ImageObject",
          "url": "URL de tu logo"
        }
      },
    };
  }, [article]);

  /**
   * Condiciones de renderizado: Loading, Error, y Contenido.
   */
  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-[var(--bg-color)] text-[var(--text-color)]">
        <ClipLoader color="var(--main-color)" loading={isLoading} size={50} />
        <span className="mt-4 text-lg font-medium">Cargando artículo...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-red-100 text-red-700">
        <div className="flex items-center bg-red-200 p-6 rounded-lg shadow-md">
          <svg
            className="w-6 h-6 mr-3"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          <span>Error al cargar el artículo: {error}</span>
        </div>
      </div>
    );
  }

  if (!article) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-[var(--bg-color)] text-[var(--text-color)]">
        <p className="text-lg font-medium">Artículo no encontrado.</p>
      </div>
    );
  }

  return (
    <div className="font-source-code-pro bg-[var(--bg-color)] text-[var(--text-color)] min-h-screen py-12 sm:py-16 px-4 sm:px-6 lg:px-8">
      <Helmet>
        <title>{article.meta_description || article.title}</title>
        <meta name="description" content={article.meta_description || article.summary || 'Descripción no disponible'} />
        <meta name="keywords" content={article.tags || 'artículo, blog'} />
        {structuredData && (
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        )}
      </Helmet>

      <div className="max-w-4xl mx-auto bg-[var(--bg-color)] rounded-3xl p-8 sm:p-12 lg:p-16 shadow-2xl transition-shadow hover:shadow-3xl" data-aos="fade-up">
        {/* Breadcrumbs de Navegación */}
        <nav className="mb-6" aria-label="Breadcrumb">
          <ol className="flex flex-wrap space-x-2 text-sm">
            <li>
              <Link to="/" className="text-[var(--main-color)] hover:underline">
                Inicio
              </Link>
            </li>
            <li>
              <span className="text-gray-500">/</span>
            </li>
            <li aria-current="page" className="text-gray-700">
              {article.title || 'Artículo'}
            </li>
          </ol>
        </nav>

        {/* Imagen destacada con optimización */}
        {article.image_path && (
          <div className="relative w-full h-64 sm:h-80 mb-6 overflow-hidden rounded-lg">
            <img
              src={article.image_path}
              alt={`Imagen destacada de ${article.title}`}
              className="w-full h-full object-cover transition-opacity duration-700 ease-in-out"
              loading="lazy"
              onError={(e) => { e.target.src = DEFAULT_IMAGE; }}
              data-aos="zoom-in"
            />
            <div className="absolute inset-0 bg-gray-300 dark:bg-gray-700 animate-pulse opacity-50"></div>
          </div>
        )}

        {/* Título del artículo */}
        <h1 className="text-4xl lg:text-5xl font-extrabold text-center mb-4" style={{ color: 'var(--main-color)' }}>
          {article.title || 'Título no disponible'}
        </h1>

        {/* Descripción del artículo */}
        {article.description && (
          <p className="text-lg font-semibold text-center mb-6 text-[var(--text-color)]">
            {article.description}
          </p>
        )}

        {/* Detalles del artículo */}
        <div className="flex flex-col sm:flex-row justify-between items-center text-sm lg:text-base text-gray-600 dark:text-gray-300 mb-8">
          <p>
            Publicado por <span className="font-bold">{article.author_name || 'Autor Desconocido'}</span> el{' '}
            <time dateTime={new Date(article.publish_date).toISOString()}>
              {new Date(article.publish_date).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}
            </time>
          </p>
          <p>{article.reading_time || 'Tiempo de lectura no disponible'} minutos de lectura</p>
        </div>

        {/* Resumen del artículo */}
        {article.summary && (
          <div className="mb-8">
            <h2 className="text-2xl font-semibold mb-2" style={{ color: 'var(--main-color)' }}>Resumen</h2>
            <p className="text-gray-700 dark:text-gray-300">{article.summary}</p>
          </div>
        )}

        {/* Categorías del artículo */}
        {article.categories && (
          <div className="mb-8">
            <h2 className="text-2xl font-semibold mb-2" style={{ color: 'var(--main-color)' }}>Categorías</h2>
            <ul className="flex flex-wrap gap-2">
              {article.categories.split(',').map((cat, index) => (
                <li key={index} className="bg-[var(--secondary-color)] text-white px-3 py-1 rounded-full text-sm">
                  {cat.trim()}
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Etiquetas del artículo */}
        {article.tags && (
          <div className="mb-8">
            <h2 className="text-2xl font-semibold mb-2" style={{ color: 'var(--main-color)' }}>Etiquetas</h2>
            <ul className="flex flex-wrap gap-2">
              {article.tags.split(',').map((tag, index) => (
                <li key={index} className="bg-[var(--hover-color)] text-white px-3 py-1 rounded-full text-sm">
                  {tag.trim()}
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Contenido del artículo */}
        <article
          className="prose lg:prose-xl text-gray-700 dark:text-gray-300 mb-12 transition-colors"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article.content || '<p>Contenido no disponible</p>') }}
        />

        {/* Sección de artículos relacionados */}
        {relatedArticles.length > 0 && (
          <section className="mt-16">
            <h2 className="text-2xl font-bold mb-6 text-center" style={{ color: 'var(--main-color)' }} data-aos="fade-up">
              Artículos relacionados
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              {relatedArticles.map((relArticle) => (
                <Link
                  to={`/articles/${relArticle.id}`}
                  key={relArticle.id}
                  className="bg-[var(--bg-color)] rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 flex flex-col"
                  data-aos="zoom-in"
                >
                  {relArticle.image_path && (
                    <div className="relative w-full h-40 overflow-hidden rounded-t-lg">
                      <img
                        src={relArticle.image_path}
                        alt={`Imagen de ${relArticle.title}`}
                        className="w-full h-full object-cover transition-opacity duration-700 ease-in-out"
                        loading="lazy"
                        onError={(e) => { e.target.src = DEFAULT_IMAGE; }}
                      />
                      <div className="absolute inset-0 bg-gray-300 dark:bg-gray-700 animate-pulse opacity-50"></div>
                    </div>
                  )}
                  <div className="p-6 flex flex-col flex-grow">
                    <h3 className="text-xl font-semibold mb-2 text-[var(--main-color)]">
                      {relArticle.title || 'Título no disponible'}
                    </h3>
                    <p className="text-gray-700 dark:text-gray-300 flex-grow">
                      {relArticle.summary || 'Un pequeño resumen de este artículo relacionado...'}
                    </p>
                    <span className="mt-4 text-[var(--main-color)] hover:underline font-semibold">
                      Leer más
                    </span>
                  </div>
                </Link>
              ))}
            </div>
          </section>
        )}

        {/* Botón para volver al blog */}
        <div className="mt-16 text-center">
          <Link
            to="/"
            className="inline-flex items-center px-4 py-2 bg-[var(--main-color)] text-white rounded-full font-semibold hover:bg-[var(--hover-color)] transition-colors duration-300 shadow-md hover:shadow-lg"
            aria-label="Volver al blog"
          >
            Volver al Blog
          </Link>
        </div>
      </div>
    </div>
  );
};

/**
 * Validación de las props con PropTypes.
 */
ArticleDetail.propTypes = {
  // No props son directamente pasadas; todos los datos se obtienen según los parámetros de la URL.
};

export default React.memo(ArticleDetail);
