// src/components/Menu.jsx
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';

const Menu = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('starters');

  const categories = {
    starters: {
      title: t('starters'),
      items: [
        {
          name: t('bruschetta'),
          description: t('bruschettaDesc'),
          price: '8€',
          image: '/images/menu/bruschetta.jpg',
        },
        {
          name: t('capreseSalad'),
          description: t('capreseSaladDesc'),
          price: '7€',
          image: '/images/menu/caprese-salad.jpg',
        },
        {
          name: t('garlicPrawns'),
          description: t('garlicPrawnsDesc'),
          price: '9€',
          image: '/images/menu/garlic-prawns.jpg',
        },
        {
          name: t('soup'),
          description: t('soupDesc'),
          price: '6€',
          image: '/images/menu/soup.jpg',
        },
        {
          name: t('stuffedMushrooms'),
          description: t('stuffedMushroomsDesc'),
          price: '7€',
          image: '/images/menu/stuffed-mushrooms.jpg',
        },
      ],
    },
    mainCourses: {
      title: t('mainCourses'),
      items: [
        {
          name: t('beefTenderloin'),
          description: t('beefTenderloinDesc'),
          price: '18€',
          image: '/images/menu/beef-tenderloin.jpg',
        },
        {
          name: t('chickenParmesan'),
          description: t('chickenParmesanDesc'),
          price: '16€',
          image: '/images/menu/chicken-parmesan.jpeg',
        },
        {
          name: t('grilledSalmon'),
          description: t('grilledSalmonDesc'),
          price: '20€',
          image: '/images/menu/grilled-salmon.jpg',
        },
        {
          name: t('lambChops'),
          description: t('lambChopsDesc'),
          price: '22€',
          image: '/images/menu/lamb-chops.jpeg',
        },
        {
          name: t('pastaPrimavera'),
          description: t('pastaPrimaveraDesc'),
          price: '15€',
          image: '/images/menu/pasta-primavera.webp',
        },
        {
          name: t('risotto'),
          description: t('risottoDesc'),
          price: '17€',
          image: '/images/menu/risotto.webp',
        },
      ],
    },
    desserts: {
      title: t('desserts'),
      items: [
        {
          name: t('cheesecake'),
          description: t('cheesecakeDesc'),
          price: '7€',
          image: '/images/menu/cheesecake.jpg',
        },
        {
          name: t('chocolateLava'),
          description: t('chocolateLavaDesc'),
          price: '8€',
          image: '/images/menu/chocolate-lava.jpg',
        },
        {
          name: t('pannaCotta'),
          description: t('pannaCottaDesc'),
          price: '6€',
          image: '/images/menu/pannacotta.avif',
        },
        {
          name: t('tiramisu'),
          description: t('tiramisuDesc'),
          price: '7€',
          image: '/images/menu/tiramisu.jpg',
        },
      ],
    },
    drinks: {
      title: t('drinks'),
      items: [
        {
          name: t('cappuccino'),
          description: t('cappuccinoDesc'),
          price: '3€',
          image: '/images/menu/cappuccino.jpg',
        },
        {
          name: t('espresso'),
          description: t('espressoDesc'),
          price: '2€',
          image: '/images/menu/espresso.jpg',
        },
        {
          name: t('redWine'),
          description: t('redWineDesc'),
          price: '5€',
          image: '/images/menu/red-wine.webp',
        },
        {
          name: t('sparklingWater'),
          description: t('sparklingWaterDesc'),
          price: '2€',
          image: '/images/menu/sparkling-water.jpg',
        },
        {
          name: t('whiteWine'),
          description: t('whiteWineDesc'),
          price: '5€',
          image: '/images/menu/white-wine.webp',
        },
      ],
    },
  };

  const tabs = Object.keys(categories);

  return (
    <section id="menu" className="py-20 bg-white dark:bg-gray-900">
      <div className="container mx-auto px-6 md:px-12">
        <h2 className="text-4xl font-bold text-center text-gold mb-12 font-sans">
          {t('menuTitle')}
        </h2>
        <div className="flex justify-center mb-8 flex-wrap">
          {tabs.map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`mx-2 my-2 px-4 py-2 rounded-full font-medium transition-colors ${
                activeTab === tab
                  ? 'bg-gold text-white'
                  : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-200 hover:bg-gold hover:text-white'
              }`}
            >
              {categories[tab].title}
            </button>
          ))}
        </div>
        <AnimatePresence mode="wait">
          <motion.div
            key={activeTab}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}
          >
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
              {categories[activeTab]?.items.map((dish, index) => (
                <motion.div
                  key={index}
                  className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden"
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.3 }}
                >
                  <img
                    src={dish.image}
                    alt={dish.name}
                    className="w-full h-56 object-cover"
                  />
                  <div className="p-6">
                    <h4 className="text-2xl font-semibold text-gold font-sans">
                      {dish.name}
                    </h4>
                    <p className="text-gray-700 dark:text-gray-200 mt-2 font-medium">
                      {dish.description}
                    </p>
                    <div className="mt-4 flex items-center justify-between">
                      <span className="text-gold font-bold text-lg">
                        {dish.price}
                      </span>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </section>
  );
};

export default Menu;
