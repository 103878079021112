import React, { useEffect, useState, useCallback, useMemo } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Article from './Article';
import { useNavigate } from 'react-router-dom';
import { FiSearch } from 'react-icons/fi';

// Constantes
const API_URL = 'https://tempestgf.zapto.org/api/articles';
const ARTICLES_PER_PAGE = 5;
const DEBOUNCE_DELAY = 300; // Milisegundos
const MAX_RETRIES = 3;

const Blog = () => {
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();

  // Memorizar la función de navegación
  const handleReadMore = useCallback(
    (id) => {
      navigate(`/articles/${id}`);
    },
    [navigate]
  );

  // Fetch artículos con reintentos y cancelación
  const fetchArticles = useCallback(async (retries = MAX_RETRIES, signal) => {
    try {
      const response = await fetch(API_URL, { signal });
      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }
      const data = await response.json();
      const sortedArticles = data.sort(
        (a, b) => new Date(b.publish_date) - new Date(a.publish_date)
      );
      setArticles(sortedArticles);
      setError(null);
    } catch (err) {
      if (err.name !== 'AbortError') {
        if (retries > 0) {
          console.warn(`Reintentando fetchArticles (${retries} reintentos restantes)...`);
          fetchArticles(retries - 1, signal);
        } else {
          console.error('Error fetching articles:', err);
          setError(err.message);
        }
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Inicialización de AOS y fetch de artículos
  useEffect(() => {
    AOS.init({ duration: 1000, easing: 'ease-in-out', once: true });
    const controller = new AbortController();
    fetchArticles(MAX_RETRIES, controller.signal);
    return () => {
      controller.abort();
    };
  }, [fetchArticles]);

  // Debounce para el término de búsqueda
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
      setCurrentPage(1); // Resetear a la primera página al cambiar el término de búsqueda
    }, DEBOUNCE_DELAY);

    return () => clearTimeout(handler);
  }, [searchTerm]);

  // Filtrar artículos basado en el término de búsqueda debounced
  const filteredArticles = useMemo(() => {
    return articles.filter(article =>
      article.title.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
    );
  }, [articles, debouncedSearchTerm]);

  // Calcular artículos para la página actual
  const currentArticles = useMemo(() => {
    const indexOfLastArticle = currentPage * ARTICLES_PER_PAGE;
    const indexOfFirstArticle = indexOfLastArticle - ARTICLES_PER_PAGE;
    return filteredArticles.slice(indexOfFirstArticle, indexOfLastArticle);
  }, [filteredArticles, currentPage]);

  const totalPages = useMemo(() => Math.ceil(filteredArticles.length / ARTICLES_PER_PAGE), [filteredArticles.length]);

  // Manejar paginación
  const handleNextPage = () => {
    setCurrentPage(prev => Math.min(prev + 1, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage(prev => Math.max(prev - 1, 1));
  };

  return (
    <div className="font-source-code-pro text-[var(--text-color)] bg-gradient-to-r from-[var(--bg-color)] via-[var(--secondary-color)] to-[var(--bg-color)] min-h-screen py-24 sm:py-32">
      <div className="container mx-auto px-4 sm:px-6 lg:px-12">
        {/* Buscador */}
        <div className="relative max-w-lg mx-auto mb-12">
          <div className="relative">
            <input
              type="text"
              placeholder="🔍 Buscar artículos..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              aria-label="Buscar artículos"
              className="w-full py-3 px-6 text-[var(--text-color)] bg-[var(--bg-color)] border border-[var(--main-color)] rounded-full shadow-lg focus:outline-none focus:ring-2 focus:ring-[var(--main-color)] transition-all duration-300"
            />
            <FiSearch className="absolute right-4 top-1/2 transform -translate-y-1/2 text-[var(--main-color)] text-xl animate-pulse" aria-hidden="true" />
          </div>
        </div>

        <main className="py-12 sm:py-16 px-4 sm:px-8 lg:px-10 bg-[var(--bg-color)] rounded-3xl shadow-3xl transition-shadow duration-500 hover:shadow-4xl">
          <div
            className="max-w-4xl mx-auto bg-[var(--bg-color)] rounded-3xl p-8 sm:p-12 lg:p-16 shadow-2xl"
            data-aos="fade-up"
          >
            <h1 className="text-center text-5xl sm:text-6xl lg:text-7xl font-extrabold mb-10 sm:mb-12 lg:mb-16 text-[var(--main-color)] tracking-tight drop-shadow-lg">
              Blog
            </h1>

            {isLoading ? (
              <div role="status" aria-live="polite" className="space-y-6">
                {[...Array(ARTICLES_PER_PAGE)].map((_, index) => (
                  <div key={index} className="animate-pulse flex space-x-4">
                    <div className="rounded-full bg-gray-300 h-12 w-12"></div>
                    <div className="flex-1 space-y-4">
                      <div className="h-4 bg-gray-300 rounded w-3/4"></div>
                      <div className="space-y-2">
                        <div className="h-4 bg-gray-300 rounded"></div>
                        <div className="h-4 bg-gray-300 rounded w-5/6"></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : error ? (
              <div role="alert" className="text-center text-lg font-medium text-red-500">
                <p>Error al cargar artículos:</p>
                <p>{error}</p>
              </div>
            ) : filteredArticles.length > 0 ? (
              <div
                className="space-y-10 sm:space-y-12 lg:space-y-14"
                data-aos="fade-up"
              >
                {currentArticles.map((article) => (
                  <MemoizedArticle
                    key={article.id}
                    article={article}
                    onReadMore={handleReadMore}
                  />
                ))}
              </div>
            ) : (
              <div className="text-center text-lg font-medium text-[var(--text-color)]">
                <p>No se encontraron artículos.</p>
              </div>
            )}

            {/* Paginación */}
            {filteredArticles.length > ARTICLES_PER_PAGE && (
              <nav
                className="flex justify-between items-center mt-12"
                aria-label="Paginación"
              >
                <button
                  onClick={handlePreviousPage}
                  className={`py-3 px-6 rounded-full focus:outline-none focus:ring-2 focus:ring-[var(--main-color)] transition-all duration-300 ${
                    currentPage === 1
                      ? 'opacity-50 cursor-not-allowed bg-[var(--secondary-color)]'
                      : 'bg-[var(--main-color)] hover:bg-[var(--main-color)-dark]'
                  }`}
                  disabled={currentPage === 1}
                  aria-label="Página anterior"
                >
                  Anterior
                </button>
                <p className="text-[var(--text-color)] font-medium">
                  Página {currentPage} de {totalPages}
                </p>
                <button
                  onClick={handleNextPage}
                  className={`py-3 px-6 rounded-full focus:outline-none focus:ring-2 focus:ring-[var(--main-color)] transition-all duration-300 ${
                    currentPage === totalPages
                      ? 'opacity-50 cursor-not-allowed bg-[var(--secondary-color)]'
                      : 'bg-[var(--main-color)] hover:bg-[var(--main-color)-dark]'
                  }`}
                  disabled={currentPage === totalPages}
                  aria-label="Página siguiente"
                >
                  Siguiente
                </button>
              </nav>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

// Component Memoizado para evitar renders innecesarios
const MemoizedArticle = React.memo(({ article, onReadMore }) => {
  return (
    <Article
      article={article}
      onReadMore={onReadMore}
    />
  );
});

export default Blog;
