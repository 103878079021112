import React, { useState, useEffect } from 'react';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import ReactMarkdown from 'react-markdown';
import DOMPurify from 'dompurify';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Inicializar el parser de Markdown
const mdParser = new MarkdownIt(/* Opciones */);

const PublishArticle = () => {
  const { id } = useParams(); // Para edición de artículos
  const navigate = useNavigate();

  // Estado único para manejar los datos del formulario
  const [formData, setFormData] = useState({
    title: '',
    slug: '',
    summary: '',
    content: '',
    metaTitle: '',
    metaDescription: '',
    focusKeyword: '',
    categories: [],
    tags: '',
    image: null,
    publishDate: new Date(),
    readingTime: 0,
    status: 'draft'
  });

  const [allCategories, setAllCategories] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [preview, setPreview] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  // Verificar si el usuario es administrador al cargar el componente
  useEffect(() => {
    const checkAdminStatus = async () => {
      try {
        const response = await fetch('https://tempestgf.zapto.org/api/checkAdmin', {
          method: 'GET',
          credentials: 'include',
        });
        const data = await response.json();
        if (data.isAdmin) {
          setIsAdmin(true);
          // Cargar categorías y etiquetas solo si es administrador
          fetchAllCategoriesAndTags();
          // Si hay un ID, cargar el artículo para editar
          if (id) loadArticleForEditing(id);
        } else {
          toast.error('No tienes permisos para acceder a esta página.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate('/');
        }
      } catch (error) {
        console.error('Error al verificar permisos:', error);
        toast.error('Error al verificar permisos.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate('/');
      } finally {
        setLoading(false);
      }
    };
    checkAdminStatus();
  }, [id, navigate]);

  // Obtener todas las categorías y etiquetas existentes
  const fetchAllCategoriesAndTags = () => {
    fetch('https://tempestgf.zapto.org/api/categories')
      .then((res) => res.json())
      .then((data) => setAllCategories(data))
      .catch((error) => console.error('Error fetching categories:', error));

    fetch('https://tempestgf.zapto.org/api/tags')
      .then((res) => res.json())
      .then((data) => setAllTags(data))
      .catch((error) => console.error('Error fetching tags:', error));
  };

  // Cargar el artículo para edición
  const loadArticleForEditing = (articleId) => {
    fetch(`https://tempestgf.zapto.org/api/articles/${articleId}`)
      .then((res) => res.json())
      .then((data) => {
        setFormData({
          title: data.title,
          slug: data.slug,
          summary: data.summary,
          content: data.content, // Asumiendo que el contenido está en Markdown
          metaTitle: data.meta_title,
          metaDescription: data.meta_description,
          focusKeyword: data.focus_keyword,
          categories: data.categories,
          tags: data.tags.join(', '),
          image: data.image_path,
          publishDate: new Date(data.publish_date),
          readingTime: data.reading_time,
          status: data.status,
        });
      })
      .catch((error) => console.error('Error loading article:', error));
  };

  // Manejar cambios en los campos de entrada
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Actualizar el slug automáticamente cuando se cambia el título
    if (name === 'title' && !formData.slug) {
      setFormData((prevData) => ({
        ...prevData,
        slug: value.toLowerCase().replace(/ /g, '-'),
      }));
    }
  };

  // Manejar la subida de imágenes
  const handleImageUpload = async (files) => {
    const file = files[0];
    const uploadData = new FormData();
    uploadData.append('image', file);

    try {
      const response = await fetch('https://tempestgf.zapto.org/api/upload-image', {
        method: 'POST',
        body: uploadData,
        credentials: 'include',
      });
      const data = await response.json();
      setFormData((prevData) => ({
        ...prevData,
        image: data.imagePath,
      }));
      toast.success('Imagen subida exitosamente.', {
        position: "top-right",
        autoClose: 3000,
      });
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Error al subir la imagen.', {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  // Calcular el tiempo de lectura
  const calculateReadingTime = (text) => {
    const wordsPerMinute = 200;
    const cleanText = DOMPurify.sanitize(text, { USE_PROFILES: { html: false } });
    const textLength = cleanText.split(/\s+/).length;
    return Math.ceil(textLength / wordsPerMinute);
  };

  // Manejar cambios en el contenido del editor Markdown
  const handleContentChange = ({ text }) => {
    setFormData((prevData) => ({
      ...prevData,
      content: text,
      readingTime: calculateReadingTime(text),
    }));
  };

  // Validar el formulario
  const validateForm = () => {
    const formErrors = {};
    if (!formData.title.trim()) formErrors.title = 'El título es obligatorio.';
    if (!formData.content.trim()) formErrors.content = 'El contenido no puede estar vacío.';
    if (!formData.summary.trim()) formErrors.summary = 'El resumen es obligatorio.';
    if (!formData.categories.length) formErrors.categories = 'Seleccione al menos una categoría.';
    setErrors(formErrors);
    return formErrors;
  };

  // Manejar el envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    setErrors({});
    setNotification('');

    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setIsSubmitting(false);
      toast.error('Por favor, corrige los errores en el formulario.', {
        position: "top-right",
        autoClose: 5000,
      });
      return;
    }

    const article = {
      ...formData,
      slug: formData.slug || formData.title.toLowerCase().replace(/ /g, '-'),
      publishDate: formData.publishDate.toISOString().slice(0, 19).replace('T', ' '),
      tags: formData.tags.split(',').map((tag) => tag.trim()),
    };

    try {
      const method = id ? 'PUT' : 'POST';
      const url = id
        ? `https://tempestgf.zapto.org/api/articles/${id}`
        : 'https://tempestgf.zapto.org/api/articles';

      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(article),
      });

      if (response.ok) {
        const data = await response.json();
        toast.success('Artículo guardado con éxito.', {
          position: "top-right",
          autoClose: 5000,
        });
        navigate(`/articles/${data.id}`);
      } else {
        const errorData = await response.json();
        toast.error(`Error: ${errorData.message}`, {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error('Error saving article:', error);
      toast.error('Hubo un error al guardar el artículo.', {
        position: "top-right",
        autoClose: 5000,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Reiniciar el formulario
  const resetForm = () => {
    setFormData({
      title: '',
      slug: '',
      summary: '',
      content: '',
      metaTitle: '',
      metaDescription: '',
      focusKeyword: '',
      categories: [],
      tags: '',
      image: null,
      publishDate: new Date(),
      readingTime: 0,
      status: 'draft'
    });
    setErrors({});
    toast.info('Formulario reseteado.', {
      position: "top-right",
      autoClose: 3000,
    });
  };

  // Manejar la entrada de etiquetas
  const handleTagInput = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      tags: e.target.value,
    }));
  };

  // Agregar una etiqueta sugerida
  const addSuggestedTag = (tag) => {
    const currentTags = formData.tags.split(',').map((t) => t.trim());
    if (!currentTags.includes(tag)) {
      setFormData((prevData) => ({
        ...prevData,
        tags: [...currentTags, tag].join(', '),
      }));
    }
  };

  // Mostrar estado de carga
  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen text-gray-700 bg-gray-100">
        <p className="text-xl font-medium">Cargando...</p>
      </div>
    );
  }

  // No renderizar nada si el usuario no es administrador
  if (!isAdmin) {
    return null;
  }

  return (
    <div className="font-sans text-gray-800 bg-gray-100 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <ToastContainer />
      <div className="max-w-5xl mx-auto bg-white shadow-md rounded-lg p-8 sm:p-12">
        <h1 className="text-3xl font-bold text-center mb-8">
          {id ? 'Editar Artículo' : 'Publicar Nuevo Artículo'}
        </h1>

        {/* Notificación */}
        {formData.notification && (
          <div className="mb-6 p-4 text-green-800 bg-green-200 rounded-lg" role="alert">
            {formData.notification}
          </div>
        )}

        {/* Barra de navegación del formulario */}
        <div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0">
          {/* Botones de estado */}
          <div className="flex space-x-2">
            <button
              type="button"
              onClick={() => setFormData((prevData) => ({ ...prevData, status: 'draft' }))}
              className={`py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                formData.status === 'draft'
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-200 text-gray-700 hover:bg-blue-500 hover:text-white'
              } transition-colors`}
              aria-pressed={formData.status === 'draft'}
            >
              Borrador
            </button>
            <button
              type="button"
              onClick={() => setFormData((prevData) => ({ ...prevData, status: 'published' }))}
              className={`py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                formData.status === 'published'
                  ? 'bg-green-600 text-white'
                  : 'bg-gray-200 text-gray-700 hover:bg-green-500 hover:text-white'
              } transition-colors`}
              aria-pressed={formData.status === 'published'}
            >
              Publicar
            </button>
          </div>
          {/* Botón de previsualización */}
          <button
            type="button"
            onClick={() => setPreview(!preview)}
            className="bg-purple-600 text-white py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 hover:bg-purple-700 transition-colors"
            aria-pressed={preview}
          >
            {preview ? 'Editar' : 'Previsualizar'}
          </button>
        </div>

        {/* Previsualización en tiempo real */}
        {preview ? (
          <div className="bg-gray-50 p-6 rounded-lg shadow-inner" aria-live="polite">
            <h2 className="text-2xl font-semibold mb-4">{formData.title || 'Título del artículo'}</h2>
            <p className="text-sm text-gray-500 mb-2">
              Por {/* Aquí podrías mostrar el nombre del autor */ 'Autor'} |{' '}
              {new Date(formData.publishDate).toLocaleDateString()}
            </p>
            <ReactMarkdown
              children={formData.content || 'Contenido del artículo...'}
              className="prose max-w-none"
              components={{
                img: ({ node, ...props }) => (
                  <img
                    {...props}
                    alt={props.alt || 'Imagen'}
                    className="rounded-md max-w-full h-auto"
                  />
                ),
              }}
            />
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Título */}
            <div>
              <label htmlFor="title" className="block text-lg font-medium text-gray-700 mb-2">
                Título:
              </label>
              <input
                type="text"
                id="title"
                name="title"
                className={`w-full px-4 py-2 border ${
                  errors.title ? 'border-red-500' : 'border-gray-300'
                } rounded-md bg-gray-50 focus:ring-blue-500 focus:border-blue-500 text-gray-900`}
                value={formData.title}
                onChange={handleInputChange}
                required
                aria-invalid={errors.title ? 'true' : 'false'}
                aria-describedby={errors.title ? 'title-error' : undefined}
              />
              {errors.title && (
                <p className="mt-1 text-sm text-red-600" id="title-error">
                  {errors.title}
                </p>
              )}
            </div>

            {/* Slug */}
            <div>
              <label htmlFor="slug" className="block text-lg font-medium text-gray-700 mb-2">
                Slug (URL):
              </label>
              <input
                type="text"
                id="slug"
                name="slug"
                className="w-full px-4 py-2 border border-gray-300 rounded-md bg-gray-50 focus:ring-blue-500 focus:border-blue-500 text-gray-900"
                value={formData.slug}
                onChange={handleInputChange}
                placeholder="ejemplo-mi-articulo"
              />
            </div>

            {/* Resumen */}
            <div>
              <label htmlFor="summary" className="block text-lg font-medium text-gray-700 mb-2">
                Resumen:
              </label>
              <textarea
                id="summary"
                name="summary"
                rows="3"
                className={`w-full px-4 py-2 border ${
                  errors.summary ? 'border-red-500' : 'border-gray-300'
                } rounded-md bg-gray-50 focus:ring-blue-500 focus:border-blue-500 text-gray-900`}
                value={formData.summary}
                onChange={handleInputChange}
                placeholder="Escribe un breve resumen del artículo..."
                aria-invalid={errors.summary ? 'true' : 'false'}
                aria-describedby={errors.summary ? 'summary-error' : undefined}
              ></textarea>
              {errors.summary && (
                <p className="mt-1 text-sm text-red-600" id="summary-error">
                  {errors.summary}
                </p>
              )}
            </div>

            {/* Contenido */}
            <div>
              <label htmlFor="content" className="block text-lg font-medium text-gray-700 mb-2">
                Contenido (Markdown):
              </label>
              <MdEditor
                value={formData.content}
                style={{ height: '400px' }}
                renderHTML={(text) => mdParser.render(text)}
                onChange={handleContentChange}
                className="rounded-md"
                aria-label="Editor de contenido en Markdown"
              />
              {errors.content && (
                <p className="mt-1 text-sm text-red-600" id="content-error">
                  {errors.content}
                </p>
              )}
              <p className="mt-2 text-sm text-gray-600">
                Tiempo estimado de lectura: {formData.readingTime} minutos
              </p>
            </div>

            {/* Meta Título */}
            <div>
              <label htmlFor="metaTitle" className="block text-lg font-medium text-gray-700 mb-2">
                Meta Título (SEO):
              </label>
              <input
                type="text"
                id="metaTitle"
                name="metaTitle"
                className="w-full px-4 py-2 border border-gray-300 rounded-md bg-gray-50 focus:ring-blue-500 focus:border-blue-500 text-gray-900"
                value={formData.metaTitle}
                onChange={handleInputChange}
              />
            </div>

            {/* Meta Descripción */}
            <div>
              <label htmlFor="metaDescription" className="block text-lg font-medium text-gray-700 mb-2">
                Meta Descripción (SEO):
              </label>
              <textarea
                id="metaDescription"
                name="metaDescription"
                rows="3"
                className="w-full px-4 py-2 border border-gray-300 rounded-md bg-gray-50 focus:ring-blue-500 focus:border-blue-500 text-gray-900"
                value={formData.metaDescription}
                onChange={handleInputChange}
                placeholder="Escribe una breve descripción para SEO..."
              ></textarea>
            </div>

            {/* Palabra clave de enfoque */}
            <div>
              <label htmlFor="focusKeyword" className="block text-lg font-medium text-gray-700 mb-2">
                Palabra Clave de Enfoque (SEO):
              </label>
              <input
                type="text"
                id="focusKeyword"
                name="focusKeyword"
                className="w-full px-4 py-2 border border-gray-300 rounded-md bg-gray-50 focus:ring-blue-500 focus:border-blue-500 text-gray-900"
                value={formData.focusKeyword}
                onChange={handleInputChange}
                placeholder="Escribe la palabra clave principal del artículo"
              />
            </div>

            {/* Categorías */}
            <div>
              <label htmlFor="categories" className="block text-lg font-medium text-gray-700 mb-2">
                Categorías:
              </label>
              <select
                id="categories"
                name="categories"
                multiple
                className={`w-full px-4 py-2 border ${
                  errors.categories ? 'border-red-500' : 'border-gray-300'
                } rounded-md bg-gray-50 focus:ring-blue-500 focus:border-blue-500 text-gray-900`}
                value={formData.categories}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    categories: [...e.target.selectedOptions].map((option) => option.value),
                  }))
                }
                aria-invalid={errors.categories ? 'true' : 'false'}
                aria-describedby={errors.categories ? 'categories-error' : undefined}
              >
                {allCategories.map((cat) => (
                  <option key={cat.id} value={cat.name}>
                    {cat.name}
                  </option>
                ))}
              </select>
              {errors.categories && (
                <p className="mt-1 text-sm text-red-600" id="categories-error">
                  {errors.categories}
                </p>
              )}
            </div>

            {/* Etiquetas */}
            <div>
              <label htmlFor="tags" className="block text-lg font-medium text-gray-700 mb-2">
                Etiquetas:
              </label>
              <input
                type="text"
                id="tags"
                name="tags"
                className="w-full px-4 py-2 border border-gray-300 rounded-md bg-gray-50 focus:ring-blue-500 focus:border-blue-500 text-gray-900"
                value={formData.tags}
                onChange={handleTagInput}
                placeholder="Separar etiquetas con comas"
                aria-describedby="tags-suggestions"
              />
              {/* Etiquetas sugeridas */}
              <div className="mt-2 flex flex-wrap gap-2" id="tags-suggestions">
                {allTags.map((tag) => (
                  <button
                    key={tag.id}
                    type="button"
                    onClick={() => addSuggestedTag(tag.name)}
                    className="bg-gray-200 text-gray-700 py-1 px-3 rounded-full hover:bg-blue-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors"
                  >
                    {tag.name}
                  </button>
                ))}
              </div>
            </div>

            {/* Imagen Principal */}
            <div>
              <label htmlFor="imageUpload" className="block text-lg font-medium text-gray-700 mb-2">
                Imagen Principal:
              </label>
              <div
                className="w-full h-48 border-dashed border-2 border-gray-300 rounded-md flex items-center justify-center bg-gray-50 hover:border-blue-500 cursor-pointer relative"
                onClick={() => document.getElementById('imageUpload').click()}
                onDrop={(e) => {
                  e.preventDefault();
                  handleImageUpload(e.dataTransfer.files);
                }}
                onDragOver={(e) => e.preventDefault()}
                aria-label="Subir imagen principal"
              >
                {formData.image ? (
                  <img
                    src={formData.image}
                    alt="Imagen subida"
                    className="object-cover w-full h-full rounded-md"
                  />
                ) : (
                  <p className="text-gray-400">Arrastra y suelta una imagen o haz clic para subir</p>
                )}
                <input
                  type="file"
                  id="imageUpload"
                  accept="image/*"
                  className="hidden"
                  onChange={(e) => handleImageUpload(e.target.files)}
                />
              </div>
            </div>

            {/* Fecha de Publicación */}
            <div>
              <label htmlFor="publishDate" className="block text-lg font-medium text-gray-700 mb-2">
                Fecha de Publicación:
              </label>
              <input
                type="datetime-local"
                id="publishDate"
                name="publishDate"
                className="w-full px-4 py-2 border border-gray-300 rounded-md bg-gray-50 focus:ring-blue-500 focus:border-blue-500 text-gray-900"
                value={formData.publishDate.toISOString().slice(0, 16)}
                onChange={(e) => setFormData((prevData) => ({
                  ...prevData,
                  publishDate: new Date(e.target.value)
                }))}
              />
            </div>

            {/* Botones de acción */}
            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={resetForm}
                className="bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 transition-colors"
              >
                Cancelar
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className={`${
                  isSubmitting
                    ? 'bg-blue-300 cursor-not-allowed'
                    : 'bg-blue-600 hover:bg-blue-700'
                } text-white py-2 px-6 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors`}
              >
                {isSubmitting ? 'Procesando...' : 'Guardar'}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default PublishArticle;
