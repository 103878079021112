// components/Contact.js
import React, { useState } from 'react';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const Contact = () => {
  const { t } = useTranslation(); // Initialize translation hook
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { ref: formRef, inView: formInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const { ref: infoRef, inView: infoInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      setIsSubmitted(true);
    } else {
      setErrors(validationErrors);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = t('contactFormName') + ' ' + t('isRequired');
    if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = t('contactFormEmail') + ' ' + t('isRequired');
    if (!formData.message.trim()) newErrors.message = t('contactFormMessage') + ' ' + t('isRequired');
    return newErrors;
  };

  return (
    <section
      id="contact"
      className="bg-gradient-to-br from-gray-800 to-gray-900 py-16 text-white overflow-hidden"
    >
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-16 overflow-hidden">
        <motion.div
          ref={formRef}
          initial={{ opacity: 0, x: -100 }}
          animate={formInView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }}
          transition={{ duration: 1.2 }}
          className="bg-gray-800 p-8 rounded-lg shadow-xl overflow-hidden"
        >
          <h2 className="text-4xl font-bold mb-8 text-center md:text-left text-white">
            {t('contactTitle')}
          </h2>
          {isSubmitted ? (
            <p className="text-green-400 text-xl">{t('contactThanks')}</p>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="flex flex-col">
                <label htmlFor="name" className="text-lg text-white">
                  {t('contactFormName')}
                </label>
                <input
                  type="text"
                  id="name"
                  className="p-3 border border-gray-600 rounded-lg bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-white"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                />
                {errors.name && <span className="text-red-500 text-sm">{errors.name}</span>}
              </div>

              <div className="flex flex-col">
                <label htmlFor="email" className="text-lg text-white">
                  {t('contactFormEmail')}
                </label>
                <input
                  type="email"
                  id="email"
                  className="p-3 border border-gray-600 rounded-lg bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-white"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                />
                {errors.email && <span className="text-red-500 text-sm">{errors.email}</span>}
              </div>

              <div className="flex flex-col">
                <label htmlFor="message" className="text-lg text-white">
                  {t('contactFormMessage')}
                </label>
                <textarea
                  id="message"
                  rows="5"
                  className="p-3 border border-gray-600 rounded-lg bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-white"
                  value={formData.message}
                  onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                />
                {errors.message && <span className="text-red-500 text-sm">{errors.message}</span>}
              </div>

              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition"
                type="submit"
              >
                {t('sendMessage')}
              </motion.button>
            </form>
          )}
        </motion.div>

        <motion.div
          ref={infoRef}
          initial={{ opacity: 0, x: 100 }}
          animate={infoInView ? { opacity: 1, x: 0 } : { opacity: 0, x: 100 }}
          transition={{ duration: 1.2 }}
          className="flex flex-col justify-center items-start space-y-8 bg-gray-800 p-8 rounded-lg shadow-xl overflow-hidden"
        >
          <h2 className="text-4xl font-bold mb-8 text-center md:text-left text-white">
            {t('contactInfoTitle')}
          </h2>
          <div className="flex items-center space-x-4">
            <FaMapMarkerAlt className="text-blue-400 text-3xl" />
            <div>
              <h4 className="text-xl font-bold">{t('ourAddress')}</h4>
              <p className="text-gray-300">{t('footerAddress')}</p>
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <FaPhoneAlt className="text-blue-400 text-3xl" />
            <div>
              <h4 className="text-xl font-bold">{t('callUs')}</h4>
              <p className="text-gray-300">(123) 456-7890</p>
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <FaEnvelope className="text-blue-400 text-3xl" />
            <div>
              <h4 className="text-xl font-bold">{t('emailUs')}</h4>
              <p className="text-gray-300">info@gourmethaven.com</p>
            </div>
          </div>

          <div className="w-full h-64 mt-8">
            <iframe
              className="w-full h-full rounded-lg shadow-lg"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.835434509166!2d144.95373531531744!3d-37.8162797797516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf5779f0e2b2b9d6a!2sRestaurant!5e0!3m2!1sen!2sus!4v1617778433078!5m2!1sen!2sus"
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Contact;
