// src/components/Footer.jsx
import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="bg-white dark:bg-gray-900 text-gray-800 dark:text-gray-200 py-10">
      <div className="container mx-auto px-6 md:px-12">
        <div className="md:flex md:justify-between">
          <div className="md:w-1/3 mb-6 md:mb-0">
            <h3 className="text-xl font-semibold mb-4 text-gold font-sans">{t('footerAboutTitle')}</h3>
            <p className="text-gray-700 dark:text-gray-200 font-medium">
              {t('footerAboutDescription')}
            </p>
          </div>
          <div className="md:w-1/3 mb-6 md:mb-0">
            <h3 className="text-xl font-semibold mb-4 text-gold font-sans">{t('newsletterTitle')}</h3>
            <p className="text-gray-700 dark:text-gray-200 mb-4 font-medium">
              {t('newsletterDescription')}
            </p>
            <form>
              <input
                type="email"
                placeholder={t('contactFormEmail')}
                className="w-full p-3 rounded bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 mb-4 focus:outline-none"
              />
              <button className="w-full py-3 bg-gold hover:bg-gold-dark rounded text-white font-semibold transition-colors">
                {t('subscribe')}
              </button>
            </form>
          </div>
          <div className="md:w-1/3">
            <h3 className="text-xl font-semibold mb-4 text-gold font-sans">{t('followUs')}</h3>
            <p className="text-gray-700 dark:text-gray-200 mb-4 font-medium">
              {t('followUsDescription')}
            </p>
            <div className="flex space-x-4">
              {/* Iconos de redes sociales */}
            </div>
          </div>
        </div>
        <div className="mt-10 text-center text-gray-600 dark:text-gray-400">
          <p>&copy; {new Date().getFullYear()} {t('title')}. {t('all_rights_reserved')}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
