// DarkMode.js
import { useState, useEffect, useCallback } from 'react';
import './DarkMode.css'; // Archivo de estilos para personalizar el botón

export function DarkMode({ onThemeChange }) {
  const [darkMode, setDarkMode] = useState(() => {
    const currentTheme = localStorage.getItem('theme');
    if (currentTheme) {
      return currentTheme === 'dark';
    }
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    localStorage.setItem('theme', prefersDark ? 'dark' : 'light');
    return prefersDark;
  });

  useEffect(() => {
    document.body.classList.toggle('active', darkMode);
    localStorage.setItem('theme', darkMode ? 'dark' : 'light');

    // Notificar al componente padre del cambio de tema
    onThemeChange(darkMode);

    return () => {
      document.body.classList.remove('active');
    };
  }, [darkMode, onThemeChange]);

  const toggleDarkMode = useCallback(() => {
    setDarkMode((prevMode) => !prevMode);
  }, []);

  return (
    <div className="dark-mode-toggle" onClick={toggleDarkMode}>
      {darkMode ? <i className="bx bx-sun"></i> : <i className="bx bx-moon"></i>}
    </div>
  );
}
