// components/Testimonials.js
import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaQuoteLeft } from 'react-icons/fa';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const testimonials = [
  {
    id: 1,
    name: 'John Doe',
    review:
      'An unforgettable dining experience! Every dish was exquisite and the service was top-notch.',
    title: 'CEO at ExampleCorp',
  },
  {
    id: 2,
    name: 'Jane Smith',
    review:
      'The ambiance and food were absolutely incredible. I highly recommend the Gourmet Haven experience.',
    title: 'Food Blogger',
  },
  {
    id: 3,
    name: 'David Wilson',
    review:
      'A true culinary masterpiece. Every bite was full of flavor and the atmosphere was amazing!',
    title: 'Chef at Foodies Unite',
  },
  {
    id: 4,
    name: 'Emily Johnson',
    review:
      'Absolutely stunning presentation and the taste was beyond words. A must-visit for food lovers!',
    title: 'Travel Writer',
  },
  {
    id: 5,
    name: 'Michael Brown',
    review:
      'This place redefines fine dining. Each dish was a perfect blend of flavors. Exceptional!',
    title: 'Restaurant Critic',
  },
  {
    id: 6,
    name: 'Sarah Lee',
    review:
      'From start to finish, the experience was perfect. Incredible food, beautiful setting, and wonderful staff.',
    title: 'Event Planner',
  },
];

const Testimonials = () => {
  const { t } = useTranslation(); // Initialize translation hook
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <section id="testimonials" className="relative py-20">
      <div className="absolute inset-0 overflow-hidden z-0">
        <video
          autoPlay
          muted
          loop
          playsInline
          className="w-full h-full object-cover opacity-40"
        >
          <source src="/videos/guarnition.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className="absolute inset-0 bg-black bg-opacity-60 z-0"></div>

      <div className="relative container mx-auto text-center px-4 z-10">
        <h2 className="text-3xl sm:text-4xl lg:text-5xl font-extrabold text-white mb-4 sm:mb-6">
          {t('testimonialsTitle')}
        </h2>

        <div
          className="md:grid md:grid-cols-3 gap-8 overflow-y-auto md:overflow-visible h-[300px] md:h-auto space-y-6 md:space-y-0"
          ref={ref}
        >
          {testimonials.map((testimonial) => (
            <motion.div
              key={testimonial.id}
              initial={{ opacity: 0, y: 50 }}
              animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
              transition={{ duration: 0.8 }}
              className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 flex-shrink-0 w-full md:w-auto"
            >
              <FaQuoteLeft className="text-blue-500 text-4xl mb-4" />
              <p className="text-base sm:text-lg text-gray-700 italic mb-4">
                "{testimonial.review}"
              </p>
              <h4 className="text-lg sm:text-xl font-bold text-gray-900">
                {testimonial.name}
              </h4>
              <p className="text-gray-500">{testimonial.title}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
