// components/Footer.js
import React from 'react';
import { FaFacebookF, FaInstagram, FaTwitter, FaGithub, FaEnvelope } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Footer = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
    rootMargin: '0px 0px -50px 0px',
  });

  return (
    <div className="overflow-hidden" style={{ background: 'var(--bg-color)', color: 'var(--text-color)' }}>
      <motion.footer
        ref={ref}
        className="py-12 sm:py-16"
        initial={{ opacity: 0, y: 50 }}
        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: 0.8, ease: 'easeInOut' }}
        style={{ minHeight: '300px' }}
      >
        <div className="container mx-auto px-6 sm:px-8 lg:px-16">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {/* Sección Acerca de */}
            <div>
              <h4 className="text-2xl font-bold mb-4" style={{ color: 'var(--main-color)' }}>About Tempest</h4>
              <p className="text-sm mb-4" style={{ fontSize: 'var(--p-font)' }}>
                Currently focused on web applications, databases, and Android development. 
              </p>
              {/*<p className="text-sm mb-2" style={{ fontSize: 'var(--p-font)' }}>1234 Innovation Drive, Tech City</p>*/}
              {/*<p className="text-sm" style={{ fontSize: 'var(--p-font)' }}>Phone: +1 234 567 890</p>*/}
            </div>
            
            {/* Sección de Newsletter */}
            <div>
              <h4 className="text-2xl font-bold mb-4" style={{ color: 'var(--main-color)' }}>Newsletter</h4>
              <p className="text-sm mb-4" style={{ fontSize: 'var(--p-font)' }}>
                Coming soon..
              </p>
              <p className="text-sm mb-4" style={{ fontSize: 'var(--p-font)' }}>
                Stay updated with our latest news and insights. Subscribe to our newsletter and never miss a beat.
              </p>
              <form className="flex flex-col space-y-4">
                <input
                  type="email"
                  className="p-3 rounded-lg bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-main-color transition"
                  placeholder="Enter your email"
                  style={{ backgroundColor: 'var(--bg-color)', color: 'var(--text-color)' }}
                />
                <button
                  type="submit"
                  className="p-3 bg-main-color text-white rounded-lg hover:bg-hover-color transition focus:outline-none focus:ring-2 focus:ring-hover-color"
                  style={{ backgroundColor: 'var(--main-color)', color: 'var(--text-color)' }}
                >
                  Subscribe
                </button>
              </form>
            </div>

            {/* Sección de Contacto */}
            <div>
              <h4 className="text-2xl font-bold mb-4" style={{ color: 'var(--main-color)' }}>Contact Me</h4>
              <p className="text-sm mb-4" style={{ fontSize: 'var(--p-font)' }}>
                Have any questions or feedback? Reach me  anytime, and I'll be happy to help!
              </p>
              <p className="text-sm" style={{ fontSize: 'var(--p-font)' }}>Email: tempestgf@protonmail.com</p>
              {/*<p className="text-sm" style={{ fontSize: 'var(--p-font)' }}>Support: support@tempest.com</p>*/}
            </div>

            {/* Sección de Redes Sociales */}
            <div className="flex flex-col items-center md:items-end">
              <h4 className="text-2xl font-bold mb-4" style={{ color: 'var(--main-color)' }}>Follow Me</h4>
              <p className="text-sm mb-4 text-center md:text-right" style={{ fontSize: 'var(--p-font)' }}>
                Stay connected with me on social media.
              </p>
              <div className="flex space-x-4 text-2xl">
                {/*<a href="https://facebook.com" className="hover:text-hover-color transition" style={{ color: 'var(--text-color)' }}><FaFacebookF /></a>*/}
                {/*<a href="https://instagram.com" className="hover:text-hover-color transition" style={{ color: 'var(--text-color)' }}><FaInstagram /></a>*/}
                <a href="https://twitter.com/Tempestgf" className="hover:text-hover-color transition" style={{ color: 'var(--text-color)' }}><FaTwitter /></a>
                <a href="https://github.com/Tempestgf" className="hover:text-hover-color transition" style={{ color: 'var(--text-color)' }}><FaGithub /></a>
                <a href="mailto:tempestgf@protonmail.com" className="hover:text-hover-color transition" style={{ color: 'var(--text-color)' }}><FaEnvelope /></a>
              </div>
            </div>
          </div>

          <div
            className="mt-12 border-t pt-6 text-center text-sm"
            style={{
              borderColor: 'var(--secondary-color)',
              color: 'var(--text-color)',
            }}
          >
            <p>
              <span style={{ display: 'inline-block', transform: 'scaleX(-1)' }}>
                &copy;
              </span>{' '}
              Copyleft no rights reserved. Tempestgf 2024.
            </p>
          </div>


        </div>
      </motion.footer>
    </div>
  );
};

export default Footer;
