import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-[var(--bg-color)] text-[var(--text-color)] transition-colors duration-300">
      <h1 className="text-6xl font-bold">404</h1>
      <p className="text-xl mt-4 mb-6">Oops! The page you're looking for doesn't exist.</p>
      
      <Link
        to="/"
        className="px-6 py-2 bg-[var(--primary-color)] text-[var(--text-color)] rounded-lg shadow-lg hover:bg-[var(--hover-color)] transition-all duration-300"
      >
        Go Back Home
      </Link>
    </div>
  );
};

export default NotFound;
