// Restaurant.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import About from './components/About';
import MenuPage from './components/MenuPage';
import Gallery from './components/Gallery';
import Testimonials from './components/Testimonials';
import Contact from './components/Contact';
import Footer from './components/Footer';
import MenuIntro from './components/MenuIntro';
import Reservations from './components/Reservations';
import './Restaurant.css'; // Importa el CSS específico del restaurante

function Restaurant() {
  return (
    <div className="restaurant-app"> {/* Clase encapsuladora */}
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Hero />
              <About />
              <MenuIntro />
              <Gallery />
              <Testimonials />
              <Contact />
            </>
          }
        />
        <Route path="menu" element={<MenuPage />} />
        <Route path="reservations" element={<Reservations />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default Restaurant;
