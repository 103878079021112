import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Importar los estilos de AOS

function Contact({ msg }) {
  // Inicializa AOS al montar el componente
  useEffect(() => {
    AOS.init({
      duration: 1200, // Duración de las animaciones en milisegundos
      once: true, // Si true, la animación solo ocurrirá una vez mientras haces scroll
      offset: 80, // Desplazamiento antes de que la animación empiece
    });
  }, []);

  return (
    <section id="contact" className="py-20 bg-[var(--bg-color)] text-[var(--text-color)]">
      <div className="container mx-auto px-8 md:px-16">
        <div className="text-center mb-16">
          <h2
            className="text-3xl md:text-[var(--big-font)] font-bold text-main-color-day dark:text-main-color-night"
            data-aos="fade-down"
          >
            Contact Me
          </h2>
          <p
            className="text-lg mt-4 max-w-xl mx-auto text-opacity-80"
            data-aos="fade-up"
          >
            I'm here to help! Fill out the form below and I will get back to you promptly.
          </p>
        </div>
        <div
          className="bg-[var(--bg-color)] p-10 shadow-xl rounded-2xl"
          data-aos="zoom-in"
        >
          <form className="space-y-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="flex flex-col">
                <label className="text-lg font-medium mb-2">
                  Your Name
                </label>
                <input
                  type="text"
                  placeholder="John Doe"
                  className="p-4 rounded-lg border border-gray-300 focus:border-main-color-day dark:border-gray-700 outline-none transition duration-200 dark:bg-gray-800 dark:text-white focus:shadow-sm"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-lg font-medium mb-2">
                  Your Email
                </label>
                <input
                  type="email"
                  placeholder="example@email.com"
                  className="p-4 rounded-lg border border-gray-300 focus:border-main-color-day dark:border-gray-700 outline-none transition duration-200 dark:bg-gray-800 dark:text-white focus:shadow-sm"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-lg font-medium mb-2">
                Subject
              </label>
              <input
                type="text"
                placeholder="Subject of your message"
                className="p-4 rounded-lg border border-gray-300 focus:border-main-color-day dark:border-gray-700 outline-none transition duration-200 dark:bg-gray-800 dark:text-white focus:shadow-sm"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-lg font-medium mb-2">
                Message
              </label>
              <textarea
                placeholder="Write your message here..."
                className="p-4 rounded-lg border border-gray-300 focus:border-main-color-day dark:border-gray-700 outline-none transition duration-200 resize-none h-48 dark:bg-gray-800 dark:text-white focus:shadow-sm"
              />
            </div>
            <button
              type="submit"
              className="w-full py-4 rounded-full bg-[var(--secondary-color)] text-white font-semibold uppercase transition duration-300 hover:shadow-xl focus:ring-4 focus:ring-offset-2 transform hover:scale-105"
            >
              Send Message
            </button>
            {msg && (
              <div
                className="text-center mt-6 text-red-500"
                data-aos="fade-in"
              >
                {msg}
              </div>
            )}
          </form>
        </div>
      </div>
    </section>
  );
}

export default Contact;
