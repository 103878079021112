// components/Footer.js
import React from 'react';
import { FaFacebookF, FaInstagram, FaTwitter, FaEnvelope } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const Footer = () => {
  const { t } = useTranslation(); // Initialize translation hook
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.05,
    rootMargin: '0px 0px -50px 0px',
  });

  return (
    <div className="bg-gray-900 overflow-hidden">
      <motion.footer
        ref={ref}
        className="bg-gray-900 text-white py-10 sm:py-12"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={inView ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.95 }}
        transition={{ duration: 0.8, ease: 'easeInOut' }}
        style={{ minHeight: '200px' }}
      >
        <div className="container mx-auto px-6 sm:px-8 lg:px-16">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <h4 className="text-xl sm:text-2xl font-bold mb-4">{t('footerAboutTitle')}</h4>
              <p className="text-gray-400 mb-4">{t('footerAboutDescription')}</p>
              <p className="text-gray-400">{t('footerAddress')}</p>
              <p className="text-gray-400">{t('footerPhone')}</p>
            </div>
            <div>
              <h4 className="text-xl sm:text-2xl font-bold mb-4">{t('newsletterTitle')}</h4>
              <p className="text-gray-400 mb-4">{t('newsletterDescription')}</p>
              <form className="flex flex-col space-y-4">
                <input
                  type="email"
                  className="p-3 rounded-lg bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder={t('contactFormEmail')}
                />
                <button
                  type="submit"
                  className="p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  {t('subscribe')}
                </button>
              </form>
            </div>
            <div className="flex flex-col items-center md:items-end">
              <h4 className="text-xl sm:text-2xl font-bold mb-4">{t('followUs')}</h4>
              <p className="text-gray-400 mb-4 text-center md:text-right">{t('followUsDescription')}</p>
              <div className="flex space-x-4 text-xl">
                <a href="https://facebook.com" className="text-gray-400 hover:text-blue-500 transition"><FaFacebookF /></a>
                <a href="https://instagram.com" className="text-gray-400 hover:text-pink-500 transition"><FaInstagram /></a>
                <a href="https://twitter.com" className="text-gray-400 hover:text-blue-400 transition"><FaTwitter /></a>
                <a href="mailto:info@gourmethaven.com" className="text-gray-400 hover:text-red-500 transition"><FaEnvelope /></a>
              </div>
            </div>
          </div>

          <div className="mt-10 border-t border-gray-700 pt-6 text-center text-gray-500">
            <p>&copy; 2024 Gourmet Haven. {t('allRightsReserved')}</p>
          </div>
        </div>
      </motion.footer>
    </div>
  );
};

export default Footer;
