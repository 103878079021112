// Skills.jsx
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'boxicons/css/boxicons.min.css'; // Importa Boxicons

function Skills() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: 'ease-in-out',
    });
  }, []);

  // Lista de habilidades con sus porcentajes y sus iconos
  const skills = [
    { name: 'HTML', percent: 94, icon: 'bx bxl-html5' },
    { name: 'CSS', percent: 81, icon: 'bx bxl-css3' },
    { name: 'JavaScript', percent: 60, icon: 'bx bxl-javascript' },
    { name: 'Python', percent: 62, icon: 'bx bxl-python' },
    { name: 'Linux', percent: 70, icon: 'bx bxl-tux' },
    { name: 'C++', percent: 30, icon: 'bx bxl-c-plus-plus' },
  ];

  // Lista extendida de iconos adicionales que no se añaden al progress bar
  const extraIcons = [
    { name: 'Git', icon: 'bx bxl-git' },
    { name: 'React', icon: 'bx bxl-react' },
    { name: 'Node.js', icon: 'bx bxl-nodejs' },
    { name: 'Docker', icon: 'bx bxl-docker' },
    { name: 'Sass', icon: 'bx bxl-sass' },
    { name: 'Tailwind CSS', icon: 'bx bxl-tailwind-css' },
    { name: 'MongoDB', icon: 'bx bxl-mongodb' },
    { name: 'VS Code', icon: 'bx bxl-visual-studio' },
    { name: 'Bootstrap', icon: 'bx bxl-bootstrap' },
    { name: 'Figma', icon: 'bx bxl-figma' },
    { name: 'Angular', icon: 'bx bxl-angular' },
    { name: 'Vue.js', icon: 'bx bxl-vuejs' },
    { name: 'TypeScript', icon: 'bx bxl-typescript' },
    { name: 'PHP', icon: 'bx bxl-php' },
    { name: 'bash', icon: 'bx bx-code-alt' },
    { name: 'Java', icon: 'bx bxl-java' },
  ];

  return (
    <section
      id="skills"
      className="skills py-16 bg-[var(--bg-color)] text-[var(--text-color)] overflow-x-hidden"
    >
      <div className="container mx-auto px-6 md:px-12 text-center">
        <div className="heading mb-12" data-aos="fade-up">
          <h2 className="text-[var(--big-font)] font-bold">Skills</h2>
          <p className="text-[var(--medium-font)] font-medium">My Skills</p>
        </div>
        <div className="flex flex-col md:flex-row items-center justify-center w-full overflow-hidden">
          {/* Sección de Barras de Habilidades */}
          <div className="w-full md:w-1/2 mb-8 md:mb-0" data-aos="fade-right" data-aos-delay="200">
            {skills.map((skill, index) => (
              <div
                key={skill.name}
                className="relative flex flex-col text-left py-4 mb-6 w-full"
                data-aos="fade-up"
                data-aos-delay={`${200 + index * 100}`}
              >
                <div className="flex justify-between items-center mb-2">
                  <h3 className="text-lg font-semibold flex items-center">
                    <i className={`${skill.icon} text-3xl mr-2`}></i> {skill.name}
                  </h3>
                  <span className="text-lg font-semibold">{skill.percent}%</span>
                </div>
                {/* Barra de fondo */}
                <div className="relative w-full h-4 bg-gray-300 rounded-full overflow-hidden">
                  {/* Barra de progreso */}
                  <div
                    className="progress-bar absolute top-0 left-0 h-full bg-[var(--main-color)] rounded-full"
                    data-skill={skill.name}
                    data-aos="fade-right"
                    data-aos-delay={`${200 + index * 100}`}
                  ></div>
                </div>
              </div>
            ))}
          </div>
          {/* Sección de Iconos Adicionales */}
          <div
            className="w-full md:w-1/2 flex flex-wrap justify-center items-center gap-4 md:gap-8 overflow-hidden"
            data-aos="fade-left"
            data-aos-delay="400"
          >
            {extraIcons.map((icon, index) => (
              <div
                key={`icon-${icon.name}`}
                className="icon-card flex flex-col items-center justify-center w-24 h-24 md:w-32 md:h-32 max-w-full bg-[var(--bg-color)] rounded-lg shadow-lg transition-transform transform hover:scale-110"
                data-aos="zoom-in"
                data-aos-delay={`${400 + index * 100}`}
              >
                <i className={`${icon.icon} text-4xl md:text-6xl`}></i>
                <span className="mt-2 text-sm md:text-lg">{icon.name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Skills;
