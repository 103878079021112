  import React, { useState, useEffect } from 'react';
  import { FaCamera, FaEdit, FaSave } from 'react-icons/fa';
  import { FiLogOut } from 'react-icons/fi';
  import { MdPhone } from 'react-icons/md'; // Nuevo ícono de teléfono
  import { toast, ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import AOS from 'aos';
  import 'aos/dist/aos.css';

  const UserProfile = () => {
    const [user, setUser] = useState(null);
    const [editable, setEditable] = useState(false);
    const [updatedProfile, setUpdatedProfile] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      fetchUserProfile();
      AOS.init({ duration: 1000, easing: 'ease-in-out' });
    }, []);

    const fetchUserProfile = async () => {
      try {
        const response = await fetch('https://tempestgf.zapto.org/api/userProfile', {
          method: 'GET',
          credentials: 'include',
        });
        if (response.ok) {
          const data = await response.json();
          setUser(data);
          setUpdatedProfile({
            full_name: data.full_name || '',
            phone_number: data.phone_number || '',
            bio: data.bio || '',
          });
        } else {
          const errorData = await response.json();
          toast.error(`Error: ${errorData.error}`);
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
        toast.error('Error al obtener el perfil de usuario.');
      } finally {
        setLoading(false);
      }
    };

    const handleUpdateProfile = async () => {
      try {
        const response = await fetch('https://tempestgf.zapto.org/api/userProfile', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify(updatedProfile),
        });
        if (response.ok) {
          fetchUserProfile();
          setEditable(false);
          toast.success('Perfil actualizado exitosamente.');
        } else {
          const errorData = await response.json();
          toast.error(`Error: ${errorData.error}`);
        }
      } catch (error) {
        console.error('Error updating user profile:', error);
        toast.error('Error al actualizar el perfil.');
      }
    };

    const handleUploadProfilePicture = async (file) => {
      const formData = new FormData();
      formData.append('profile_picture', file);

      try {
        const response = await fetch('https://tempestgf.zapto.org/api/uploadProfilePicture', {
          method: 'POST',
          credentials: 'include',
          body: formData,
        });
        if (response.ok) {
          fetchUserProfile();
          toast.success('Foto de perfil actualizada.');
        } else {
          const errorData = await response.json();
          toast.error(`Error: ${errorData.error}`);
        }
      } catch (error) {
        console.error('Error uploading profile picture:', error);
        toast.error('Error al subir la foto de perfil.');
      }
    };

    const handleInputChange = (e) => {
      setUpdatedProfile({
        ...updatedProfile,
        [e.target.name]: e.target.value,
      });
    };

    const handleLogout = () => {
      // Lógica para cerrar sesión
      toast.info('Sesión cerrada.');
    };

    if (loading) {
      return (
        <div className="flex justify-center items-center min-h-screen text-[var(--text-color)] bg-[var(--bg-color)]">
          <p className="text-xl font-medium">Cargando perfil...</p>
        </div>
      );
    }

    if (!user) {
      return (
        <div className="flex justify-center items-center min-h-screen text-[var(--text-color)] bg-[var(--bg-color)]">
          <p className="text-red-500 text-xl font-medium">No se pudo cargar el perfil de usuario.</p>
        </div>
      );
    }

    return (
      <div className="font-orbitron text-[var(--text-color)] bg-[var(--bg-color)] min-h-screen py-24 sm:py-32">
        <div className="container mx-auto px-4 sm:px-6 lg:px-12">
          <main
            className="py-12 sm:py-16 px-4 sm:px-8 lg:px-10 bg-[var(--bg-color)] rounded-2xl shadow-xl transition-shadow duration-300"
            data-aos="fade-up"
          >
            <ToastContainer />
            {/* Tarjeta de perfil */}
            <div className="bg-[var(--bg-color)] rounded-3xl shadow-2xl overflow-hidden relative">
              <div className="relative">
                {/* Imagen de portada */}
                <div
                  className="h-56 bg-cover bg-center"
                  style={{
                    backgroundImage: `url(${user.cover_photo || 'https://source.unsplash.com/random/800x600?futuristic'})`,
                  }}
                ></div>
                {/* Botón de cerrar sesión */}
                <div className="absolute top-4 right-4">
                  <button
                    onClick={handleLogout}
                    className="text-white bg-red-500 hover:bg-red-600 p-2 rounded-full focus:outline-none shadow-lg"
                  >
                    <FiLogOut size={20} />
                  </button>
                </div>
                {/* Foto de perfil */}
                <div className="flex justify-center -mt-20 relative z-10">
                  <div className="relative group">
                    {user.profile_picture ? (
                      <img
                        className="h-40 w-40 rounded-full border-4 border-[var(--bg-color)] object-cover transform transition-transform duration-500 group-hover:scale-105"
                        src={`https://tempestgf.zapto.org/${user.profile_picture}`}
                        alt="Foto de perfil"
                      />
                    ) : (
                      <div className="h-40 w-40 rounded-full border-4 border-[var(--bg-color)] bg-gray-300 flex items-center justify-center text-2xl text-white">
                        {user.full_name ? user.full_name.charAt(0) : user.username.charAt(0)}
                      </div>
                    )}
                    <label
                      htmlFor="upload-profile-picture"
                      className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-full opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer"
                    >
                      <FaCamera className="text-white text-2xl" />
                      <input
                        type="file"
                        id="upload-profile-picture"
                        accept="image/*"
                        className="hidden"
                        onChange={(e) => handleUploadProfilePicture(e.target.files[0])}
                      />
                    </label>
                  </div>
                </div>

              </div>
              {/* Información básica */}
              <div className="text-center mt-6 px-6">
                <h2 className="text-3xl font-bold text-[var(--text-color)]">
                  {user.full_name || user.username}
                </h2>
                <p className="text-[var(--text-color)]">{user.email}</p>
                <p className="mt-4 text-[var(--text-color)] max-w-2xl mx-auto">
                  {user.bio || 'No ha proporcionado una biografía.'}
                </p>
              </div>
              {/* Información adicional */}
              <div className="mt-8 px-6">
                <div className="flex justify-center space-x-8 text-[var(--text-color)]">
                  <div className="flex items-center">
                    <svg className="h-6 w-6 fill-current" viewBox="0 0 512 512">
                      <path d="M437.332 74.667c-99.2-99.2-260.53-99.2-359.73 0-99.2 99.2-99.2 260.53 0 359.73 99.2 99.2 260.53 99.2 359.73 0 99.2-99.2 99.2-260.53 0-359.73zM256 448c-105.86 0-192-86.14-192-192s86.14-192 192-192 192 86.14 192 192-86.14 192-192 192z" />
                      <path d="M369.64 146.56c-12.12-12.12-31.78-12.12-43.9 0l-98.74 98.74-31.78-31.78c-12.12-12.12-31.78-12.12-43.9 0s-12.12 31.78 0 43.9l53.33 53.33c12.12 12.12 31.78 12.12 43.9 0l120.64-120.64c12.12-12.12 12.12-31.78 0-43.9z" />
                    </svg>
                    <span className="ml-2">{`Miembro desde ${new Date(user.joined).toLocaleDateString()}`}</span>
                  </div>
                  {user.phone_number && (
                    <div className="flex items-center">
                      <MdPhone className="h-6 w-6" /> {/* Nuevo ícono de teléfono */}
                      <span className="ml-2">{user.phone_number}</span>
                    </div>
                  )}
                </div>
              </div>
              {/* Botones de acción */}
              <div className="flex justify-center mt-8 space-x-4">
                {!editable ? (
                  <button
                    onClick={() => setEditable(true)}
                    className="flex items-center bg-[var(--main-color)] text-white px-6 py-2 rounded-full hover:bg-[var(--hover-color)] transform transition-transform duration-300 hover:scale-105 shadow-lg"
                  >
                    <FaEdit className="mr-2" /> Editar Perfil
                  </button>
                ) : (
                  <button
                    onClick={handleUpdateProfile}
                    className="flex items-center bg-green-500 text-white px-6 py-2 rounded-full hover:bg-green-600 transform transition-transform duration-300 hover:scale-105 shadow-lg"
                  >
                    <FaSave className="mr-2" /> Guardar Cambios
                  </button>
                )}
              </div>
              {/* Formulario editable */}
              {editable && (
                <div className="mt-8 px-6">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label htmlFor="full_name" className="block text-[var(--text-color)] mb-2">
                        Nombre Completo
                      </label>
                      <input
                        type="text"
                        id="full_name"
                        name="full_name"
                        value={updatedProfile.full_name}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 bg-[var(--bg-color)] border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-[var(--hover-color)] text-[var(--text-color)]"
                      />
                    </div>
                    <div>
                      <label htmlFor="phone_number" className="block text-[var(--text-color)] mb-2">
                        Número de Teléfono
                      </label>
                      <input
                        type="tel"
                        id="phone_number"
                        name="phone_number"
                        value={updatedProfile.phone_number}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 bg-[var(--bg-color)] border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-[var(--hover-color)] text-[var(--text-color)]"
                      />
                    </div>
                    <div className="md:col-span-2">
                      <label htmlFor="bio" className="block text-[var(--text-color)] mb-2">
                        Biografía
                      </label>
                      <textarea
                        id="bio"
                        name="bio"
                        value={updatedProfile.bio}
                        onChange={handleInputChange}
                        rows="3"
                        className="w-full px-4 py-2 bg-[var(--bg-color)] border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-[var(--hover-color)] text-[var(--text-color)]"
                      ></textarea>
                    </div>
                  </div>
                </div>
              )}
              {/* Cambiar contraseña */}
              <div className="mt-12 px-6 pb-12">
                <h3 className="text-2xl font-semibold text-[var(--text-color)] mb-6">
                  Cambiar Contraseña
                </h3>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    // Lógica para cambiar contraseña
                    toast.success('Contraseña actualizada exitosamente.');
                  }}
                  className="grid grid-cols-1 md:grid-cols-2 gap-6"
                >
                  <div>
                    <label htmlFor="current_password" className="block text-[var(--text-color)] mb-2">
                      Contraseña Actual
                    </label>
                    <input
                      type="password"
                      id="current_password"
                      name="current_password"
                      required
                      className="w-full px-4 py-2 bg-[var(--bg-color)] border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-[var(--hover-color)] text-[var(--text-color)]"
                    />
                  </div>
                  <div>
                    <label htmlFor="new_password" className="block text-[var(--text-color)] mb-2">
                      Nueva Contraseña
                    </label>
                    <input
                      type="password"
                      id="new_password"
                      name="new_password"
                      required
                      className="w-full px-4 py-2 bg-[var(--bg-color)] border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-[var(--hover-color)] text-[var(--text-color)]"
                    />
                  </div>
                  <div className="md:col-span-2">
                    <label htmlFor="confirm_password" className="block text-[var(--text-color)] mb-2">
                      Confirmar Contraseña
                    </label>
                    <input
                      type="password"
                      id="confirm_password"
                      name="confirm_password"
                      required
                      className="w-full px-4 py-2 bg-[var(--bg-color)] border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-[var(--hover-color)] text-[var(--text-color)]"
                    />
                  </div>
                  <div className="md:col-span-2 flex justify-center mt-4">
                    <button
                      type="submit"
                      className="bg-[var(--main-color)] text-white px-8 py-2 rounded-full hover:bg-[var(--hover-color)] transform transition-transform duration-300 hover:scale-105 shadow-lg"
                    >
                      Actualizar Contraseña
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  };

  export default UserProfile;
