import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { motion } from 'framer-motion';

const MenuPage = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);

  React.useEffect(() => {
    const handleResize = () => {
      document.documentElement.style.setProperty(
        '--viewport-height',
        `${window.innerHeight - 64}px`
      );
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  // Datos del menú con varias secciones y platos
  const menuSections = [
    {
      title: t('starters'),
      items: [
        { name: t('starter1'), description: t('starter1Desc'), price: '10€' },
        { name: t('starter2'), description: t('starter2Desc'), price: '12€' },
        { name: t('starter3'), description: t('starter3Desc'), price: '9€' },
        { name: t('starter4'), description: t('starter4Desc'), price: '11€' },
        { name: t('starter5'), description: t('starter5Desc'), price: '13€' },
      ],
    },
    {
      title: t('mainCourses'),
      items: [
        { name: t('main1'), description: t('main1Desc'), price: '20€' },
        { name: t('main2'), description: t('main2Desc'), price: '22€' },
        { name: t('main3'), description: t('main3Desc'), price: '18€' },
        { name: t('main4'), description: t('main4Desc'), price: '25€' },
        { name: t('main5'), description: t('main5Desc'), price: '24€' },
        { name: t('main6'), description: t('main6Desc'), price: '21€' },
      ],
    },
    {
      title: t('desserts'),
      items: [
        { name: t('dessert1'), description: t('dessert1Desc'), price: '8€' },
        { name: t('dessert2'), description: t('dessert2Desc'), price: '7€' },
        { name: t('dessert3'), description: t('dessert3Desc'), price: '9€' },
        { name: t('dessert4'), description: t('dessert4Desc'), price: '10€' },
      ],
    },
    {
      title: t('drinks'),
      items: [
        { name: t('drink1'), description: t('drink1Desc'), price: '5€' },
        { name: t('drink2'), description: t('drink2Desc'), price: '6€' },
        { name: t('drink3'), description: t('drink3Desc'), price: '4€' },
        { name: t('drink4'), description: t('drink4Desc'), price: '5€' },
        { name: t('drink5'), description: t('drink5Desc'), price: '7€' },
      ],
    },
  ];

  return (
    <div className="min-h-screen flex flex-col items-center justify-start bg-gray-900 overflow-hidden pt-[64px]"> {/* Espacio para el Navbar */}
      {currentPage === 0 ? (
        // Portada de la Carta
        <motion.div
          key="cover"
          initial={{ opacity: 0, x: 300 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -300 }}
          transition={{ duration: 0.5 }}
          className="flex flex-col items-center justify-center w-full max-w-[600px] p-6 bg-white rounded-xl shadow-2xl text-center"
          style={{
            height: 'var(--viewport-height)', // Usa la altura dinámica calculada
          }}
        >
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold text-gray-900 mb-6">
            Gourmet Heaven
          </h1>
          <p className="text-lg md:text-xl lg:text-2xl text-gray-700 mb-8 max-w-[500px] leading-relaxed">
            {t('heroSubtitle')}
          </p>
          <button
            onClick={() => setCurrentPage(1)}
            className="px-6 py-3 bg-gradient-to-r from-blue-500 to-indigo-600 text-white font-semibold rounded-full shadow-lg hover:from-blue-600 hover:to-indigo-700 transition duration-300"
          >
            {t('exploreMenuButton')}
          </button>
        </motion.div>
      ) : currentPage > 0 && currentPage <= menuSections.length ? (
        // Página del Menú simulando una hoja
        <motion.div
          key={`page-${currentPage}`}
          initial={{ opacity: 0, x: 300 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -300 }}
          transition={{ duration: 0.5 }}
          className="flex flex-col justify-between w-full max-w-[600px] bg-white rounded-xl shadow-2xl p-6 border border-gray-200"
          style={{
            height: 'var(--viewport-height)', // Usa la altura dinámica calculada
          }}
        >
          <div className="flex-grow overflow-y-auto pr-2">
            <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 text-gray-900 border-b-4 border-blue-500 pb-2 uppercase tracking-wider">
              {menuSections[currentPage - 1]?.title}
            </h2>
            <ul className="space-y-4">
              {menuSections[currentPage - 1]?.items.map((item, itemIndex) => (
                <li
                  key={itemIndex}
                  className="flex items-center justify-between border-b border-dashed pb-3 border-gray-300 w-full"
                >
                  <div className="flex-1">
                    <h3 className="text-lg md:text-xl lg:text-2xl font-semibold text-gray-800 leading-tight">
                      {item.name}
                    </h3>
                    <p className="text-sm md:text-base lg:text-lg text-gray-600 mt-1">
                      {item.description}
                    </p>
                  </div>
                  <span className="text-lg md:text-xl lg:text-2xl font-bold text-gray-900 ml-auto">
                    {item.price}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex justify-between w-full mt-4">
            {/* Botones Previous y Next mejorados */}
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              className="px-4 py-2 bg-gray-300 text-gray-800 rounded-lg shadow-sm hover:bg-gray-400 transition duration-300 text-sm md:text-base lg:text-lg"
              disabled={currentPage === 0}
            >
              {t('previous')}
            </button>
            {currentPage < menuSections.length ? (
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 transition duration-300 ml-auto text-sm md:text-base lg:text-lg"
              >
                {t('next')}
              </button>
            ) : (
              <button
                onClick={() => setCurrentPage(0)}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 transition duration-300 ml-auto text-sm md:text-base lg:text-lg"
              >
                {t('backToCover')}
              </button>
            )}
          </div>
        </motion.div>
      ) : null}
    </div>
  );
  
  
};

export default MenuPage;
