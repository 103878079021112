// components/Gallery.js
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaTimes } from 'react-icons/fa';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const galleryImages = [
  '/images/gallery/dish1.jpg',
  '/images/gallery/dish2.jpg',
  '/images/gallery/dish3.jpg',
  '/images/gallery/dish4.jpg',
];

const additionalImages = [
  '/images/gallery/dish5.jpg',
  '/images/gallery/dish6.jpg',
  '/images/gallery/dish7.jpg',
  '/images/gallery/dish8.jpg',
  '/images/gallery/dish9.jpg',
  '/images/gallery/dish10.jpg',
  '/images/gallery/dish11.jpg',
  '/images/gallery/dish12.jpg',
];

const Gallery = () => {
  const { t } = useTranslation(); // Initialize translation hook
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [selectedImage, setSelectedImage] = useState(null);
  const [visibleImages, setVisibleImages] = useState(galleryImages);
  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => {
    setShowMore(true);
    setVisibleImages([...galleryImages, ...additionalImages]);
  };

  const handleShowLess = () => {
    setShowMore(false);
    setVisibleImages(galleryImages);
  };

  const animationVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -50 },
  };

  return (
    <section
      id="gallery"
      className="bg-gradient-to-br from-gray-900 to-black py-12 sm:py-16"
    >
      <div className="container mx-auto text-center px-4">
        <motion.h2
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className="text-3xl sm:text-4xl lg:text-5xl font-extrabold text-white mb-4 sm:mb-6"
        >
          {t('galleryTitle')}
        </motion.h2>

        {/* Grid de Imágenes */}
        <motion.div
          ref={ref}
          className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3 sm:gap-4"
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          variants={animationVariants}
          transition={{ duration: 1.2 }}
        >
          {visibleImages.map((img, index) => (
            <motion.div
              key={index}
              className="relative cursor-pointer overflow-hidden rounded-lg shadow-lg"
              onClick={() => setSelectedImage(img)}
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            >
              <img
                src={img}
                alt={`Gallery image ${index + 1}`}
                className="w-full h-40 sm:h-48 object-cover transform hover:scale-110 transition duration-500 ease-in-out"
              />
              <div className="absolute inset-0 bg-gray-800 bg-opacity-60 opacity-0 hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
                <p className="text-white text-sm sm:text-lg font-bold">{t('viewImage')}</p>
              </div>
            </motion.div>
          ))}
        </motion.div>

        {/* Botones de Ver Más y Cerrar */}
        <motion.div
          className="mt-6 sm:mt-8 flex justify-center gap-4"
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          variants={animationVariants}
          transition={{ duration: 1.2 }}
        >
          {!showMore && (
            <button
              onClick={handleShowMore}
              className="px-4 py-2 sm:px-6 sm:py-3 bg-blue-600 text-white font-semibold rounded-full hover:bg-blue-700 transition duration-300 focus:outline-none"
            >
              {t('showMore')}
            </button>
          )}
          {showMore && (
            <button
              onClick={handleShowLess}
              className="px-4 py-2 sm:px-6 sm:py-3 bg-red-600 text-white font-semibold rounded-full hover:bg-red-700 transition duration-300 focus:outline-none"
            >
              {t('close')}
            </button>
          )}
        </motion.div>

        {/* Modal de Imagen Seleccionada */}
        {selectedImage && (
          <div
            className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50 p-4"
            onClick={() => setSelectedImage(null)}
          >
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.3 }}
              className="relative max-w-full max-h-full"
            >
              <button
                className="absolute top-2 right-2 text-white text-2xl hover:text-blue-500"
                onClick={() => setSelectedImage(null)}
              >
                <FaTimes />
              </button>
              <img
                src={selectedImage}
                alt="Selected"
                className="max-w-full max-h-full rounded-lg shadow-2xl"
              />
            </motion.div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Gallery;
