// components/About.js
import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaUtensils, FaWineGlassAlt, FaAward } from 'react-icons/fa';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const About = () => {
  const { t } = useTranslation(); // Initialize translation hook
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <section id="about" className="relative py-16 sm:py-20">
      <div className="absolute inset-0 overflow-hidden z-0">
        <video
          id="about-video"
          autoPlay
          muted
          loop
          playsInline
          className="w-full h-full object-cover opacity-30"
        >
          <source src="/videos/cutting.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className="absolute inset-0 bg-black bg-opacity-70 z-0"></div>

      <div className="container mx-auto px-4 sm:px-8 grid grid-cols-1 lg:grid-cols-2 gap-8 sm:gap-12 relative z-10">
        <motion.div
          ref={ref}
          initial={{ opacity: 0, x: -50 }}
          animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }}
          transition={{ duration: 1.2 }}
          className="relative h-64 sm:h-auto"
        >
          <div className="absolute inset-0 bg-black opacity-20 rounded-lg"></div>
          <img
            src="/images/chef.jpg"
            alt="Chef preparing a dish"
            className="rounded-lg shadow-lg object-cover w-full h-full"
          />
        </motion.div>

        <motion.div
          className="flex flex-col justify-center text-left"
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
          transition={{ duration: 1.2 }}
        >
          <h2 className="text-3xl sm:text-4xl lg:text-5xl font-extrabold text-white mb-4 sm:mb-6">
            {t('aboutTitle')}
          </h2>
          <p className="text-base sm:text-lg text-gray-200 leading-relaxed mb-4 sm:mb-6">
            {t('aboutParagraph1', { brand: 'Gourmet Haven' })}
          </p>
          <p className="text-base sm:text-lg text-gray-200 leading-relaxed mb-4 sm:mb-6">
            {t('aboutParagraph2')}
          </p>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
            <div className="flex items-center space-x-3">
              <FaUtensils className="text-blue-500 text-2xl" />
              <p className="text-sm sm:text-base font-semibold text-gray-100">
                {t('exquisiteDishes')}
              </p>
            </div>
            <div className="flex items-center space-x-3">
              <FaWineGlassAlt className="text-blue-500 text-2xl" />
              <p className="text-sm sm:text-base font-semibold text-gray-100">
                {t('fineWineSelection')}
              </p>
            </div>
            <div className="flex items-center space-x-3">
              <FaAward className="text-blue-500 text-2xl" />
              <p className="text-sm sm:text-base font-semibold text-gray-100">
                {t('awardWinningService')}
              </p>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default About;
