// src/components/Session/Register.js
import React, { useState, useRef, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid'; // Asegúrate de tener Heroicons instalado
import { motion, AnimatePresence } from 'framer-motion';

function Register({ onClose }) {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
  });
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const modalRef = useRef(null);
  const usernameRef = useRef(null);

  // Manejo de clics fuera del modal y tecla Escape
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        onClose();
      }
    };

    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscape);
    };
  }, [onClose]);

  // Enfocar el campo de usuario al abrir el modal
  useEffect(() => {
    if (usernameRef.current) {
      usernameRef.current.focus();
    }
  }, []);

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    try {
      const response = await fetch('https://tempestgf.zapto.org/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
        credentials: 'include', // Si tu API requiere enviar cookies
      });

      if (response.ok) {
        const data = await response.json();
        // Opcional: podrías notificar al usuario sobre el registro exitoso
        onClose(); // Cerrar el modal después del registro exitoso
      } else {
        const data = await response.json();
        setError(data.error || 'Registro fallido. Por favor, intenta de nuevo.');
      }
    } catch (error) {
      console.error('Error registrando:', error);
      setError('Ocurrió un error al registrarse. Por favor, intenta de nuevo.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        aria-labelledby="register-modal-title"
        role="dialog"
        aria-modal="true"
      >
        <motion.div
          className="bg-[var(--bg-color)] rounded-lg shadow-lg w-full max-w-md mx-4 p-6 relative"
          ref={modalRef}
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.8, opacity: 0 }}
          transition={{ type: 'spring', stiffness: 300, damping: 25 }}
        >
          {/* Botón de cierre */}
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-[var(--text-color)] hover:text-[var(--hover-color)]"
            aria-label="Cerrar modal"
          >
            <XMarkIcon className="h-6 w-6" />
          </button>

          <h2 id="register-modal-title" className="text-2xl font-semibold mb-4 text-center text-[var(--text-color)]">
            Registrarse
          </h2>

          {error && (
            <div className="bg-red-100 text-red-700 p-3 rounded mb-4">
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="username" className="block mb-1 text-[var(--text-color)]">
                Nombre de usuario
              </label>
              <input
                type="text"
                id="username"
                name="username"
                ref={usernameRef}
                value={formData.username}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[var(--main-color)]"
                placeholder="Tu nombre de usuario"
                style={{
                  backgroundColor: 'var(--bg-color)',
                  color: 'var(--text-color)',
                  borderColor: 'var(--secondary-color)',
                }}
              />
            </div>
            <div>
              <label htmlFor="email" className="block mb-1 text-[var(--text-color)]">
                Correo electrónico
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[var(--main-color)]"
                placeholder="Tu correo electrónico"
                style={{
                  backgroundColor: 'var(--bg-color)',
                  color: 'var(--text-color)',
                  borderColor: 'var(--secondary-color)',
                }}
              />
            </div>
            <div>
              <label htmlFor="password" className="block mb-1 text-[var(--text-color)]">
                Contraseña
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[var(--main-color)]"
                placeholder="Tu contraseña"
                style={{
                  backgroundColor: 'var(--bg-color)',
                  color: 'var(--text-color)',
                  borderColor: 'var(--secondary-color)',
                }}
              />
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                onClick={onClose}
                className="mr-3 px-4 py-2 bg-[var(--secondary-color)] text-[var(--text-color)] rounded-md hover:bg-[var(--hover-color)]"
                style={{
                  backgroundColor: 'var(--secondary-color)',
                  color: 'var(--text-color)',
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className={`px-4 py-2 bg-[var(--main-color)] text-white rounded-md hover:bg-[var(--hover-color)] focus:outline-none focus:ring-2 focus:ring-[var(--main-color)] ${
                  isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                style={{
                  backgroundColor: 'var(--main-color)',
                  color: '#fff',
                }}
              >
                {isSubmitting ? 'Registrando...' : 'Registrarse'}
              </button>
            </div>
          </form>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
}

export default Register;
