import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaEdit, FaSave, FaThumbsUp, FaThumbsDown } from 'react-icons/fa';

const DetallePublicacion = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [publicacion, setPublicacion] = useState(null);
  const [respuestas, setRespuestas] = useState([]);
  const [error, setError] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [nuevaRespuesta, setNuevaRespuesta] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editContenido, setEditContenido] = useState('');

  useEffect(() => {
    AOS.init({ duration: 1000, easing: 'ease-in-out' });
    fetchCurrentUser();
    checkLogin();
    fetchPublicacion();
    fetchRespuestas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Recuperar el usuario actual desde localStorage
  const fetchCurrentUser = () => {
    const user = JSON.parse(localStorage.getItem('currentUser')) || null;
    setCurrentUser(user);
  };

  // Verificar si el usuario está logueado
  const checkLogin = async () => {
    try {
      const response = await axios.get('https://tempestgf.zapto.org/api/checkLogin', {
        withCredentials: true,
      });
      setLoggedIn(response.data.loggedIn);
      if (response.data.loggedIn && !currentUser) {
        fetchCurrentUser();
      }
    } catch (error) {
      console.error('Error fetching login status:', error);
      setLoggedIn(false);
    }
  };

  // Obtener la publicación desde la API
  const fetchPublicacion = async () => {
    try {
      const response = await axios.get(`https://tempestgf.zapto.org/api/publicaciones/${id}`);
      setPublicacion(response.data);
    } catch (error) {
      console.error('Error fetching publicacion:', error);
      setError('Error al cargar la publicación.');
    }
  };

  // Obtener las respuestas de la publicación
  const fetchRespuestas = async () => {
    try {
      const response = await axios.get(`https://tempestgf.zapto.org/api/publicaciones/${id}/respuestas`);
      setRespuestas(response.data);
    } catch (error) {
      console.error('Error fetching respuestas:', error);
      setError('Error al cargar las respuestas.');
    }
  };

  // Manejar el envío de una nueva respuesta
  const handleAgregarRespuesta = async () => {
    if (!loggedIn) {
      setError('No estás autorizado para agregar una respuesta. Por favor, inicia sesión.');
      return;
    }

    if (nuevaRespuesta.trim() === '') {
      setError('La respuesta no puede estar vacía.');
      return;
    }

    try {
      await axios.post(
        `https://tempestgf.zapto.org/api/publicaciones/${id}/respuestas`,
        { respuesta: nuevaRespuesta },
        { withCredentials: true }
      );
      toast.success('Respuesta agregada exitosamente.');
      fetchRespuestas();
      setNuevaRespuesta('');
      fetchPublicacion(); // Para actualizar el conteo de respuestas
    } catch (error) {
      console.error('Error adding respuesta:', error);
      setError('Error al agregar la respuesta.');
      toast.error('Error al agregar la respuesta.');
    }
  };

  // Manejar Like
  const handleLike = async () => {
    if (!loggedIn) {
      setError('Debes iniciar sesión para dar like.');
      toast.error('Debes iniciar sesión para dar like.');
      return;
    }

    try {
      await axios.post(
        `https://tempestgf.zapto.org/api/publicaciones/${id}/like`,
        {},
        { withCredentials: true }
      );
      fetchPublicacion(); // Refresca la publicación para actualizar el número de likes
    } catch (error) {
      console.error('Error al dar like:', error);
      setError('Error al dar like.');
      toast.error('Error al dar like.');
    }
  };

  // Manejar Dislike
  const handleDislike = async () => {
    if (!loggedIn) {
      setError('Debes iniciar sesión para dar dislike.');
      toast.error('Debes iniciar sesión para dar dislike.');
      return;
    }

    try {
      await axios.post(
        `https://tempestgf.zapto.org/api/publicaciones/${id}/dislike`,
        {},
        { withCredentials: true }
      );
      fetchPublicacion(); // Refresca la publicación para actualizar el número de dislikes
    } catch (error) {
      console.error('Error al dar dislike:', error);
      setError('Error al dar dislike.');
      toast.error('Error al dar dislike.');
    }
  };

  // Manejar la edición de la publicación
  const handleEditarPublicacion = async () => {
    if (editContenido.trim() === '') {
      setError('El contenido no puede estar vacío.');
      toast.error('El contenido no puede estar vacío.');
      return;
    }

    try {
      await axios.put(
        `https://tempestgf.zapto.org/api/publicaciones/${id}`,
        { contenido: editContenido },
        { withCredentials: true }
      );
      toast.success('Publicación actualizada exitosamente.');
      setIsEditing(false);
      fetchPublicacion();
    } catch (error) {
      console.error('Error al actualizar la publicación:', error);
      setError('Error al actualizar la publicación.');
      toast.error('Error al actualizar la publicación.');
    }
  };

  // Formatear la fecha
  const formattedDate = (dateStr) => {
    const date = new Date(dateStr);
    if (isNaN(date.getTime())) {
      return 'Fecha no válida';
    } else {
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };
      return date.toLocaleString('es-ES', options);
    }
  };

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen text-red-500 bg-[var(--bg-color)]">
        <p className="text-xl font-medium">{error}</p>
      </div>
    );
  }

  if (!publicacion) {
    return (
      <div className="flex justify-center items-center min-h-screen text-[var(--text-color)] bg-[var(--bg-color)]">
        <p className="text-xl font-medium">Cargando publicación...</p>
      </div>
    );
  }

  return (
    <div className="font-source-code-pro text-[var(--text-color)] bg-[var(--bg-color)] min-h-screen py-24 sm:py-32">
      <ToastContainer />
      <div className="container mx-auto px-4 sm:px-6 lg:px-12">
        <main className="py-12 sm:py-16 px-4 sm:px-8 lg:px-10 bg-[var(--bg-color)] rounded-2xl shadow-xl transition-shadow duration-300">
          <div
            className="max-w-3xl mx-auto bg-[var(--container-bg-color)] dark:bg-[var(--container-bg-color-night)] rounded-3xl p-8 sm:p-12 lg:p-16 shadow-2xl"
            data-aos="fade-up"
          >
            {/* Título de la publicación */}
            <h2 className="text-3xl font-bold mb-6 text-[var(--main-color)]">
              {publicacion.titulo}
            </h2>

            {/* Información del autor */}
            <div className="flex items-center mb-6">
              <img
                src={`https://tempestgf.zapto.org/${publicacion.avatar}`}
                alt={`Avatar de ${publicacion.autor}`}
                className="w-10 h-10 rounded-full mr-3"
              />
              <span className="text-lg font-medium">{publicacion.autor}</span>
              <span className="text-sm text-gray-500 ml-auto">
                {formattedDate(publicacion.fecha_creacion)}
              </span>
            </div>

            {/* Contenido de la publicación */}
            <div className="prose dark:prose-invert mb-8">
              {isEditing ? (
                <textarea
                  value={editContenido}
                  onChange={(e) => setEditContenido(e.target.value)}
                  className="w-full h-40 p-4 border border-gray-300 dark:border-gray-600 rounded-md bg-[var(--container-bg-color)] dark:bg-gray-800 text-[var(--text-color)] focus:outline-none focus:ring-2 focus:ring-[var(--hover-color)]"
                />
              ) : (
                <p>{publicacion.contenido}</p>
              )}
            </div>

            {/* Botones de like/dislike */}
            <div className="flex items-center mb-8">
              <button
                onClick={handleLike}
                className="flex items-center mr-4 text-green-500 hover:text-green-600 transition-colors"
              >
                <FaThumbsUp className="mr-1" /> {publicacion.likes}
              </button>
              <button
                onClick={handleDislike}
                className="flex items-center text-red-500 hover:text-red-600 transition-colors"
              >
                <FaThumbsDown className="mr-1" /> {publicacion.dislikes}
              </button>
            </div>

            {/* Botón para editar la publicación (solo para el autor) */}
            {currentUser && publicacion.autor === currentUser.username && (
              <div className="flex justify-end mb-6">
                {!isEditing ? (
                  <button
                    onClick={() => {
                      setIsEditing(true);
                      setEditContenido(publicacion.contenido);
                    }}
                    className="flex items-center bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition-transform transform hover:scale-105 shadow-lg"
                  >
                    <FaEdit className="mr-2" /> Editar Publicación
                  </button>
                ) : (
                  <button
                    onClick={handleEditarPublicacion}
                    className="flex items-center bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600 transition-transform transform hover:scale-105 shadow-lg"
                  >
                    <FaSave className="mr-2" /> Guardar Cambios
                  </button>
                )}
              </div>
            )}

            {/* Respuestas */}
            <h3 className="text-2xl font-semibold mb-4">Respuestas ({respuestas.length})</h3>
            <div className="space-y-6">
              {respuestas.map((respuesta) => (
                <div
                  key={respuesta.id}
                  className="bg-gray-100 dark:bg-gray-800 p-6 rounded-lg shadow-md"
                >
                  <div className="flex items-center mb-2">
                    <img
                      src={`https://tempestgf.zapto.org/${respuesta.avatar}`}
                      alt={`Avatar de ${respuesta.autor}`}
                      className="w-8 h-8 rounded-full mr-2"
                    />
                    <span className="font-medium">{respuesta.autor}</span>
                    <span className="text-sm text-gray-500 ml-auto">
                      {formattedDate(respuesta.fecha_creacion)}
                    </span>
                  </div>
                  <div className="prose dark:prose-invert">
                    <p>{respuesta.respuesta}</p>
                  </div>
                </div>
              ))}
            </div>

            {/* Agregar respuesta */}
            {loggedIn ? (
              <div className="mt-8">
                <h3 className="text-2xl font-semibold mb-4">Agregar Respuesta</h3>
                <textarea
                  value={nuevaRespuesta}
                  onChange={(e) => setNuevaRespuesta(e.target.value)}
                  placeholder="Escribe tu respuesta aquí..."
                  className="w-full h-32 p-4 border border-gray-300 dark:border-gray-600 rounded-md bg-[var(--container-bg-color)] dark:bg-gray-800 text-[var(--text-color)] focus:outline-none focus:ring-2 focus:ring-[var(--hover-color)]"
                />
                <button
                  onClick={handleAgregarRespuesta}
                  className="mt-4 px-6 py-3 bg-[var(--main-color)] text-white rounded-md font-semibold hover:bg-[var(--hover-color)] transition-colors transform hover:scale-105 shadow-lg"
                >
                  Enviar Respuesta
                </button>
              </div>
            ) : (
              <p className="text-center text-red-500 mt-6">
                Inicia sesión para agregar una respuesta.
              </p>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default DetallePublicacion;
