import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Importar los estilos de AOS
import 'boxicons/css/boxicons.min.css'; // Asegúrate de importar los estilos de Boxicons

const Services = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duración de las animaciones en milisegundos
      once: true, // Si true, la animación solo ocurrirá una vez mientras haces scroll
      offset: 50, // Desplazamiento antes de que la animación empiece
    });
  }, []);

  return (
    <section
      id="services"
      className="py-16 bg-[var(--bg-color)] text-[var(--text-color)] overflow-hidden"
    >
      <div className="container mx-auto px-6 md:px-12 text-center">
        <div className="mb-12">
          <h2
            className="text-3xl font-bold text-[var(--text-color)]"
            data-aos="fade-down"
          >
            Services
          </h2>
          <p
            className="mt-4 text-[var(--text-color)] dark:text-[var(--text-color-night)]"
            data-aos="fade-up"
          >
            Explore the range of services I offer to help bring your ideas to life.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 overflow-hidden">
          <div
            className="flex flex-col items-center text-center p-8 bg-[var(--bg-color)] dark:bg-[var(--bg-color-night)] rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300 w-full max-w-full"
            data-aos="fade-up"
          >
            <i className="bx bx-code-alt text-7xl text-[var(--main-color)] dark:text-[var(--main-color-night)] mb-4"></i>
            <h3 className="text-xl font-semibold mb-4 text-[var(--text-color)] dark:text-[var(--text-color-night)]">
              Frontend <br /> Development
            </h3>
            <p className="text-[var(--p-font)] text-[var(--text-color)] dark:text-[var(--text-color-night)]">
              Crafting responsive and visually appealing user interfaces using the latest technologies like React, Angular, and Vue. Focus on performance and accessibility.
            </p>
          </div>
          <div
            className="flex flex-col items-center text-center p-8 bg-[var(--bg-color)] dark:bg-[var(--bg-color-night)] rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300 w-full max-w-full"
            data-aos="fade-up"
          >
            <i className="bx bx-server text-7xl text-[var(--main-color)] dark:text-[var(--main-color-night)] mb-4"></i>
            <h3 className="text-xl font-semibold mb-4 text-[var(--text-color)] dark:text-[var(--text-color-night)]">
              Backend <br /> Development
            </h3>
            <p className="text-[var(--p-font)] text-[var(--text-color)] dark:text-[var(--text-color-night)]">
              Developing robust and scalable server-side solutions with a focus on security, database integration, and API development using Node.js, Python, and PHP.
            </p>
          </div>
          <div
            className="flex flex-col items-center text-center p-8 bg-[var(--bg-color)] dark:bg-[var(--bg-color-night)] rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300 w-full max-w-full"
            data-aos="fade-up"
          >
            <i className="bx bx-brush text-7xl text-[var(--main-color)] dark:text-[var(--main-color-night)] mb-4"></i>
            <h3 className="text-xl font-semibold mb-4 text-[var(--text-color)] dark:text-[var(--text-color-night)]">
              UI/UX <br /> Design
            </h3>
            <p className="text-[var(--p-font)] text-[var(--text-color)] dark:text-[var(--text-color-night)]">
              Designing intuitive and engaging user experiences with a focus on usability, prototyping, and visual design to elevate your brand's digital presence.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
