import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';

const API_URL = 'https://tempestgf.zapto.org/api/publicaciones';

const Foro = () => {
  const [publicaciones, setPublicaciones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeCategory, setActiveCategory] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 5; // Número de publicaciones por página
  const navigate = useNavigate();

  // Fetch publicaciones al montar el componente
  useEffect(() => {
    const fetchPublicaciones = async () => {
      try {
        const response = await axios.get(API_URL);
        setPublicaciones(response.data);
      } catch (err) {
        console.error('Error al obtener las publicaciones:', err);
        setError('Error al cargar las publicaciones.');
      } finally {
        setLoading(false);
      }
    };

    fetchPublicaciones();
    AOS.init({ duration: 1200, easing: 'ease-in-out', once: true });
  }, []);

  // Manejo de búsqueda con debounce
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
      setCurrentPage(1); // Resetear a la primera página al buscar
    }, 300); // 300ms de debounce

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  // Filtrado de publicaciones basado en búsqueda y categoría
  const filteredPublicaciones = useMemo(() => {
    return publicaciones.filter((post) => {
      const matchesSearch =
        post.titulo.toLowerCase().includes(debouncedSearchTerm) ||
        post.contenido.toLowerCase().includes(debouncedSearchTerm);

      const matchesCategory = activeCategory
        ? post.categoria &&
          post.categoria
            .toLowerCase()
            .split(',')
            .map((cat) => cat.trim())
            .includes(activeCategory.toLowerCase())
        : true;

      return matchesSearch && matchesCategory;
    });
  }, [publicaciones, debouncedSearchTerm, activeCategory]);

  // Paginación
  const totalPages = useMemo(
    () => Math.ceil(filteredPublicaciones.length / postsPerPage),
    [filteredPublicaciones.length, postsPerPage]
  );

  const currentPosts = useMemo(() => {
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    return filteredPublicaciones.slice(indexOfFirstPost, indexOfLastPost);
  }, [filteredPublicaciones, currentPage, postsPerPage]);

  // Funciones memorizadas para manejar eventos
  const handleSearch = useCallback((event) => {
    setSearchTerm(event.target.value.toLowerCase());
  }, []);

  const handleCategoryFilter = useCallback((category) => {
    setActiveCategory(category);
    setCurrentPage(1); // Resetear a la primera página al filtrar por categoría
  }, []);

  const paginate = useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll suave al cambiar de página
  }, []);

  // Lista de categorías disponibles
  const categories = useMemo(() => {
    const allCategories = publicaciones.flatMap((post) =>
      post.categoria
        ? post.categoria.split(',').map((cat) => cat.trim())
        : []
    );
    // Eliminar duplicados
    return Array.from(new Set(allCategories));
  }, [publicaciones]);

  return (
    <div className="font-source-code-pro text-[var(--text-color)] bg-[var(--bg-color)] min-h-screen py-24 sm:py-32">
      <div className="container mx-auto px-4 sm:px-6 lg:px-12">
        <main className="py-12 sm:py-16 px-4 sm:px-8 lg:px-10 bg-[var(--bg-color)] rounded-2xl shadow-xl hover:shadow-2xl transition-shadow duration-300">
          <div
            className="max-w-5xl mx-auto bg-[var(--bg-color)] dark:bg-[var(--bg-color)] rounded-3xl p-8 sm:p-12 lg:p-16 shadow-2xl"
            data-aos="fade-up"
          >
            <h1 className="text-center text-4xl sm:text-5xl lg:text-6xl font-extrabold mb-8 text-[var(--main-color)] tracking-tight">
              Foro de Discusión
            </h1>

            {/* Barra de búsqueda */}
            <div className="mb-8">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearch}
                className="w-full p-3 rounded-lg shadow-md text-[var(--text-color)] bg-[var(--secondary-color)] focus:outline-none focus:ring-2 focus:ring-[var(--main-color)]"
                placeholder="Buscar publicaciones..."
                aria-label="Buscar publicaciones"
              />
            </div>

            {/* Filtros por categoría */}
            <div className="flex flex-wrap justify-center mb-8 gap-4">
              <button
                className={`px-4 py-2 rounded-full font-semibold focus:outline-none focus:ring-2 focus:ring-[var(--main-color)] ${
                  activeCategory === ''
                    ? 'bg-[var(--main-color)] text-white'
                    : 'bg-gray-300 text-gray-700'
                }`}
                onClick={() => handleCategoryFilter('')}
                aria-pressed={activeCategory === ''}
              >
                Todas
              </button>
              {categories.map((category) => (
                <button
                  key={category}
                  className={`px-4 py-2 rounded-full font-semibold focus:outline-none focus:ring-2 focus:ring-[var(--main-color)] ${
                    activeCategory.toLowerCase() === category.toLowerCase()
                      ? 'bg-[var(--main-color)] text-white'
                      : 'bg-gray-300 text-gray-700'
                  }`}
                  onClick={() => handleCategoryFilter(category)}
                  aria-pressed={activeCategory.toLowerCase() === category.toLowerCase()}
                >
                  {category}
                </button>
              ))}
            </div>

            {loading ? (
              <p className="text-center text-lg font-medium text-[var(--text-color)]">
                Cargando publicaciones...
              </p>
            ) : error ? (
              <p className="text-center text-lg font-medium text-red-500 mb-6">
                {error}
              </p>
            ) : currentPosts.length > 0 ? (
              <>
                <div className="space-y-8 sm:space-y-10 lg:space-y-12" data-aos="fade-up">
                  {currentPosts.map((post) => {
                    const {
                      id,
                      titulo,
                      autor,
                      avatar,
                      fecha_creacion,
                      categoria,
                      tags,
                      contenido,
                      respuestas,
                      visitas,
                    } = post;

                    return (
                      <article
                        key={id}
                        className="bg-white dark:bg-gray-900 rounded-lg p-6 sm:p-8 shadow-lg hover:shadow-2xl transition-shadow duration-300 transform hover:-translate-y-1 hover:bg-opacity-90"
                        data-aos="fade-up"
                      >
                        <Link to={`/publicaciones/${id}`} className="block focus:outline-none">
                          {/* Título de la publicación */}
                          <h2 className="text-2xl sm:text-3xl font-bold mb-2 text-[var(--main-color)] hover:text-[var(--hover-color)] transition-colors duration-300">
                            {titulo}
                          </h2>

                          {/* Información del autor y fecha */}
                          <div className="flex items-center text-sm text-gray-500 mb-4">
                            <div className="flex items-center mr-4">
                              {avatar ? (
                                <img
                                  src={`https://tempestgf.zapto.org/${avatar}`}
                                  alt={`Avatar de ${autor}`}
                                  className="w-8 h-8 rounded-full mr-2"
                                  loading="lazy"
                                />
                              ) : (
                                <div className="w-8 h-8 rounded-full mr-2 bg-gray-400 flex items-center justify-center text-white">
                                  {autor.charAt(0).toUpperCase()}
                                </div>
                              )}
                              <span className="font-medium">{autor}</span>
                            </div>
                            <time dateTime={new Date(fecha_creacion).toISOString()}>
                              {new Date(fecha_creacion).toLocaleDateString()}
                            </time>
                          </div>

                          {/* Categorías y etiquetas */}
                          <div className="flex flex-wrap gap-2 mb-4">
                            {categoria &&
                              categoria.split(',').map((cat, index) => (
                                <span
                                  key={`categoria-${index}`}
                                  className="bg-blue-200 text-blue-800 px-2 py-1 rounded text-sm"
                                >
                                  {cat.trim()}
                                </span>
                              ))}
                            {tags &&
                              tags.split(',').map((tag, index) => (
                                <span
                                  key={`tag-${index}`}
                                  className="bg-green-200 text-green-800 px-2 py-1 rounded text-sm"
                                >
                                  {tag.trim()}
                                </span>
                              ))}
                          </div>

                          {/* Contenido de la publicación */}
                          <p className="text-base sm:text-lg text-[var(--text-color)] mb-6">
                            {contenido.length > 200
                              ? `${contenido.substring(0, 200)}...`
                              : contenido}
                          </p>

                          {/* Información adicional */}
                          <div className="flex gap-4 text-sm text-gray-500">
                            {respuestas && (
                              <span>{respuestas.length} Respuestas</span>
                            )}
                            <span>{visitas || 0} Visitas</span>
                          </div>
                        </Link>
                      </article>
                    );
                  })}
                </div>

                {/* Paginación */}
                <div className="flex justify-center mt-8 flex-wrap gap-2">
                  {Array.from({ length: totalPages }, (_, index) => (
                    <button
                      key={index + 1}
                      onClick={() => paginate(index + 1)}
                      className={`px-4 py-2 rounded-lg font-semibold focus:outline-none focus:ring-2 focus:ring-[var(--main-color)] ${
                        currentPage === index + 1
                          ? 'bg-[var(--main-color)] text-white'
                          : 'bg-gray-300 text-gray-700'
                      }`}
                      aria-current={currentPage === index + 1 ? 'page' : undefined}
                      aria-label={`Página ${index + 1}`}
                    >
                      {index + 1}
                    </button>
                  ))}
                </div>
              </>
            ) : (
              <p className="text-center text-lg font-medium text-[var(--text-color)]">
                No se encontraron publicaciones.
              </p>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Foro;
